@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
//font-family: "Roboto", sans-serif;
@font-face {
    font-family: 'Trajan Pro 3';
    src: url('../fonts/TrajanPro3-Regular.woff2') format('woff2'),
        url('../fonts/TrajanPro3-Regular.woff') format('woff'),
        url('../fonts/TrajanPro3-Regular.svg#TrajanPro3-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro-Bold.woff2') format('woff2'),
        url('../fonts/GothamPro-Bold.woff') format('woff'),
        url('../fonts/GothamPro-Bold.svg#GothamPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap');