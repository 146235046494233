@media screen and (min-width: 680px){
	.mob-true{
		display: none!important;
	}

	.care-subtitle__bottom{
		strong{
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			white-space: nowrap;
		}
		img{
			flex-shrink: 0;
		}
	}
}
@media screen and (max-width: 680px){
	.pc-true{
		display: none!important;
	}
	@function vm($px) {
		@return $px/(375 / 100vw);
	} 
	.container{
		margin-right: auto;
		margin-left: auto;
		max-width: vm(280);
		width: 100%;
	}
	.row{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	
	.flex-a{
		display: flex;
		align-items: center;
	}
	.flex-t{
		display: flex;
		align-items: flex-start;
	}
	.flex-b{
		display: flex;
		align-items: flex-end;
	}
	.flex-c{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.photo-bg{
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.cicle{
		border-radius: 50%;
	}
	.title{
		font-family: 'Trajan Pro 3';
		span{
			color: #9B7F62;
		}
	}
	.first-screen{
		
		.content{
			padding-top: vm(90);
			padding-bottom: vm(30);
			border-radius: vm(20);
		}
	}

	.section-padding{
		padding: vm(0);
		.content{
			border-radius: vm(20);
		}
	}

	.first-screen{
		.content{
			border-radius: 0 0 vm(20) vm(20);
		}
	}
	.first-screen__title{
		margin-bottom: vm(15);

		font-size: vm(30);
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 48px */
		letter-spacing: vm(-1.2);
	}
	.first-screen-terms{
		flex-direction: column;
		align-items: flex-start;
	}
	.first-screen-term{
		border-radius: vm(6);
		background: #FFF;
		display: flex;
		align-items: center;
		color: #3E4953;
		padding: vm(7) vm(10);
		font-family: "Wix Madefor Display";
		font-size: vm(10);
		font-style: normal;
		font-weight: 800;
		line-height: 125%; /* 13.75px */
		letter-spacing: vm(0.55);
		text-transform: uppercase;
		margin-bottom: vm(5);
		img{
			margin-right: vm(5);
			max-width: vm(10);
		}
		margin-right: vm(0);
		display: inline-flex;

		&:last-child{
			margin-bottom: 0;
		}
	}
	.first-screen-count{
		margin-top: vm(25);
		flex-direction: column;
		align-items: flex-start;
		.wrapper{
			display: flex;
			align-items: center;
			border-radius: vm(7.491);
			background: #FFF;
			backdrop-filter: blur(vm(16.8));
			justify-content: center;
			width: vm(281);
			height:vm(37.4);
		}
		span{
			color: rgba(155, 127, 98, 0.25);
			margin-right: vm(7.29);
			margin-left: vm(7.29);
			font-size: vm(8.24);
			font-weight: 700;
			line-height: 125%;
			letter-spacing: vm(0.42);
			text-transform: uppercase;
		}
	}
	.first-screen-count__title{
		color: #3E4953;
		margin-right: vm(0);
		font-size: vm(17);
		font-weight: 400;
		line-height: 125%;
		margin-bottom: vm(5.27);
	}
	.reviews-slider-wrapper{
		display: flex;
		align-items: center;
		width: vm(327);
		max-width: vm(327);
		margin-right: vm(-20);
		margin-left: vm(0);
		border-radius: vm(11.923);
		background: #FFF;
		padding-top: vm(16);
		padding-bottom: vm(17.69);
		margin-top: vm(351);
		padding-left: vm(16);
		position: relative;
	}
	.reviews-slide{
		width: vm(185);
		margin-right: vm(4);
		margin-left: vm(4);
		.photo-bg{
			width: 100%;
			height: vm(80);
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: grey;
			border-radius: vm(8);
			margin-bottom: vm(12);
		}
		.reviews-slide__text{
			color: #3E4953;
			font-family: Roboto;
			font-size: vm(12);
			font-weight: 300;
			line-height: 125%;
		}
	}
	.reviews-slider{
		width: 100%;
		max-width: vm(960);
		margin-right: vm(-4);
		margin-left: vm(-4);
	}
	.reviews-slide__play{
		border-radius:vm(50);
		background: #FFF;
		width:vm(40);
		height:vm(40);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.first-line{
		border-radius: vm(8);
		bottom: 0px;
		background: #FFF;
		backdrop-filter: blur(vm(17.5));
		padding-top: vm(10);
		padding-bottom: 0;
		height: vm(39);
		overflow: hidden;
		.slide{
			color: #3E4953;
			font-family: Roboto;
			font-size: vm(15);
			font-weight: 400;
			line-height: 125%;
			letter-spacing: vm(-0.45);
			span{
				color: #9B7F62;
			}
		}
	}
	.first-screen{
		position: relative;
	}
	.first-line-wrapper{
		position: absolute;
		margin-top: 0;
		left: 20px;
		bottom: 284px;
	}
	.first-line__btn{
		
		border-radius: vm(10);
		background: #3E4953;
		width: vm(280);
		height:vm(60);
		
		background: #9B7F62;
		box-shadow: 0px vm(4) vm(25) 0px rgba(255, 255, 255, 0.35) inset;


		color: #F8FBFF;
		font-size: vm(16);
		font-weight: 500;
		line-height: vm(22); /* 138.889% */
		letter-spacing: vm(-0.48);
		&:hover{
			color: #F8FBFF;
		}

		right: unset;
		top: unset;
		bottom: -18.66667vw;
		position: absolute;
		left: vm(27);
		transform: unset;

	}

	.first-screen .content{
		background-image: url('../img/bg/first-screen-mob.jpg')!important;
	}
	.hello .content{
		background-image: url('../img/bg/hello-mob.jpg')!important;
	}

	.hello{
		padding: vm(10);
		.container{
			padding-left: vm(0);
		}
		.content{
			border-radius: vm(20);
			padding-top: vm(50);
			padding-bottom: vm(145);
		}
	}
	.hello__title{
		color: #F6F7F8;
		font-size:vm(35);
		font-weight: 600;
		line-height:vm(35);
		letter-spacing: vm(-1.5);
		margin-bottom:vm(25);
	}
	.hello-text{
		color: #F6F7F8;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		margin-bottom: vm(137);
		p{
			margin-bottom: vm(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.hello-wrapper{
		margin-left: vm(0);
	}
	.hello__fio{
		color: rgba(246, 247, 248, 0.65);
		font-size: vm(13);
		font-weight: 400;
		line-height: 125%;
		strong{
			color: #F6F7F8;
			font-weight: 700;
		}
	}
	.hello-wrapper{
		position: relative;
	}
	.hello-text strong{
		font-weight: 700;
	}
	.hello__quote{
		display: none;
	}
	.hello__signature{
		display: none;
	}
	.first-screen{
		padding-bottom: 0;
	}
	.section-title{
		color: #444;
		-webkit-text-stroke-width: 1;
		-webkit-text-stroke-color: rgba(155, 127, 98, 0.55);
		font-family: "Trajan Pro 3";
		font-size: vm(27);
		font-weight: 400;
		line-height: 120%; /* 54px */
		letter-spacing: vm(-1.35);
	}
	.portfolio{
		padding-top: vm(40);
		.section-title{
			margin-bottom: vm(10);
		}
	}
	body .sheepskin{
		margin-bottom: vm(20);
	}
	.sum{
		padding-left: vm(0);
		padding-right: vm(0);
		.content{
			border-radius: vm(25);
			padding-top: vm(50);
			padding-bottom: vm(337);
			padding-left: vm(48);
			padding-right: vm(47);
			min-height: vm(705);
			background-image: url('../img/bg/sum-mob.jpg')!important;
		}
		.section-title{
			margin-bottom: vm(20);
		}
	}
	.section-title{
		span{
			color: #9B7F62;
		}
	}
	.sum__text{
		color: #3E4953;
		max-width: vm(400);
		font-family: Roboto;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		p{
			margin-bottom: vm(25);
			&:last-child{
				margin-bottom: 0;
			}
		}
		strong{
			font-weight: 500;
		}
	}
	.big-block{
		padding-right: vm(10);
		padding-left: vm(10);
		.content{
			border-radius: vm(20);
		}
	}
	.section-title--center{
		text-align: center;
	}
	.big-block{
		.content{
			padding-top: vm(90);
			padding-bottom: vm(90);
		}
	}
	.care{
		margin-top: vm(10);
		
		.content{
			background: #3C464F;
			padding-top: vm(50);
			padding-bottom: vm(46);
		}
		.section-title{
			font-size: vm(27);
			line-height: 120%; /* 66px */
			letter-spacing: vm(-0.81);
			margin-bottom: vm(20);
			text-align: left;
		}
		.arrow-left{
			transform: scale(-1, 1);
		}
	}
	.white{
		color: #FFF;
		.section-title{
			color: #FFF;
		}
	}
	.care-subtitle{
		margin-right: auto;
		margin-left: auto;
		max-width: vm(567);
		text-align: left;
		font-size: vm(14);
		font-style: normal;
		font-weight: 400;
		line-height: 125%;
		img{
			display: none;
		}
	}
	.care-subtitle__bottom{
		display: flex;
		align-items: center;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		justify-content: flex-start;
		margin-top: vm(20);
		font-weight: 500;
		img{
			display: none;
		}
		.arrow-left{
			margin-right: 0;
			margin-left: vm(15);
		}
		strong{
			font-weight: 500;
		}
	}
	.care-block{
		width: 100%;
		border-radius: vm(10);
		background: #628D9B;
		box-shadow: 0 vm(4) vm(40) 0 rgba(255, 255, 255, 0.25) inset;
		max-width: vm(280);
		min-width: vm(280);
		margin-right: vm(15);
		padding: vm(30) vm(20);
		.wrapper{
			padding-left: vm(10);
		}
	}
	.care-block__title{
		color: #FFF;
		font-family: Roboto;
		font-size: vm(18);
		font-weight: 700;
		line-height: 125%;
		margin-bottom: vm(15);
	}
	.care-block__text{
		color: rgba(255, 255, 255, 0.75);
		font-family: Roboto;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		min-height: vm(126);
		margin-bottom: vm(20);
		p{
			margin-bottom: vm(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
		
	}
	.care-block__photo{
		width: 100%;
		border-radius: vm(10);
		background-color: grey;
		height: vm(150);
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding-right: vm(10);
		padding-bottom: vm(10);
	}
	.care-row{
		margin-top: vm(30);
		flex-wrap: nowrap;
		overflow-x: scroll;
		margin-right: vm(-37.5);
		padding-right: vm(20);
		.section-title span{
			color: #E3CDB5;
		}
	}
	.care-block__loop{
		border-radius: vm(10);
		background: #FFFFFF;
		width: vm(45);
		height: vm(45);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.care-block--gray{
		background: #9B7F62;
		box-shadow: 0 vm(4) vm(40) 0 rgba(255, 255, 255, 0.25) inset;
		.care-block__loop{
			background: rgba(155, 127, 98, 0.20);
		}
	}
	.questions{
		padding-top: vm(50);
		padding-bottom: vm(50);
	}
	.questions-row{
		margin-top: vm(30);
		margin-bottom: vm(-10);
		align-items: flex-start;
	}
	.questions-block{
		border-radius: vm(10);
		background: #FFF;
		padding-left: vm(25);
		padding-right: vm(25);
		width: vm(330);
		max-width: unset;
		cursor: pointer;
		margin-bottom: vm(10);
		margin-left: vm(-22.5);
		margin-right: vm(-22.5);
		.top{
			display: flex;
			align-items: center;
			
			justify-content: space-between;
			padding-top: vm(22);
			padding-bottom: vm(22);
			transition: .4s all ease;
			min-height: vm(80);
		}
		.hidden{
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

		}
		.content{
			padding-bottom: vm(25);
			padding-top: vm(20);
			border-top: vm(1) solid rgba(0, 0, 0, 0.05);
			

			color: rgba(62, 73, 83, 0.75);
			font-size: vm(14);
			font-weight: 400;
			line-height: 125%;
			p{
				min-height: vm(80);
			}
			a{
				color: #9B7F62;
				text-decoration-line: underline;
				text-decoration-skip-ink: none;
			}
		}
	}
	.questions-block .content{
		br{
			display: none;
		}
		br.mob-true{
			display: block;
		}
	}
	.questions-block .content p{
		min-height: unset;
	}
	.questions-block__arrow{
		max-width: vm(12);
	}
	.questions-block__title{
		color: #3E4953;
		font-size: vm(15);
		font-weight: 500;
		line-height: 120%; 
		letter-spacing: vm(-0.45);
	}
	.questions-block__arrow{
		transition: .4s all ease;
	}
	.questions-block.opened{
		.top{
			padding-bottom: vm(20);
		}
		.hidden{
			max-height: vm(2000);
			transition: max-height 1s ease-in-out;
		}
		.questions-block__arrow{
			transform: scale(1, -1);
		}
	}
	.deeper-block{
		max-width: vm(374);
		width: 100%;
		padding-left: vm(26.47);
		padding-top: vm(26.47);
		height: vm(220);
		position: relative;
		border-radius: vm(22);

		margin-bottom: vm(10.41);
		&:last-child{
			margin-bottom: 0;
		}
	}
	.deeper{
		padding-bottom: vm(50);
		.section-title{
			margin-bottom:vm(25);
		}
	}
	.deeper-row{
		margin-right: vm(-25);
		margin-left: vm(-25);
	}
	.deeper-block__play{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: vm(61.765);
		height: vm(61.765);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: #fff;
	}
	.deeper-block__title{
		color: #FFF;
		font-family: "Gotham Pro";
		font-size:vm(12.353);
		font-weight: 700;
		line-height: 120%;
		letter-spacing: vm(0.6);
		text-transform: uppercase;
		margin-bottom: vm(13.6);
		strong{
			color: #E3CDB6;
		}
	}
	.lose{
		margin-bottom:vm(0);
		.content{
			border-radius: vm(20);
			box-shadow: 0 vm(4) vm(70) 0 rgba(255, 255, 255, 0.20) inset;
			padding-top: vm(50);
			padding-bottom:vm(28);
			background-image: url('../img/bg/lose-bg-mob.jpg')!important;
		}
		.widget{
			margin-top: vm(15);
			background: #9B7F62;
		}
	}
	.section-title--big{
		font-size: vm(35);
		font-weight: 400;
		line-height: 110%;
		letter-spacing: vm(-1.05);
	}
	.lose__subtitle{
		margin-top: vm(12);
		margin-bottom: vm(100);
		color: rgba(255, 255, 255, 0.80);
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		letter-spacing: vm(-0.54);
		max-width: vm(170);
		strong{
			font-weight: 500;
		}

		.lose__fio{
			color: rgba(255, 255, 255, 0.40);
			font-family: Roboto;
			font-size: vm(12);
			font-weight: 400;
			line-height: 125%;
			position: static;
			margin-top: vm(10);
			strong{
				color: rgba(255, 255, 255, 0.60);
			}
		}
	}
	.section-form{
		border-radius: vm(10);
		background: #FFF;
		padding: vm(10);
		display: flex;
		align-items: center;
		justify-content: unset;
		flex-direction: column;
	}
	.form-btn{
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: vm(15);
		background: #2A3138;
		box-shadow: 0 vm(4) vm(15) 0 rgba(255, 255, 255, 0.15) inset;
		width: 100%;
		height: vm(70);
		color: #FFF;
		border: none;
		cursor: pointer;
		color: #FFF;

		text-align: center;
		font-size: vm(16);
		font-weight: 700;
		line-height: vm(20);
	}
	.form-input{
		border-radius: vm(10);
		background: #F6F7F8;
		width: 100%;
		height: vm(70);
		padding-left: vm(54);
		border: none;


		font-family: Roboto;
		color: rgba(62, 73, 83, 1);
		font-size: vm(14);
		font-weight: 400;
		line-height: vm(22);
		&::placeholder{
			font-family: Roboto;
			color: rgba(62, 73, 83, 0.55);
		}
	}
	.form-label{
		position: relative;
	}
	.form-label__img{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: vm(25);
	}
	.form-label{
		width: 100%;
		margin-bottom: vm(6);
	}
	.selectize-control{
		width: 100%;
		margin-bottom: vm(6);
	}
	.selectize-input{
		border-radius: vm(15);
		background: #F6F7F8;
		width: 100%;
		height: vm(70);
		border: none!important;
		box-shadow: none!important;
		border-radius: vm(10);
		background: #F6F7F8!important;
		padding-left: vm(25.5);

		color: rgba(62, 73, 83, 0.55);
		transition: .4s all ease;
		font-family: Roboto;
		font-size: vm(14);
		font-style: normal;
		font-weight: 400;
		line-height: vm(22); /* 129.412% */
		letter-spacing: vm(-0.51);
		display: flex!important;
		align-items: center;
		padding-top: 0;
		padding-bottom: 0;
	}
	.section-form__polcy{
		margin-top: vm(10);
		color: rgba(255, 255, 255, 0.45);
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		letter-spacing: vm(-0.42);
		text-align: center;
		margin-right: auto;
		margin-left: auto;
		max-width: vm(235);
		a{
			color: #FEEBD6;
			text-decoration: underline;
			text-decoration-skip-ink: none;
		}
	}

	.selectize-control.single .selectize-input:after{
		width: vm(12);
		height: vm(7);
		background-image: url('../img/icons/select-arrow.svg');
		padding: vm(0);
		border: none;
		background-size: cover;
		right: vm(30);
		transition: .4s all ease;
	}
	.selectize-control.single .selectize-input.dropdown-active:after{
		margin-top: vm(-3);
		transform: scale(1, -1);
	}
	.selectize-dropdown .create, .selectize-dropdown .no-results, .selectize-dropdown .optgroup-header, .selectize-dropdown .option{
		padding-top: vm(8);
		padding-bottom: vm(8);
		padding-left: vm(25.5);
		background: #F6F7F8;
		color: rgba(62, 73, 83, 0.55);
		transition: .4s all ease;
		font-family: Roboto;
		font-size: vm(14);
		font-style: normal;
		font-weight: 400;
		line-height: vm(22); /* 129.412% */
		letter-spacing: vm(-0.51);
	}
	.selectize-dropdown{
		border: none;
	}
	.lose{
		.container{
			position: relative;
		}
	}
	.lose__fio{
		position: absolute;
		color: rgba(246, 247, 248, 0.65);
		font-size: vm(16);
		font-weight: 400;
		line-height: 125%;
		right: vm(49);
		bottom: vm(210);
		strong{
			color: #F6F7F8;
			font-weight: 700;
		}
	}
	.every-counter{
		
	}
	.price{
		margin-bottom: vm(53);
		margin-top: vm(120);
		.content{
			padding-top: vm(50);
			padding-bottom:vm(28);
			
		}
		.section-title{

		}
		.container{
			position: relative;
		}
		.form-btn{
			background: #9B7F62;
			box-shadow: 0 vm(4) vm(15) 0 rgba(255, 255, 255, 0.35) inset;
		}
		.section-form__polcy{
			color: rgba(255, 255, 255, 0.55);
			a{
				color: #1A96DD;
			}
		}
	}
	.price .content{
		background-image: url('../img/bg/price-bg.jpg')!important;
	}
	.price-name{
		flex-direction: column;
		align-items: flex-start;
		margin-top: vm(35);
		.cicle{
			display: none;
		}
		margin-bottom: vm(100);
		color: rgba(255, 255, 255, 0.90);
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		letter-spacing: vm(-0.42);
		padding-left: vm(110);
	}
	.price-fio__text{
		color: rgba(255, 255, 255, 0.65);
		font-size: vm(16);
		font-weight: 400;
		line-height: 125%;
		position: relative;
		&::after{
			width: vm(10);
			height: vm(10);
			content: '';
			display: block;
			background-size: cover;
			background-image: url('../img/price/line.svg');
			position: absolute;
			left: vm(0);
			top: vm(-25);
		}
		strong{
			color: #FFF;
			font-weight: 500;
		}
	}
	.price{
		.price-fio__text::after{
			display: none;
		}
		.price-fio__text{
			color: rgba(255, 255, 255, 0.40);
			margin-top: 10px;
			font-family: Roboto;
			font-size: vm(12);
			font-weight: 400;
			line-height: 125%;
			strong{
				font-weight: 500;
				color: rgba(255, 255, 255, 0.60);
			}
		}
	}
	.block-small{
		padding-right:vm(50);
		padding-left:vm(50);
		.content{
			border-radius: vm(20);
		}
	}
	.latent-happening{
		display: flex;
		align-items: center;
	}
	.latent{
		padding-bottom: vm(10);
		margin-bottom: vm(10);
		padding-right: vm(10);
		padding-left: vm(10);
		.content{
			padding-top: vm(50);
			padding-bottom: vm(249);
		}
		.section-title{
			color: #F5F5F5;
			span{
				color: #E3CDB5;
			}
		}
	}

	.latent__text{
		max-width: vm(280);
		font-size: vm(14);
		margin-top: vm(15);
		font-weight: 300;
		line-height: 125%;
		p{
			margin-bottom: vm(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.latent-happening{
		display: flex;
		align-items: unset;
		border-radius: 0%;
		background: unset;
		background-image: url('../img/icons/latent-cicle.svg');
		width: vm(120);
		height: vm(120);
		padding-left: vm(22);
		padding-top: vm(34);
		justify-content: flex-start;
		margin-bottom: vm(0);
		img{
			display: none;
		}

		color: #FFF;

		font-family: "Trajan Pro 3";
		font-size: vm(14);
		font-weight: 400;
		line-height: 120%;
		letter-spacing: vm(-0.42);
		text-transform: uppercase;
		span{
			position: relative;
			top: vm(0);
		}
		position: absolute;
		top: -22.66667vw;
		right: -3.33333vw;
	}
	.latent .content{
		background-image: url('../img/bg/latent-mob.jpg')!important;
	}
	.latent{
		.container{
			position: relative;
		}
	}
	.latent__zoom{
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: vm(45);
		height: vm(45);
		background: #FFF;
		bottom: -44.66667vw;
		right: 1vw;
	}
	.sheepskin .content::after{
		display: none!important;
	}
	.sum .content{

	}
	.sheepskin{
		padding-right: vm(10);
		padding-left: vm(10);
		.content{
			padding-bottom:vm(39);
			padding-top: vm(50);
			background-image: url('../img/bg/sheepskin-mob.jpg')!important;
		}
		.section-form{
			width: vm(300);
			margin-right: vm(-10);
			margin-left: vm(-10);
		}
		.widget{
			width: vm(300);
			margin-right: vm(-10);
			margin-left: vm(-10);
			margin-top: vm(15);
			border-radius: vm(10);
			background: #9B7F62;
			box-shadow: 0px vm(4) vm(35) 0px rgba(255, 255, 255, 0.25) inset;
		}
		.section-title{
			color: #FFF;

			font-family: "Trajan Pro 3";
			font-size: vm(27);
			font-style: normal;
			font-weight: 400;
			line-height: 110%; /* 29.7px */
			letter-spacing: vm(-0.81);
		}

		.price-fio__text{
			color: rgba(255, 255, 255, 0.40);
			font-size: vm(12);
			font-weight: 400;
			line-height: 125%;
			margin-top: vm(10);
			strong{
				font-weight: 500;
				color: rgba(255, 255, 255, 0.60);
			}
		}
		.price-fio__text::after{
			display: none;
		}
	}
	.latent .content::after{
		display: none!important;
	}
	.sheepskin__text{
		color: rgba(255, 255, 255, 0.80);
		margin-top: vm(30);
		margin-bottom: vm(100);
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%; /* 22.5px */
		letter-spacing: vm(-0.42);
		max-width: vm(170);
	}
	.latent{
		margin-bottom: 0;
	}
	.sheepskin{
		.container{
			position: relative;

		}
		.price-fio__text::after{
			background-image: url('../img/icons/line-white.svg');
		}
		.price-fio-abc{
			right: vm(90);
			bottom: vm(229);
		}
	}
	.sheepskin{
		margin-bottom:vm(50);
		.content{
			position: relative;
			&::after{
				position: absolute;
				width: vm(24);
				height: vm(28);
				background-size: cover;
				background-position: center;
				background-image: url('../img/icons/polygon-2.svg');
				content: '';
				display: block;
				z-index: 100;
				top: vm(65);
				right: vm(-12);
			}
		}
	}
	.latent{
		.content{
			position: relative;
			&::after{
				position: absolute;
				width: vm(24);
				height: vm(28);
				background-size: cover;
				background-position: center;
				background-image: url('../img/icons/polygon-1.svg');
				content: '';
				display: block;
				z-index: 100;
				top: vm(65);
				left: vm(-12);
			}
		}
	}
	.seems{
		padding-bottom: vm(50);
	}
	.seems-row{
		margin-top: vm(20);
	}
	.seems-line{
		margin-bottom: 0;
		flex-direction: column;
		justify-content: unset;
		align-items: flex-start;
		border-radius: vm(10);
		background: #628D9B;
		box-shadow: 0px vm(4) vm(25) 0px rgba(255, 255, 255, 0.25) inset;
		margin-bottom: vm(15);
		&:last-child{
			margin-bottom: 0;
		}
		&:first-child{
			.seems-name{
				&::after{
					top: vm(25);
				}
			}
		}
	}
	.seems-name{
		background: transparent;
		box-shadow: unset;
		width: 100%;
		max-width: 100%;
		padding-left: vm(20);
		padding-top: vm(20);
		padding-bottom: vm(15);

		color: #FFF;
		position: relative;
		font-size: vm(16);
		font-weight: 500;
		line-height: 125%;
		&::after{
			position: absolute;
			top: vm(35);
			transform: rotate(90deg);
			width: vm(10);
			height: vm(10);
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/seems-arrow.svg');
			content: '';
			display: block;
			right: vm(17.5);
			opacity: .2;
			
		}
	}
	.seems-content{
		border-radius: vm(10);
		background: #FFF;
		width: 100%;
		display: flex;
		align-items: center;
		max-width: 100%;

		color: #444;
		font-size: vm(15);
		font-weight: 300;
		line-height: 125%;

		padding: vm(25);
	}
	.mobile-scroll{
		display: flex;
		align-items: flex-start;

		color: rgba(68, 68, 68, 0.35);

		font-family: Roboto;
		font-size: vm(13);
		font-style: normal;
		font-weight: 400;
		line-height: 125%;
		img{
			max-width: vm(15);
			margin-right: vm(15);
		}
	}
	.every{
		.mobile-scroll{
			margin-top: vm(15);
		}
	}
	.every-slider{
		margin-top:vm(30);
		margin-bottom: vm(46);
		margin-left: vm(0);
		margin-right: vm(-47.5);
	}
	.every-slide{
		width: vm(280);
		margin-right: vm(10);
		margin-left: vm(10);
		.photo-bg{
			height: vm(150);
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: grey;
			border-radius: vm(11.23);
		}
	}
	.every-slide__text{
		color: #3E4953;
		margin-top: vm(10);
		font-size: vm(14);
		font-weight: 300;
		line-height: 125%;
	}
	.every-slide__link{
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: vm(60);
		height: vm(60);
		background: #FFFFFF;
	}
	.messag-cicle{
		width: vm(130);
		height: vm(130);
		flex-shrink: 0;
		margin-top: vm(10);
		align-self: flex-end;
		margin-right: vm(25);
	}
	.every-messag:last-child{
		margin-top: -35.66667vw;
		.messag-cicle{
			align-self: flex-start;
			margin-bottom: vm(10);
			margin-left: vm(25);
			margin-right: 0;
		}
	}
	.messag-content{
		max-width: 100%;
		max-width: vm(900);
		
		padding: vm(25);

		border-radius: vm(15);
		background: #9B7F62;
	}
	.every-messag{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.every{
		margin-bottom: vm(50);
	}
	.messag-content__title{
		color: #FFF;
		margin-bottom: vm(15);
		font-size: vm(18);
		font-weight: 600;
		line-height: 125%;
	}
	.messag-content__text{
		color: #FFF;

		font-size: vm(14);
		font-weight: 300;
		line-height: 125%;
	}
	.messag-content__direktor{
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: vm(10);

		color: rgba(255, 255, 255, 0.55);

		font-size: vm(13);
		font-style: italic;
		font-weight: 400;
		line-height: 125%;
		strong{
			font-weight: 400;
			color: #FFF;
		}
	}
	.every-messages{
		width: vm(330);
		margin-right: vm(-25);
		margin-left: vm(-25);
	}
	.messag-content{
		position: relative;
		width: 100%;
		&::after{
			position: absolute;
			content: '';
			display: block;
			width: vm(41);
			height: vm(38);
			background-size: cover;
			background-position: center;
			background-image: url('../img/every/every-quote.svg');
			top: unset;
			bottom: vm(11);
			right: unset;
			left: vm(86);
			transform: scale(-1, 1);
		}
		&::before{
			position: absolute;
			content: '';
			display: block;
			width: vm(22);
			height: vm(16);
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/poly-1-mob.svg');
			bottom: vm(-12);
			right: vm(14);
			z-index: -1;
		}
	}
	.every-messag{
		margin-bottom: vm(30);
		&:last-child{
			margin-bottom: 0;
			.messag-content{
				&::after{
					transform: none;
					top: vm(20);
					right: vm(20);
					bottom: unset;
					left: unset;
				}
				&::before{
					width: vm(22);
					height: vm(16);
					top: vm(-14);
					left: vm(25);
					bottom: unset;
					right: unset;
					background-image: url('../img/icons/poly-2-mob.svg');
				}
			}
		}
	}

	.every-counter{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-size: cover;
		background-image: url('../img/icons/cicle-bg.svg');
		width: vm(190);
		height: vm(190);
		position: relative;
		color: #FFF;

		font-family: "Trajan Pro 3";
		font-size: vm(14.937);
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 28.302px */
		letter-spacing: vm(-0.448);
		strong{
			color: #FFF;
			font-family: "Trajan Pro 3";
			font-size: vm(57);
			font-weight: 400;
			line-height: 120%;
			letter-spacing: vm(-1.71);
			margin-bottom: vm(-11);
			display: inline-block;
		}
		p{
			max-width: vm(115);
		}

	}
	.every{
		.container{
			position: relative;
		}
	}
	.every-counter-abc{
		position: absolute;
		right: -20.53333vw;
		top: -24vw;
	}
	.cooperation{
		margin-top: vm(120);
		position: relative;
		z-index: 5;
		.content{
			padding-top: vm(50);
			padding-bottom: 0;
			border-radius: vm(20);
			background: #9B7F62;
			box-shadow: 0 vm(4) vm(80) 0 rgba(255, 255, 255, 0.25) inset;
		}
		.section-title{
			text-align: left;
		}
	}
	.cooperation-subtitle{
		justify-content: flex-start;
		margin-top: vm(15);
		color: #FFF;
		align-items: flex-start;
		strong{
			font-family: Roboto;
			font-size: vm(14);
			font-style: normal;
			font-weight: 400;
			line-height: 125%;
			margin-right: vm(0);
			margin-left: vm(10);
			max-width: vm(175);
			span{
				color: rgba(255, 255, 255, 0.55);
			}
		}

		.cooperation-right{
			display: none;
		}
		img{
			max-width: vm(11);
			position: relative;
			top: vm(4);
		}
	}

	.cooperation-photo{
		width: vm(330);
		height: vm(185);
		margin-top: vm(25);
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		border-radius: vm(8);
		bottom: vm(49);
		background-image: url('../img/bg/cooperation-photo-mob.jpg')!important;
	}
	.cooperation{
		.content{
			height: vm(520);
			position: relative;
		}
	}
	.check{
		margin-top: vm(10);
		.content{
			padding-top: vm(50);
			padding-bottom: vm(20);
			border-radius: vm(20);
		}
	}
	.cooperation__play{
		width: vm(60);
		height: vm(60);
		background: #F6F7F8;
		img{
			max-width: vm(11);
		}
	}
	.check{
		.section-title{
			margin-bottom: vm(203);
			span{
				color: #FFE4C8;
			}
		}
		.price-fio-abc{
			right: vm(118);
			bottom: vm(706);
		}
		.price-fio__text{
			&::after{
				background-image: url('../img/icons/line-white.svg');
			}
		}
	}
	.check-block{
		max-width: vm(380);
		border-radius: vm(15);
		background: #F6F7F8;
		.top{
			position: relative;
			bottom: vm(2);
			display: flex;
			align-items: center;
			padding-left: vm(25);
			justify-content: space-between;
			padding-right: vm(0);
			padding-top: vm(20);
			padding-bottom: vm(21);
			border-radius: vm(10);
			background: #9B7F62;
			box-shadow: 0 vm(4) vm(25) 0 rgba(255, 255, 255, 0.15) inset;
		}
		.bottom{
			color: #3E4953;
			padding-left: vm(25);
			padding-top: vm(15);
			padding-bottom: vm(25);
			padding-right: vm(28);
		}
		.check-block__procent{
			
		}
	}
	.check-block-list{
		margin-top: vm(10);
	}
	.check-block__title{
		color: #FFF;
		font-size: vm(15);
		font-weight: 500;
		line-height: 125%;
		br{
			display: none;
		}
	}
	.check-block__procent{
		width: vm(73);
		height: vm(73);
		background-size: cover;
		padding-top: vm(27);
		padding-left: vm(17);
		background-image: url('../img/icons/check-circle-mob.svg');
		color: #9C8063;
		right: vm(11);
		top: vm(-26);
		font-family: "Trajan Pro 3";
		font-size: vm(20);
		font-weight: 400;
		line-height: 120%; /* 24px */
		letter-spacing: vm(-0.6);
		position: absolute;
	}
	.check-block__text{
		color: #3E4953;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		strong{
			font-weight: 500;
			color: #3E4953;
		}
	}
	.check-block-list{
		color: #3E4953;
		font-size: vm(14);
		font-weight: 400;
		line-height: 129%;
		li{
			padding-left: vm(21);
			position: relative;
			margin-bottom: 0;
			&::after{
				position: absolute;
				top: vm(5.7);
				width: vm(11);
				height: vm(9);
				background-size: cover;
				content: '';
				display: block;
				background-image: url('../img/icons/check-color-2.svg');
				left: 0;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.check-block{
		margin-bottom: vm(30);
	}
	.check .content{
		background-image: url('../img/bg/check-mob.jpg')!important;
	}
	.check{
		.price-fio-abc{
			right: unset;
			left: vm(38);
			top: vm(198);
			bottom: unset;
		}
		.price-fio__text{
			
			font-size: vm(12);
			font-weight: 400;
			line-height: 125%;
			color: rgba(255, 255, 255, 0.40);
			strong{
				color: rgba(255, 255, 255, 0.60);
			}
		}
		.price-fio__text::after{
			display: none;
		}
	}
	.check-line{
		border-radius: vm(10);
		background: #9B7F62;
		box-shadow: 0 vm(4) vm(55) 0 rgba(255, 255, 255, 0.25) inset;
		padding-top: vm(30);
		padding-bottom: vm(30);
		padding-left: vm(30);
		padding-right: vm(29);
		position: relative;
		width: vm(315);
		position: relative;
		right: vm(17.1);
		&::after{
			position: absolute;
			width: vm(59);
			height: vm(55);
			background-size: cover;
			background-position: center;
			content: '';
			display: block;
			background-image: url('../img/icons/check-quote.svg');
			top: unset;
			left: unset;
			right: vm(30);
			bottom: vm(34);
			transform: scale(-1, 1);
			opacity: .2;
		}
	}
	.check-line__title{
		color: #FFF;
		font-family: "Trajan Pro 3";
		font-size: vm(27);
		font-weight: 400;
		line-height: 120%;
		letter-spacing: vm(-0.81);
		margin-bottom: vm(9);
		strong{
			font-weight: 400;
			color: #FAE0C5;
		}
	}
	.check-line__text{
		color: #FFF;

		font-family: Roboto;
		font-size: vm(14);
		font-style: normal;
		font-weight: 400;
		line-height: 125%;
	}
	.check .content{
		position: relative;
	}
	.court{
		padding-top: vm(50);
	}
	.court-participation{
		margin-top: vm(25);
		margin-bottom: vm(15);
		border-radius: vm(10);
		background: #628D9B;
		box-shadow: 0 vm(4) vm(25) 0 rgba(255, 255, 255, 0.45) inset;
	}
	.court-participation__name{
		color: #FFF;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		position: relative;
		top: vm(3);
	}
	.court-participation__price{
		color: #FFF;
		font-family: "Trajan Pro 3";
		font-size: vm(30);
		font-weight: 400;
		line-height: 100%; /* 42px */
		letter-spacing: vm(-1.05);
		position: relative;
		top: vm(4);
	}
	.court-participation{
		padding-left: vm(25);
		max-width: vm(300);
		padding-top: vm(18);
		padding-bottom: vm(18);
	}
	.court-participation__name{
		margin-bottom: vm(6);
	}
	.court_subtext{
		color: #3E4953;
		margin-top: vm(20);
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		strong{
			font-weight: 700;
		}
	}
	.court-list{
		color: #3E4953;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		li{
			padding-left: vm(21);
			position: relative;
			margin-bottom: vm(5);
			&::after{
				position: absolute;
				width: vm(11);
				height: vm(9);
				content: '';
				display: block;
				background-size: cover;
				background-image: url('../img/icons/check-blue.svg');
				left: vm(0);
				top: vm(4.6);
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.court-participation{
		position: relative;
		&::after{
			position: absolute;
			width: vm(11);
			height: vm(15);
			background-size: cover;
			background-position: center;
			content: '';
			display: block;
			background-image: url('../img/icons/arrow-bottom.svg');
			top: vm(37);
			right: vm(26);
			//transform: rotate(90deg);
		}
	}
	.court{

		.content{
			position: relative;
			&::after{
				display: none;
			}
		}
		.container{
			padding-bottom: vm(80);
			border-bottom: vm(1) solid rgba(0, 0, 0, 0.02);
		}
	}
	.court-photo{
		width: vm(330);
		height: vm(209);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: vm(35);
		margin-right: vm(-22.5);
		margin-left: vm(-22.5);
		border-radius: vm(8.25);
	}
	.court-photo__play{
		display: flex;
		align-items: center;
		justify-content: center;
		width: vm(60);
		height: vm(60);
		border-radius: 50%;
		background: #FFFFFF;
	}
	.troubles-slide{
		display: flex!important;
		align-items: center;
		flex-direction: column;
	}
	.troubles-photo{
		width: vm(500);
		height: vm(528);
		flex-shrink: 0;
		border-radius: vm(20);
		margin-right:vm(60);
	}
	.troubles-photo{
		width: vm(330);
		height: vm(200);
		margin-right: 0;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: vm(185);
		border-radius: vm(10);
	}
	.troubles{
		.troubles-arrows{
			right: 0;
			width: vm(125);
			bottom: vm(-70);
			justify-content: space-between;
		}
		.troubles-arrow{
			margin-right: 0;
			margin-left: 0;
		}
		.container{
			padding-right: 0;
			padding-left: 0;
			max-width: vm(330);
		}
		.content{
			max-width: vm(280);
			margin-right: auto;
			margin-left: auto;
		}

		.slick-dots{
			display: flex!important;
			bottom: vm(-70);
			left: 6.33333vw;
		}
	}
	.troubles-btn{
		color: #628D9B;
		font-family: "Gotham Pro";
		font-size: vm(8);
		font-weight: 700;
		line-height: 125%;
		letter-spacing: vm(0.4);
		text-transform: uppercase;

		border-radius: vm(6);
		background: #FFF;
		padding: 0px;
		padding-bottom: vm(0);
		margin-right: vm(0);
		display: flex;
		align-items: center;
		justify-content: center;
		width: vm(120);
		height: vm(35);
		margin-bottom: vm(6);
		&:nth-child(2n){
			width: vm(154);
		}
	}
	.troubles-btns{
		margin-top: vm(25);
		margin-bottom:vm(240);
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: stretch;
	}
	.troubles-text{
		color: #3E4953;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		max-width: vm(540);
		p{
			margin-bottom: vm(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.troubles-slide{
		align-items: flex-start;
		position: relative;
	}
	.troubles-btn.active{
		color: #FFF;
		background: #628D9B;
		box-shadow: 0 vm(4) vm(15) 0 rgba(255, 255, 255, 0.35) inset;
	}
	.troubles-arrows{
		display: flex;
		align-items: center;
	}
	.troubles-arrow{
		width: vm(55);
		height: vm(55);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition: .4s all ease;
		svg path{
			transition: .4s all ease;
		}
		background: #628D9B;
		box-shadow: 0 vm(4) vm(25) 0 rgba(255, 255, 255, 0.35) inset;
	}

	.troubles-arrow.slick-disabled{
		background: #fff;
		svg path{
			fill: #628D9B;
		}
	}

	.troubles-next{
		transform: scale(-1, 1);
		margin-left: vm(20);
	}
	.troubles-arrows{
		position: absolute;
		right: vm(440);
		bottom: 0;
	}
	.troubles-slider-wrapper{
		position: relative;
	}
	.portfolio__subtitle{
		display: flex;
		align-items: center;
		img{
			margin-right:vm(16);
		}

		color: #3E4953;
		font-size: vm(15);
		font-weight: 400;
		line-height: 125%;
	}
	.portfolio-slider-wrapper{
		margin-top:vm(30);
	}
	.portfolio-block{
		border-radius: vm(15);
		background: #FFF;
		max-width: vm(279);
		padding-left: vm(35);
		padding-top: vm(30);
		height: vm(291);
		width: 100%;
		margin-bottom: vm(10);
		position: relative;
		margin-right: vm(10);
	}
	.portfolio-slider{
		max-width: 100%;
	}
	.portfolio-slide{
		display: flex!important;
		justify-content: space-between;
		flex-wrap: wrap;
		max-width: 100%;
	}
	.portfolio-slider-wrapper{
		margin-bottom: vm(110);
	}
	.portfolio-block__pretitle{
		color: rgba(62, 65, 80, 0.75);
		font-size:vm(14);
		font-weight: 400;
		line-height: 125%;
		margin-bottom: vm(10);
	}
	.portfolio-block__price{
		color: #3E4150;
		margin-bottom: vm(35);
		font-size: vm(25);
		font-weight: 700;
		line-height: 125%;
	}
	.portfolio-block__text{
		color: rgba(62, 65, 80, 0.45);
		font-size: vm(15);
		font-weight: 400;
		line-height: 125%;
		strong{
			color: #3E4150;
			font-weight: 500;
		}
		p{
			margin-bottom: vm(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.portfolio-block__zoom{
		width: vm(45);
		height: vm(45);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		border-radius: vm(10);
		background: rgba(98, 141, 155, 0.20);
		bottom: vm(20);
		right: vm(20);
	}
	.portfolio-logos{
		color: rgba(155, 127, 98, 0.45);
		font-family: "Gotham Pro";
		font-size: vm(6.6);
		font-weight: 700;
		line-height: 125%;
		letter-spacing: vm(0.55);
		text-transform: uppercase;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height:vm(30);
		width: vm(225);
		border-radius: vm(6);
		background: rgba(155, 127, 98, 0.10);
		backdrop-filter: blur(vm(13.5));
		position: relative;
		bottom: vm(2);
		span{
			margin-right: vm(6);
			margin-left: vm(6);
		}
	}
	.portfolio .first-screen-count__img:nth-child(1){
		max-width: vm(39);
	}
	.portfolio .first-screen-count__img:nth-child(3){
		max-width: vm(40);
	}
	.portfolio .first-screen-count__img:nth-child(5){
		max-width: vm(90);
	}

	.slick-dots li button{
		display: none;
	}
	.slick-dots li{
		width: vm(7);
		height: vm(7);
		border-radius: vm(10);
		background: rgba(155, 127, 98, 0.10);
		transition: .4s all ease;
		margin-right: vm(7);
		margin-left: vm(7);
	}
	.slick-dots{
		width: auto;
		margin-left: vm(-7);
		bottom: vm(-70);
		display: none;
	}
	.slick-dots{
		display: none!important;
	}
	.slick-dots li.slick-active{
		background: #9B7F62;
	}
	.portfolio-slider-wrapper{
		position: relative;
		.troubles-arrows{
			position: absolute;
			display: flex;
			justify-content: space-between;
			width: vm(125);
			transform: none;
			top: unset;
			bottom: vm(-70);
		}
	}
	.portfolio-next{
		transform: scale(-1, 1);
	}
	.portfolio-slider-wrapper{
		margin-bottom: 0;
		margin-right: vm(-48);
		.portfolio-slider{
			margin-bottom: 0;
		}
		.portfolio-block{
			margin-bottom: 0;
		}
	}
	.header-logo{
		max-width: vm(168);
		margin-right: vm(0);
	}

	.header__phone-mob{
		border-radius: vm(8);
		background: #FFF;
		width: vm(40);
		height: vm(40);
		margin-right: vm(5);
		img{
			max-width: vm(12);
		}
	}
	.header__burger{
		width: vm(40);
		height: vm(40);

		border-radius: vm(8);
		background: #9B7F62;
		box-shadow: 0px vm(4) vm(20) 0px rgba(255, 255, 255, 0.45) inset;
		img{
			max-width: vm(11);
		}
	}
	.header{
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		top: 0;
		z-index: 1000;
		padding-top: vm(20.5);
		padding-left:vm(25.5);
		padding-right:vm(25.5);
	}
	
	.soc-wc{
		background: #65BC63;
	}
	.soc-tg{
		background: #68B6C1;
	}
	.header-soc{
		margin-left: vm(-30);
	}
	.footer{
		padding-bottom: vm(41);
		padding-top: vm(50);
		.container{
			max-width: vm(280);
		}
		.header-logo{
			max-width: vm(231);
			position: relative;
			bottom: vm(0);
			margin-right: vm(0);
			margin-bottom: vm(30);
		}
		.header-row{
			align-items: flex-start;
		}
		.left{
			flex-direction: column;
			align-items: flex-start;
			padding-bottom: vm(55);
			margin-bottom: vm(50);
			width: 100%;
			position: relative;
			&::after{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				content: '';
				display: block;
				width: 100vw;
				height: vm(1);
				background: rgba(0, 0, 0, 0.05);
				bottom: 0;
			}
		}
		.right{
			flex-direction: column;
			align-items: flex-start;
			position: relative;
			padding-bottom: vm(50);
			margin-bottom: vm(30);
			&::after{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				content: '';
				display: block;
				width: 100vw;
				height: vm(1);
				background: rgba(0, 0, 0, 0.05);
				bottom: 0;
			}
		}
	}
	.footer-bottom{
		padding-top: vm(0);
		margin-top: vm(0);
		border: none;
		flex-direction: column;
		align-items: flex-start;
	}
	.footer__legal{
		color: #D0D0D0;
		font-family: Roboto;
		font-size: vm(14);
		font-weight: 400;
		line-height: vm(22);
		text-align: left;
		margin-top: vm(15);
	}
	.footer__copy{
		color: #D0D0D0;
		font-size: vm(14);
		font-weight: 400;
		line-height: vm(22);
		a{
			color: #9B7F62;
			text-decoration-line: underline;
		}
	}
	.footer-address__name{
		color: #3E4953;
		margin-bottom: vm(0);
		font-family: "Trajan Pro 3";
		font-size: vm(18);
		font-weight: 400;
		line-height: vm(22);
	}
	.footer-address__adress{
		color: #9B7F62;
		font-size: vm(14);
		font-weight: 400;
		line-height: vm(22); /* 137.5% */
	}
	.footer-address{
		position: relative;
		top: vm(2);
	}
	.modal-window{
		position: fixed;
		right: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000000;
		background: rgba(0,0,0, 0.2);
		display: flex;
		justify-content: center;
		overflow-y: scroll;
		padding: vm(10);
		transition: .4s all ease;
		opacity: 0;
		visibility: hidden;
		.inner{
			margin-top: auto;
			margin-bottom: auto;
			height: max-content;
			position: relative;
		}
	}
	.modal-window.active{
		opacity: 1;
		visibility: visible;
	}
	.modal-window__close{
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: vm(35);
		height: vm(35);
		right: vm(15);
		top: vm(15);
		border-radius: vm(6);
		border: vm(0.45) solid #628D9B;
		background: #F6F7F8;
		box-shadow: 0 vm(4) vm(10) 0 rgba(255, 255, 255, 0.25) inset;
		svg{
			max-width: vm(10);
		}
	}
	.modal-exit{
		.inner{
			border-radius: vm(20);
			background: #628D9B;
			box-shadow: 0 vm(4) vm(65) 0 rgba(255, 255, 255, 0.25) inset;
			padding:vm(60);
			max-width: vm(1160);
			width: 100%;
		}
	}
	.modal-exit__title{
		color: #FFF;
		font-family: "Trajan Pro 3";
		font-size: vm(20);
		font-weight: 400;
		line-height: 120%; /* 42px */
		letter-spacing: vm(-0.6);
		margin-bottom: vm(15);
	}
	.exit-photo{
		width: vm(330);
		height: vm(200);
		border-radius: vm(15);
		background-size: 101%;
		margin-right: vm(68);

		position: relative;
		right: 10.73333vw;
		margin-right: vm(0);
		margin-left: vm(0);
	}
	.exit-photo__play{
		img{
			max-width: vm(11);
		}
	}
	.exit-row{
		justify-content: flex-start;
	}
	.modal-exit__name{
		color: #FFF;

		font-family: Roboto;
		font-size: vm(15);
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-bottom: vm(15);
	}
	.modal-exit-list{
		color: rgba(255, 255, 255, 0.75);
		font-size: vm(14);
		font-weight: 400;
		max-width: 100%;
		li{
			margin-bottom: vm(10);
			position: relative;
			padding-left: vm(26);
			&::after{
				width: vm(11);
				height: vm(9);
				background-size: cover;
				background-position: center;
				content: '';
				display: block;
				background-position: center;
				background-image: url('../img/icons/check-white.svg');
				position: absolute;
				top: vm(4);
				left: 0;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.modal-exit__btn{
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: vm(10);
		background: #9B7F62;
		box-shadow: 0 vm(4) vm(35) 0 rgba(255, 255, 255, 0.25) inset;
		width: 100%;
		height: vm(60);
		margin-top: vm(15);

		color: #FFF;

		text-align: center;
		font-size: vm(14);
		font-weight: 700;
		line-height: vm(20); 
		img{
			margin-left: vm(10);
		}
	}
	.exit-photo__play{
		width: vm(60);
		height: vm(60);
		border-radius: 50%;
		background: #FFFFFF;
	}
	.modal-start{
		.inner{
			max-width: 100%;
			padding: vm(0);
			width: 100%;
			border-radius: vm(20);
			background: #FFF;
		}
	}
	.modal-start{

	}
	.start{
		border-radius: vm(10);
		padding:vm(13);
		padding-top: vm(45);
	}
	.start__title{
		font-family: "Trajan Pro 3";
		font-size: vm(27);
		font-weight: 400;
		line-height: 120%; /* 48px */
		letter-spacing: vm(-0.81);
		margin-bottom: vm(15);
		margin-left: auto;
		margin-right: auto;
		max-width: vm(280);
	}
	.start .hello__fio{
		display: none;
	}
	.start-text{
		color: #FFF;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		max-width: vm(280);
		margin-bottom: vm(15);
		margin-left: auto;
		margin-right: auto;
		p{
			margin-bottom: vm(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.start-block{
		border-radius: vm(10);
		background: #628D9B;
		box-shadow: 0 vm(4) vm(55) 0 rgba(255, 255, 255, 0.25) inset;
		max-width: vm(480);
		width: 100%;
		padding: vm(25);
		position: relative;
		&::after{
			position: absolute;
			width: vm(59);
			height: vm(55);
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/start-quote.svg');
			content: '';
			display: block;
			top: vm(-20);
			right: vm(20);
		}
	}
	.start-block__text{
		color: #FFF;
		margin-bottom: vm(20);
		font-size:vm(14);
		font-weight: 400;
		line-height: 125%;
		strong{
			font-weight: 500;
		}
	}
	.start-number__phone{
		color: #FFF;
		font-family: "Trajan Pro 3";
		font-size: vm(20);
		font-weight: 400;
		line-height: 125%;
	}
	.start{
		border-radius: vm(10);
		background: #9B7F62!important;
		box-shadow: 0px vw(4) vw(65) 0px rgba(255, 255, 255, 0.35) inset;
	}
	.start-block{
		background-size: cover;
		background-image: url('../img/bg/start-block-mob.jpg');
		.wrapper{
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.start-number__name{
		color: rgba(255, 255, 255, 0.75);
		font-size: vm(13);
		font-weight: 200;
		line-height: 125%; 
	}
	.start-block::after{
		display: none;
	}
	.start-number{
		margin-right: vm(0);
		position: relative;
		margin-bottom: vm(15);
		&:last-child{
			margin-right: 0;
		}
		&:first-child{
			&::after{
				position: absolute;
				width: vm(10);
				height: vm(15);
				background-size: cover;
				background-position: center;
				background-image: url('../img/icons/start-sim.svg');
				top: vm(5);
				right: vm(-25);
				content: '';
				display: block;
			}
		}
	}
	.start{
		position: relative;
		.hello__fio{
			position: absolute;
			top: vm(285);
			right: vm(66);
			font-size: vm(12);
		}
	}
	.webinar{
		max-width: vm(280);
		margin-right: auto;
		margin-left: auto;
		position: relative;
		width: 100%;
		padding-top: vm(30);
		padding-left: vm(0);
		padding-bottom:vm(33);
		padding-right: 0;

		.modal-exit__title{
			color: #3E4953;
			font-family: "Trajan Pro 3";
			font-size: vm(20);
			font-weight: 400;
			line-height: 120%;
			letter-spacing: vm(-1.2);
			margin-bottom: vm(240);
		}

		.modal-exit__name{
			color: #3E4953;
			font-size: vm(15);
			font-weight: 600;
			line-height: normal;
		}
		.exit-photo{
			
		}
		.exit-row{
			flex-direction: column;
			align-items: center;
		}
		.modal-exit-list{
			color: #3E4953;
		}
		.modal-exit__btn{
			background: #628D9B;
		}
		.modal-exit-list{
			li{
				&::after{
					background-image: url('../img/icons/check-blue.svg');
				}
			}
		}
	}
	.webinar__pretitle{
		color: #3E4953;
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		margin-bottom: vm(12);
		max-width: 53vw;
	}
	.modal-exit__title{
		margin-bottom:vm(50);
	}
	.modal-keys{
		.inner{
			border-radius: vm(20);
			background: #FFF;
			max-width: vm(1160);
			width: 100%;
		}
		.webinar{
			padding-left:vm(0);
		}
	}
	.keys{
		padding-left:vm(38);
		padding-top:vm(45);
		padding-right:vm(0);
		.section-title{
			font-size: vm(27);
			line-height: 120%;
			letter-spacing: vm(-0.91);
			margin-bottom: vm(20);
		}

	}
	.keys-text{
		color: #3E4953;
		margin-top: vm(20);
		font-size: vm(14);
		font-weight: 400;
		line-height: 125%;
		max-width: vm(280);
		strong{
			font-weight: 600;
		}
		p{
			margin-bottom: vm(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.keys-slide__photo{
		height: vm(150);
		width: 100%;
		border-radius: vm(10);
		margin-bottom: vm(10);
	}
	.keys-block{
		width:vm(249);
		max-width: unset;
		margin-bottom: vm(0);
		margin-right: vm(15);
	}
	.keys-slider-wrapper .troubles-arrows{
		max-width: vm(125);
		width: 100%;
		top: unset;
		bottom: vm(-75);
		right: vm(37);
	}
	.keys-slide__title{
		color: #3E4953;
		font-family: Roboto;
		font-size: vm(14);
		font-weight: 300;
		line-height: 125%; 
	}
	.modal-keys .webinar{
		padding-top: vm(125);
		.exit-photo{
			top: 82vw;
		}
	}
	.modal-window__close{
		z-index: 10000;
	}
	.keys{
		position: relative;
		z-index: 10;
	}
	.keys-slider-wrapper .slick-dots{
		display: flex!important;
	}
	.keys-slider-wrapper .slick-dots{
		width: unset;
	}
	.keys-slider-wrapper .slick-dots li{
		width: vm(7);
		height: vm(7);
		margin-right: vm(7);
		margin-left: vm(7);
	}
	.keys-slider{
		margin-top: vm(30);
		margin-bottom: 0!important;
	}
	.keys-slide__play{
		width: vm(70);
		height: vm(70);
		border-radius: 50%;
		background: #FFFFFF;
	}
	.keys-slide{
		display: flex!important;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;

	}
	.modal-start{
		.modal-window__close{
			border: none;
		}
	}

	.care-subtitle__bottom img{
		max-width: vm(15);
	}
	.care-block__photo{
		
	}
	.widget{
		border-radius: 10px;
		background: #363C41;
		box-shadow: 0px 4px 35px 0px rgba(255, 255, 255, 0.10) inset;
		padding: vm(15);
		padding-top: vm(20);
		margin-top: vm(20);
	}
	.widget__title{
		text-align: center;
		margin-bottom: vm(10);
		color: #FFF;
		font-family: "Trajan Pro 3";
		font-size: vm(18);
		font-weight: 400;
		line-height: 120%; /* 21.6px */
		letter-spacing: vm(-0.54);
		span{
			color: rgba(255, 255, 255, 0.25);
		}
	}
	.widget-btn{
		width: 100%;
		height: vm(60);
		border-radius: vm(10);
		margin-bottom: vm(5);

		color: #FFF;

		text-align: center;
		font-family: Roboto;
		font-size: vm(14);
		font-style: normal;
		font-weight: 700;
		line-height: vm(15);
		img{
			margin-left: vm(10);
		}
	}

	.widget-btn--tg{
		background: #628D9B;
		box-shadow: 0px vm(4) vm(15) 0px rgba(255, 255, 255, 0.35) inset;
		img{
			max-width: vm(18);
		}
	}

	.widget-btn--wc{
		background: #65BC63;
		box-shadow: 0px vm(4) vm(15) 0px rgba(255, 255, 255, 0.35) inset;
		img{
			max-width: vm(15);
		}
	}

	.header-soc__item{
		img{
			max-width: vm(22);
		}
	}
	.form-label__img{
		max-width: vm(14);
	}
	.deeper-block__play{
		img{
			max-width: vm(11);
		}
	}
	.cooperation__play{
		img{
			max-width: vm(11);
		}
	}
	.troubles-arrow{
		svg{
			max-width: vm(7);
			width: 100%;
		}
	}
	.latent-happening{
		img{
			max-width: vm(11);
		}
	}
	.latent__zoom{
		img{
			max-width: vm(15);
		}
	}
	.every-slide__link{
		img{
			max-width: vm(11);
		}
	}
	.portfolio__subtitle img{
		max-width: vm(27);
	}
	.portfolio-block__zoom{
		img{
			max-width: vm(15);
		}
	}
	.reviews-slider__arrow img{
		max-width: vm(10);
	}
	.first-screen-term img{
		max-width: vm(11);
	}
	.first-screen-term{
		white-space: nowrap;
	}
	.reviews-slide__play{
		img{
			max-width: vm(7);
		}
	}
	.reviews-slider__arrow{
		white-space: nowrap;
	}
	.hello__signature{
		max-width: vm(160);
	}
	.hello__quote{
		max-width: vm(92);
	}

	.first-screen-count__img{
		&:nth-child(1){
			max-width: vm(50);
		}
		&:nth-child(3){
			max-width: vm(50);
		}
		&:nth-child(5){
			max-width: vm(112);
		}
	}

	svg path{
		width: 100%;
	}
	.court-photo__play{
		img{
			max-width: vm(10);
		}
	}
	.overflow{
		overflow: hidden;
	}

	.keys-slide__play{
		img{
			max-width: vm(11);
		}
	}
	.modal-exit__btn{
		img{
			max-width: vm(15.7);
		}
	}
	.court-mob-wrapper{
		justify-content: space-between;
		margin-top: vm(20);
	}
	.court-mob__arrow{
		width: vm(100);
		height: vm(54);
		border-radius: vm(100);
		background: #FFF;
		img{
			max-width: vm(14);
		}
	}
	.exit-photo{
		margin: 0;
		left: 50%;
		transform: translateX(-50%);
		top: vm(213);
		right: unset;
		position: absolute;
	}
	.court_subtext{
		margin-top: 0;
	}
	.court .container{
		padding-bottom: vm(50);
	}
	.header{
		
	}


	.header-number__phone{
		font-size: 24px;
		font-weight: 400;
		line-height: 125%;
	}
	.header-number__name{
		color: rgba(62, 73, 83, 0.75);
		font-size: vm(14);
		font-weight: 200;
		line-height: 125%;
	}
	.header-number{
		align-items: flex-start;
		margin-right: 0;
		margin-bottom: vm(30);
		&:last-child{
			margin-bottom: 0;
		}
	}

	.header-soc{
		margin-left: 0;
		.header-soc__item.soc-wc{
			order: -1;
			margin-right: vm(10);
		}
	}
	.header-soc__item{
		width: vm(55);
		height: vm(55);
		margin-left: 0;
		margin-right: 0;
		border-radius: vm(9.167);
		box-shadow: 0px vm(4) vm(10) 0px rgba(255, 255, 255, 0.25) inset;
		&:last-child{
			margin-right: 0;
		}
	}
	.soc-tg{
		background: #62949B;
	}
	.footer{
		padding-bottom: vm(60);
	}
	.latent-happening{
		background-size: cover;
	}
	.start-number:first-child::after{
		display: none;
	}

	.modal-menu .left{
		width: 100%;
	}
	.modal-menu{
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #F6F8FA;
		z-index: 1000000;
		padding-top: vm(20);
		transform: translateY(-100%);
		transition: .4s all ease;
		.top-row{
			padding-right: vm(25);
			padding-left: vm(25);
			margin-bottom: vm(99.5);
		}
		.left{
			flex-direction: column;
			align-items: flex-start;
		}
		.right{
			flex-direction: column;
			align-items: flex-start;
		}
		.footer-address{
			padding-bottom: vm(40);
			margin-bottom: vm(50);
		}
		.header-links{
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			max-width: 100%;
			padding-bottom: vm(30);
			margin-bottom: vm(40);
			display: flex;
		}
		.header__burger img{
			max-width: vm(13);
		}
		.header-link{
			color: rgba(62, 73, 83, 0.75);
			display: block;
			width: 100%;
			max-width: 100%;
			font-family: Roboto;
			font-size: vm(14);
			font-weight: 300;
			line-height: 135%;
			margin-bottom: vm(10);
			text-decoration: none;
			position: relative;
			&:last-child{
				margin-bottom: 0;
			}
			&::after{
				position: absolute;
				top: vm(6);
				right: vm(2);
				content: '';
				display: block;
				width: vm(3);
				height: vm(6);
				background-size: cover;
				background-position: center;
				background-image: url('../img/icons/link-mob-arrow.svg');
			}
		}
		.header-links{
			position: relative;
			&::after{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				content: '';
				display: block;
				width: 100vw;
				height: vm(1);
				background: rgba(0, 0, 0, 0.05);
				bottom: 0;
			}
		}
		.footer-address{
			position: relative;
			&::after{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				content: '';
				display: block;
				width: 100vw;
				height: vm(1);
				background: rgba(0, 0, 0, 0.05);
				bottom: 0;
			}
		}
	}
	.modal-menu.active{
		transform: translateY(0);
	}
	.modal-menu{
		overflow-y: auto;
		padding-bottom: vm(25);
	}
	.header__phone-mob{
		transition: .4s all ease;
	}
	.header{
		width: vm(365);
		margin-top: vm(5);
		left: 50%;
		transform: translateX(-50%);
		padding-top: vm(15);
		padding-bottom: vm(15);
		border-radius: vm(10);
		transition: .4s all ease;
	}
	.header.fixed{
		position: fixed;
		background: #FFF;
		box-shadow: 0px vm(251) vm(70) 0px rgba(71, 70, 78, 0.00), 0px vm(161) vm(64) 0px rgba(71, 70, 78, 0.01), 0px vm(90) vm(54) 0px rgba(71, 70, 78, 0.03), 0px vm(40) vm(40) 0px rgba(71, 70, 78, 0.04), 0px vm(10) vm(22) 0px rgba(71, 70, 78, 0.05);
		.header__phone-mob{
			background: #F3F4F5;
		}
	}


	.form-input{
		border: vm(1) solid #F6F7F8;
		transition: .4s all ease;
	}


	.blick{
	    background-image: linear-gradient(-45deg, transparent 0%, transparent 49%, #fff 50%, transparent 70%) !important;
	    background-repeat: no-repeat !important;
	    -webkit-animation: blick-slide infinite 3s;
	    animation: blick-slide infinite 3s;
	    .spn-txt{
	    	position: relative;
	    	z-index: 2;
	    }
	}
	@keyframes blick-slide{
		0% {
		    background-position-x: -70vw;
		}
		100% {
		    background-position-x: calc(100% + 70vw);
		}
	}
	.form-btn{
		-webkit-animation: blick-slide-btn infinite 4s;
		animation: blick-slide-btn infinite 4s;
	}

	@keyframes blick-slide-btn{
		0% {
		    background-position-x: -80vw;
		}
		100% {
		    background-position-x: calc(100% + 80vw);
		}
	}

	.thanks{
		margin-bottom: vm(50);
	}
	.thanks .content{
		padding-top: vm(90);
		padding-bottom: 0;
		height: vm(720);
		border-radius: 0 0 vm(20) vm(20);
		background-image: url('../img/bg/thanks-mob.jpg')!important;
	}
	.thanks__title{
		font-size: vm(30);
		line-height: 120%; /* 36px */
		letter-spacing: vm(-0.9);
		margin-bottom: vm(30);
	}
	.thanks-text::after{
		width: vm(59);
		height: vm(55);
		right: 0;
		top: vm(-13);
	}
	.thanks-text{
		font-size: vm(14);
		line-height: 125%;
		max-width: vm(280);
		p{
			margin-bottom: vm(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	.deeper--thanks .section-title{
		font-size: vm(22);
		letter-spacing: vm(-0.66);
		margin-bottom: vm(30);
	}
	.deeper--thanks{
		padding-bottom: 0;
	}
	.webinar--section{
		padding-bottom: 0;
	}
	.webinar--section .webinar__pretitle{
		font-size: vm(14);
		margin-bottom: vm(15);
	}
	.webinar--section .modal-exit-list{
		font-size: vm(14);
	}

	.webinar--section .exit-photo{
		width: vm(330);
		height: vm(200);
		top: 46vw;
	}
	.header--thanks{
		position: absolute!important;
		background: transparent!important;
	}
	[data-aos^=fade][data-aos^=fade]{
		transition-delay: .1s!important;
	}


	.first-line{
		position: relative;
		right: vw(75);
		border-radius: 0;
	}

	.selectize-dropdown-content::-webkit-scrollbar {
	  width: vm(5); /* Ширина всего элемента навигации */
	}
}