@function vw($px) {
	@return $px/(1440 / 100vw);
}
.fancybox__container{
	z-index: 9999999999999999;
}
.container{
	margin-right: auto;
	margin-left: auto;
	max-width: vw(1160);
	width: 100%;
}
.row{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.flex-a{
	display: flex;
	align-items: center;
}
.flex-t{
	display: flex;
	align-items: flex-start;
}
.flex-b{
	display: flex;
	align-items: flex-end;
}
.flex-c{
	display: flex;
	align-items: center;
	justify-content: center;
}
.photo-bg{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.cicle{
	border-radius: 50%;
}
.title{
	font-family: 'Trajan Pro 3';
	span{
		color: #9B7F62;
	}
}
.troubles-arrows{
	pointer-events: none;
}
.troubles-arrow{
	pointer-events: all;
}
.first-screen{
	
	.content{
		padding-top: vw(145);
		padding-bottom: vw(53);
		border-radius: vw(20);
	}
}

.section-padding{
	padding: vw(10);
	.content{
		border-radius: vw(20);
	}
}

.first-screen{

}
.first-screen__title{
	margin-bottom: vw(20);

	font-size: vw(40);
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 48px */
	letter-spacing: vw(-1.2);
}
.first-screen-term{
	border-radius: vw(6);
	background: #FFF;
	display: flex;
	align-items: center;
	color: #3E4953;
	padding: vw(10);
	font-family: "Wix Madefor Display";
	font-size: vw(11);
	font-style: normal;
	font-weight: 800;
	line-height: 125%; /* 13.75px */
	letter-spacing: vw(0.55);
	text-transform: uppercase;
	img{
		margin-right: vw(10);
	}
	margin-right: vw(10);
	display: inline-flex;
}
.first-screen-count{
	margin-top: vw(50);
	.wrapper{
		display: flex;
		align-items: center;
		border-radius: vw(10);
		background: #FFF;
		backdrop-filter: blur(vw(22.5));
		justify-content: center;
		width: vw(373);
		height:vw(50);
	}
	span{
		color: rgba(155, 127, 98, 0.25);
		margin-right: vw(10);
		margin-left: vw(10);
		font-size: vw(11);
		font-weight: 700;
		line-height: 125%;
		letter-spacing: vw(0.55);
		text-transform: uppercase;
	}
}
.first-screen-count__title{
	color: #3E4953;
	margin-right: vw(20);
	font-size: vw(20);
	font-weight: 400;
	line-height: 125%;
}
.reviews-slider-wrapper{
	display: flex;
	align-items: center;
	width: vw(1200);
	max-width: vw(1200);
	margin-right: vw(-20);
	margin-left: vw(-20);
	border-radius: vw(15);
	background: #FFF;
	padding-top: vw(22);
	padding-bottom: vw(20);
	margin-top: vw(20);
	padding-left: vw(20);
	position: relative;
}
.reviews-slide{
	width: 100%;
	margin-right: vw(5);
	margin-left: vw(5);
	.photo-bg{
		width: 100%;
		height: vw(100);
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: grey;
		border-radius: vw(10);
		margin-bottom: vw(15);
	}
	.reviews-slide__text{
		color: #3E4953;
		font-family: Roboto;
		font-size: vw(15);
		font-weight: 300;
		line-height: 125%;

		display: -webkit-box;
	    -webkit-line-clamp: 2;
	    -webkit-box-orient: vertical;
	}
}
.reviews-slider{
	width: 100%;
	max-width: vw(960);
	margin-right: vw(-5);
	margin-left: vw(-5);
}
.reviews-slide__play{
	border-radius:vw(50);
	background: #FFF;
	width:vw(60);
	height:vw(60);
	display: flex;
	align-items: center;
	justify-content: center;
}
.reviews-slider__arrow{
	display: flex;
	align-items: center;
	justify-content: center;
	background: #9B7F62;
	box-shadow: 0 vw(4.186) vw(36.628) 0 rgba(255, 255, 255, 0.25) inset;
	width: vw(225);
	height: vw(225);
	border-radius: 50%;
	top: vw(-15);
	right: vw(-20);
	position: absolute;
	color: #FFFAF5;
	text-align: center;
	font-family: Roboto;
	font-size: vw(20.93);
	font-weight: 600;
	line-height: vw(26.163); /* 125% */
	letter-spacing: vw(-0.628);
	span{
		font-weight: 500;
	}
	img{
		margin-right: vw(4);
		margin-left: vw(4);
	}
	transition: .4s all ease;
	&:hover{
		box-shadow: none;
		color: #FFFAF5;
	}
}
.first-line{
	
	background: #FFF;
	backdrop-filter: blur(vw(17.5));
	padding-top: vw(10);
	padding-bottom: 0;
	height: vw(43);
	overflow: hidden;
	.slide{
		color: #3E4953;
		font-family: Roboto;
		font-size: vw(18);
		font-weight: 400;
		line-height: 125%;
		letter-spacing: vw(-0.54);
		span{
			color: #9B7F62;
		}
	}
}
.first-line-wrapper{
	position: relative;
	margin-top: vw(51);
}
.first-line__btn{
	border-radius: vw(10);
	background: #3E4953;
	width: vw(203);
	height:vw(60);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: vw(112);

	color: #F8FBFF;
	text-align: center;
	font-family: Roboto;
	font-size: vw(18);
	font-weight: 600;
	line-height: vw(25); /* 138.889% */
	letter-spacing: vw(-0.54);
	&:hover{
		color: #F8FBFF;
	}
}
.hello{
	.container{
		padding-left: vw(560);
	}
	.content{
		border-radius: vw(20);
		padding-top: vw(120);
		padding-bottom: vw(90);
	}
}
.hello__title{
	color: #F6F7F8;
	font-size:vw(50);
	font-weight: 600;
	line-height:vw(50);
	letter-spacing: vw(-1.5);
	margin-bottom:vw(50);
}
.hello-text{
	color: #F6F7F8;
	font-size: vw(20);
	font-weight: 400;
	line-height: 125%;
	margin-bottom: vw(25);
	p{
		margin-bottom: vw(25);
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.hello-wrapper{
	margin-left: vw(100);
}
.hello__fio{
	color: rgba(246, 247, 248, 0.65);
	font-size: vw(16);
	font-weight: 400;
	line-height: 125%;
	strong{
		color: #F6F7F8;
		font-weight: 700;
	}
}
.hello-wrapper{
	position: relative;
}
.hello__quote{
	position: absolute;
	top: 0;
	left: vw(-134);
}
.hello__signature{
	position: absolute;
	bottom: vw(-20);
	left: vw(187);
}
.first-screen{
	padding-bottom: 0;
}
.section-title{
	color: #444;
	-webkit-text-stroke-width: 1;
	-webkit-text-stroke-color: rgba(155, 127, 98, 0.55);
	font-family: "Trajan Pro 3";
	font-size: vw(45);
	font-weight: 400;
	line-height: 120%; /* 54px */
	letter-spacing: vw(-1.35);
}
.portfolio{
	padding-top: vw(110);
	.section-title{
		margin-bottom: vw(20);
	}
}
.sum{
	padding-left: vw(20);
	padding-right: vw(20);
	.content{
		border-radius: vw(25);
		padding-top: vw(105);
		padding-bottom: vw(116);
		padding-left: vw(682);
	}
	.section-title{
		margin-bottom: vw(30);
	}
}
.section-title{
	span{
		color: #9B7F62;
	}
}
.sum__text{
	color: #3E4953;
	max-width: vw(500);
	font-family: Roboto;
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	p{
		margin-bottom: vw(25);
		&:last-child{
			margin-bottom: 0;
		}
	} 
}
.big-block{
	padding-right: vw(20);
	padding-left: vw(20);
	.content{
		border-radius: vw(25);
	}
}
.section-title--center{
	text-align: center;
}
.big-block{
	.content{
		padding-top: vw(90);
		padding-bottom: vw(90);
	}
}
.care{
	margin-top: vw(20);
	
	.content{
		background: #3C464F;
	}
	.section-title{
		font-size: vw(55);
		line-height: 120%; /* 66px */
		letter-spacing: vw(-1.65);
		margin-bottom: vw(25);
	}
	.arrow-left{
		transform: scale(-1, 1);
	}
}
.white{
	color: #FFF;
	.section-title{
		color: #FFF;
	}
}
.care-subtitle{
	margin-right: auto;
	margin-left: auto;
	max-width: vw(567);
	text-align: center;
	font-size: vw(18);
	font-style: normal;
	font-weight: 400;
	line-height: 125%;
}
.care-subtitle__bottom{
	display: flex;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	justify-content: center;
	margin-top: vw(25);
	font-weight: 500;
	img{
		margin-right: vw(20);
	}
	.arrow-left{
		margin-right: 0;
		margin-left: vw(15);
	}
}
.care-block{
	width: 100%;
	border-radius: vw(25);
	background: #628D9B;
	box-shadow: 0 vw(4) vw(40) 0 rgba(255, 255, 255, 0.25) inset;
	max-width: vw(374);
	padding-bottom: vw(19);
	padding-top:vw(50);
	padding-left: vw(20);
	padding-right: vw(20);
	.wrapper{
		padding-left: vw(20);
	}
}
.care-block__title{
	color: #FFF;
	font-family: Roboto;
	font-size: vw(20);
	font-weight: 700;
	line-height: 125%;
	margin-bottom: vw(20);
}
.care-block__text{
	color: rgba(255, 255, 255, 0.75);
	font-family: Roboto;
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	min-height: vw(160);
	margin-bottom: vw(20);
	p{
		margin-bottom: vw(25);
		&:last-child{
			margin-bottom: 0;
		}
	}
	
}
.care-block__photo{
	width: 100%;
	border-radius: vw(15);
	background-color: grey;
	height: vw(200);
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding-right: vw(15);
	padding-bottom: vw(15);
}
.care-row{
	margin-top: vw(65);
}
.care-block__loop{
	border-radius: vw(10);
	background: rgba(98, 141, 155, 0.20);
	width: vw(45);
	height: vw(45);
	display: flex;
	align-items: center;
	justify-content: center;
}
.care-block--gray{
	background: #9B7F62;
	box-shadow: 0 vw(4) vw(40) 0 rgba(255, 255, 255, 0.25) inset;
	.care-block__loop{
		background: rgba(155, 127, 98, 0.20);
	}
}
.questions{
	padding-top: vw(110);
	padding-bottom: vw(110);
}
.questions-row{
	margin-top: vw(40);
	margin-bottom: vw(-10);
	align-items: flex-start;
}
.questions-block{
	border-radius: vw(10);
	background: #FFF;
	padding-left: vw(40);
	padding-right: vw(40);
	width: 100%;
	max-width: vw(575);
	cursor: pointer;
	margin-bottom: vw(10);
	.top{
		display: flex;
		align-items: center;
		
		justify-content: space-between;
		padding-top: vw(35);
		padding-bottom: vw(35);
		transition: .4s all ease;

	}
	.hidden{
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

	}
	.content{
		padding-bottom: vw(40);
		padding-top: vw(20);
		border-top: vw(1) solid rgba(0, 0, 0, 0.05);
		

		color: rgba(62, 73, 83, 0.75);
		font-size: vw(16);
		font-weight: 400;
		line-height: 125%;
		p{
			min-height: vw(80);
		}
		a{
			color: #9B7F62;
			text-decoration-line: underline;
			text-decoration-skip-ink: none;
		}
	}
}
.questions-block__title{
	color: #3E4953;
	font-size: vw(18);
	font-weight: 500;
	line-height: 120%; 
	letter-spacing: vw(-0.54);
}
.questions-block__arrow{
	transition: .4s all ease;
}
.questions-block.opened{
	.top{
		padding-bottom: vw(20);
	}
	.hidden{
		max-height: vw(2000);
		transition: max-height 1s ease-in-out;
	}
	.questions-block__arrow{
		transform: scale(1, -1);
	}
}
.deeper-block{
	max-width: vw(374);
	width: 100%;
	padding-left: vw(30);
	padding-top: vw(30);
	height: vw(250);
	position: relative;
	border-radius: vw(25);
}
.deeper{
	padding-bottom: vw(110);
	.section-title{
		margin-bottom:vw(50);
	}
}
.deeper-block__play{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: vw(70);
	height: vw(70);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: #fff;
}
.deeper-block__title{
	color: #FFF;
	font-family: "Gotham Pro";
	font-size:vw(14);
	font-weight: 700;
	line-height: 120%;
	letter-spacing: vw(0.7);
	text-transform: uppercase;
	margin-bottom: vw(15.5);
	strong{
		color: #E3CDB6;
	}
}
.lose{
	margin-bottom:vw(50);
	.content{
		border-radius: vw(20);
		box-shadow: 0 vw(4) vw(70) 0 rgba(255, 255, 255, 0.20) inset;
		padding-top: vw(80);
		padding-bottom:vw(50);
	}
}
.section-title--big{
	font-size: vw(55);
	font-weight: 400;
	line-height: 110%;
	letter-spacing: vw(-1.65);
}
.lose__subtitle{
	margin-top: vw(20);
	margin-bottom: vw(44);
	margin-bottom: vw(44);
	color: rgba(255, 255, 255, 0.80);
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	letter-spacing: vw(-0.54);
	strong{
		font-weight: 500;
	}
}
.section-form{
	border-radius: vw(20);
	background: #FFF;
	padding: vw(15);
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.form-btn{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: vw(15);
	background: #2A3138;
	box-shadow: 0 vw(4) vw(15) 0 rgba(255, 255, 255, 0.15) inset;
	width: vw(360);
	height: vw(85);
	color: #FFF;
	border: none;
	cursor: pointer;
	color: #FFF;

	text-align: center;
	font-size: vw(18);
	font-weight: 700;
	line-height: vw(20);
}
.form-input{
	border-radius: vw(15);
	background: #F6F7F8;
	width: vw(265);
	height: vw(85);
	padding-left: vw(56);
	border: none;


	font-family: Roboto;
	color: rgba(62, 73, 83, 1);
	font-size: vw(17);
	font-weight: 400;
	line-height: vw(22);
	&::placeholder{
		font-family: Roboto;
		color: rgba(62, 73, 83, 0.55);
	}
}
.form-label{
	position: relative;
}
.form-label__img{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: vw(30);
}
.selectize-input{
	border-radius: vw(15);
	background: #F6F7F8;
	width: vw(480);
	height: vw(85);
	border: none!important;
	box-shadow: none!important;
	border-radius: vw(15);
	background: #F6F7F8!important;
	padding-left: vw(40.5);

	color: rgba(62, 73, 83, 0.55);
	transition: .4s all ease;
	font-family: Roboto;
	font-size: vw(17);
	font-style: normal;
	font-weight: 400;
	line-height: vw(22); /* 129.412% */
	letter-spacing: vw(-0.51);
	display: flex!important;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
}
.section-form__polcy{
	margin-top: vw(12);
	color: rgba(255, 255, 255, 0.55);
	font-size: vw(15);
	font-weight: 400;
	line-height: 125%;
	letter-spacing: vw(-0.45);
	a{
		color: #FEEBD6;
		text-decoration: underline;
		text-decoration-skip-ink: none;
	}
}

.selectize-control.single .selectize-input:after{
	width: vw(12);
	height: vw(7);
	background-image: url('../img/icons/select-arrow.svg');
	padding: vw(0);
	border: none;
	background-size: cover;
	right: vw(30);
	transition: .4s all ease;
}
.selectize-control.single .selectize-input.dropdown-active:after{
	margin-top: vw(-3);
	transform: scale(1, -1);
}
.selectize-dropdown .create, .selectize-dropdown .no-results, .selectize-dropdown .optgroup-header, .selectize-dropdown .option{
	padding-top: vw(13);
	padding-bottom: vw(13);
	padding-left: vw(40.5);
	background: #F6F7F8;
	color: rgba(62, 73, 83, 0.55);
	transition: .4s all ease;
	font-family: Roboto;
	font-size: vw(17);
	font-style: normal;
	font-weight: 400;
	line-height: vw(22); /* 129.412% */
	letter-spacing: vw(-0.51);
}
.selectize-dropdown{
	border: none;
}
.lose{
	.container{
		position: relative;
	}
}
.lose__fio{
	position: absolute;
	color: rgba(246, 247, 248, 0.65);
	font-size: vw(16);
	font-weight: 400;
	line-height: 125%;
	right: vw(49);
	bottom: vw(210);
	strong{
		color: #F6F7F8;
		font-weight: 700;
	}
}
.price{
	margin-bottom: vw(145);
	margin-top: vw(146);
	.content{
		padding-top: vw(80);
		padding-bottom:vw(50);
		
	}
	.section-title{

	}
	.container{
		position: relative;
	}
	.form-btn{
		background: #9B7F62;
		box-shadow: 0 vw(4) vw(15) 0 rgba(255, 255, 255, 0.35) inset;
	}
	.section-form__polcy{
		color: rgba(255, 255, 255, 0.55);
		a{
			color: #1A96DD;
		}
	}
}
.price-name{
	display: flex;
	align-items: center;
	margin-top: vw(20);
	.cicle{
		width:vw(50);
		height:vw(50);
		border-radius: 50%;
		margin-right: vw(20);
	}
	margin-bottom: vw(63);
	color: rgba(255, 255, 255, 0.80);
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	letter-spacing: vw(-0.54);
}
.price-fio__text{
	color: rgba(255, 255, 255, 0.65);
	font-size: vw(16);
	font-weight: 400;
	line-height: 125%;
	position: relative;
	&::after{
		width: vw(10);
		height: vw(10);
		content: '';
		display: block;
		background-size: cover;
		background-image: url('../img/price/line.svg');
		position: absolute;
		left: vw(0);
		top: vw(-25);
	}
	strong{
		color: #FFF;
		font-weight: 500;
	}
}
.price-fio-abc{
	position: absolute;
	right: vw(138);
	bottom:vw(249);
}
.block-small{
	padding-right:vw(50);
	padding-left:vw(50);
	.content{
		border-radius: vw(20);
	}
}
.latent-happening{
	display: flex;
	align-items: center;
}
.latent{
	padding-bottom: vw(10);
	margin-bottom: vw(10);
	.content{
		padding-top: vw(70);
		padding-bottom: vw(70);
	}
	.section-title{
		color: #F5F5F5;
		span{
			color: #E3CDB5;
		}
	}
}

.latent__text{
	max-width: vw(472);
	font-size: vw(18);
	margin-top: vw(20);
	font-weight: 300;
	line-height: 125%;
	p{
		margin-bottom: vw(25);
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.latent-happening{
	display: flex;
	align-items: center;
	border-radius: vw(6);
	background: rgba(255, 255, 255, 0.05);
	width: vw(187);
	height: vw(32);
	justify-content: center;
	margin-bottom: vw(10);
	img{
		margin-right: vw(10);
	}

	color: #FFF;

	font-family: "Gotham Pro";
	font-size: vw(11);
	font-weight: 700;
	line-height: 125%;
	letter-spacing: vw(0.55);
	text-transform: uppercase;
	span{
		position: relative;
		top: vw(1);
	}
}
.latent{
	.container{
		position: relative;
	}
}
.latent__zoom{
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: vw(45);
	height: vw(45);
	background: #FFF;
	bottom: 0;
	 right: vw(116);
}

.sheepskin{
	.content{
		padding-bottom:vw(50);
		padding-top: vw(80);
	}
}
.sheepskin__text{
	color: rgba(255, 255, 255, 0.80);
	margin-top: vw(20);
	margin-bottom: vw(45);
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%; /* 22.5px */
	letter-spacing: vw(-0.54);
}
.latent{
	margin-bottom: 0;
}
.sheepskin{
	.container{
		position: relative;

	}
	.price-fio__text::after{
		background-image: url('../img/icons/line-white.svg');
	}
	.price-fio-abc{
		right: vw(90);
		bottom: vw(229);
	}
}
.sheepskin{
	margin-bottom:vw(50);
	.content{
		position: relative;
		&::after{
			position: absolute;
			width: vw(24);
			height: vw(28);
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/polygon-2.svg');
			content: '';
			display: block;
			z-index: 100;
			top: vw(65);
			right: vw(-12);
		}
	}
}
.latent{
	.content{
		position: relative;
		&::after{
			position: absolute;
			width: vw(24);
			height: vw(28);
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/polygon-1.svg');
			content: '';
			display: block;
			z-index: 100;
			top: vw(65);
			left: vw(-12);
		}
	}
}
.seems{
	padding-bottom: vw(90);
}
.seems-row{
	margin-top: vw(40);
}
.seems-line{
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	margin-bottom: vw(15);
	width: 100%;
	&:last-child{
		margin-bottom: 0;
	}
}
.seems-name{
	border-radius: vw(10);
	background: #628D9B;
	box-shadow: 0 vw(4) vw(25) 0 rgba(255, 255, 255, 0.25) inset;
	width: 100%;
	max-width: vw(400);
	padding-left: vw(30);
	padding-top: vw(25);
	padding-bottom: vw(25);

	color: #FFF;
	position: relative;
	font-size: vw(20);
	font-weight: 500;
	line-height: 125%;
	&::after{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: vw(15);
		height: vw(15);
		background-size: cover;
		background-position: center;
		background-image: url('../img/icons/seems-arrow.svg');
		content: '';
		display: block;
		right: vw(-60);
	}
}
.seems-content{
	border-radius: vw(10);
	background: #FFF;
	width: 100%;
	padding-left: vw(40);
	display: flex;
	align-items: center;
	max-width: vw(655);

	color: #444;
	font-size: vw(18);
	font-weight: 300;
	line-height: 125%;
}
.every-slider{
	margin-top:vw(60);
	margin-bottom: vw(65);
	margin-left: vw(-10);
	margin-right: vw(-700);
}
.every-slide{
	width: vw(374);
	margin-right: vw(10);
	margin-left: vw(10);
	.photo-bg{
		height: vw(200);
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: grey;
		border-radius: vw(15);
	}
}
.every-slide__text{
	color: #3E4953;
	margin-top: vw(15);
	font-size: vw(18);
	font-weight: 300;
	line-height: 125%;
}
.every-slide__link{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: vw(90);
	height: vw(90);
	background: #FFFFFF;
}
.messag-cicle{
	width: vw(210);
	height: vw(210);
	flex-shrink: 0;
}
.messag-content{
	max-width: 100%;
	max-width: vw(900);
	padding-top: vw(34);
	padding-left: vw(35);
	padding-right: vw(30);
	border-radius: vw(20);
	background: #9B7F62;
	min-height: vw(190);
}
.every-messag{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.every{
	margin-bottom: vw(110);
}
.messag-content__title{
	color: #FFF;
	margin-bottom: vw(15);
	font-size: vw(22);
	font-weight: 600;
	line-height: 125%;
}
.messag-content__text{
	color: #FFF;

	font-size: vw(18);
	font-weight: 300;
	line-height: 125%;
}
.messag-content__direktor{
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: vw(18);

	color: rgba(255, 255, 255, 0.55);

	font-size: vw(16);
	font-style: italic;
	font-weight: 400;
	line-height: 125%;
	strong{
		font-weight: 400;
		color: #FFF;
	}
}
.messag-content{
	position: relative;
	width: 100%;
	&::after{
		position: absolute;
		content: '';
		display: block;
		width: vw(92);
		height: vw(85);
		background-size: cover;
		background-position: center;
		background-image: url('../img/every/every-quote.svg');
		top: vw(25);
		right: vw(25.7);
	}
	&::before{
		position: absolute;
		content: '';
		display: block;
		width: vw(29);
		height: vw(24);
		background-size: cover;
		background-position: center;
		background-image: url('../img/every/every-polygon-1.svg');
		bottom: vw(51);
		right: vw(-26);
	}
}
.every-messag{
	margin-bottom: vw(30);
	&:last-child{
		margin-bottom: 0;
	}
}
.every-messag--blue{
	.messag-content{
		background: #628D9B;
		&::before{
			background-image: url('../img/every/every-polygon-2.svg');
			bottom: vw(51);
			left: vw(-17);
			right: unset;
		}
	}
}
.every-counter{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-image: url('../img/icons/cicle-bg.svg');
	width: vw(300);
	height: vw(300);
	position: relative;
	color: #FFF;

	font-family: "Trajan Pro 3";
	font-size: vw(23.585);
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 28.302px */
	letter-spacing: vw(-0.708);
	strong{
		color: #FFF;
		font-family: "Trajan Pro 3";
		font-size: vw(90);
		font-weight: 400;
		line-height: 120%;
		letter-spacing: vw(-2.7);
		margin-bottom: vw(-21);
		display: inline-block;
	}
	p{
		max-width: vw(181);
	}

}
.every{
	.container{
		position: relative;
	}
}
.every-counter-abc{
	position: absolute;
	right: vw(5.5);
    top: vw(-180);
}
.cooperation{
	margin-top: vw(110);
	position: relative;
	z-index: 5;
	.content{
		padding-top: vw(90);
		padding-bottom: 0;
		border-radius: vw(25);
		background: #9B7F62;
		box-shadow: 0 vw(4) vw(80) 0 rgba(255, 255, 255, 0.25) inset;
	}
}
.cooperation-subtitle{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: vw(25);
	color: #FFF;

	strong{
		font-family: Roboto;
		font-size: vw(20);
		font-style: normal;
		font-weight: 400;
		line-height: 125%;
		margin-right: vw(20);
		margin-left: vw(20);
		span{
			color: rgba(255, 255, 255, 0.55);
		}
	}

	.cooperation-right{
		transform: scale(-1, 1);
	}
}

.cooperation-photo{
	width: vw(1160);
	height: vw(500);
	margin-top: vw(40);
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	border-radius: vw(20);
	bottom: vw(-38);
}
.cooperation{
	.content{
		height: vw(750);
		position: relative;
	}
}
.check{
	margin-top: vw(20);
	.content{
		padding-top: vw(108);
		padding-bottom: vw(77);
		border-radius: vw(20);
	}
}
.cooperation__play{
	width: vw(100);
	height: vw(100);
	background: #F6F7F8;
}
.check{
	.section-title{
		margin-bottom: vw(30);
		span{
			color: #FFE4C8;
		}
	}
	.price-fio-abc{
		right: vw(118);
		bottom: vw(706);
	}
	.price-fio__text{
		&::after{
			background-image: url('../img/icons/line-white.svg');
		}
	}
}
.check-block{
	max-width: vw(380);
	border-radius: vw(15);
	background: #F6F7F8;
	.top{
		position: relative;
		bottom: vw(2);
		display: flex;
		align-items: center;
		padding-left: vw(35);
		justify-content: space-between;
		padding-right: vw(35);
		padding-top: vw(26);
		padding-bottom: vw(26);
		border-radius: vw(15);
		background: #9B7F62;
		box-shadow: 0 vw(4) vw(25) 0 rgba(255, 255, 255, 0.15) inset;
	}
	.bottom{
		color: #3E4953;
		padding-left: vw(30);
		padding-top: vw(28);
		padding-bottom: vw(34);
		padding-right: vw(43);
	}
}
.check-block-list{
	margin-top: vw(10);
}
.check-block__title{
	color: #FFF;
	font-size: vw(18);
	font-weight: 500;
	line-height: 125%;
}
.check-block__procent{
	color: #FFF;
	font-family: "Trajan Pro 3";
	font-size: vw(45);
	font-weight: 400;
	line-height: 100%; /* 54px */
	letter-spacing: vw(-1.35);
	position: relative;
	top: vw(4);
}
.check-block__text{
	color: #3E4953;
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	strong{
		font-weight: 500;
		color: #3E4953;
	}
}
.check-block-list{
	color: #3E4953;
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	li{
		padding-left: vw(21);
		position: relative;
		margin-bottom: 0;
		&::after{
			position: absolute;
			top: vw(7.4);
			width: vw(11);
			height: vw(9);
			background-size: cover;
			content: '';
			display: block;
			background-image: url('../img/icons/check-color-2.svg');
			left: 0;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.check-block{
	margin-bottom: vw(30);
}
.check-line{
	border-radius: vw(25);
	background: #9B7F62;
	box-shadow: 0 vw(4) vw(55) 0 rgba(255, 255, 255, 0.25) inset;
	padding-top: vw(55);
	padding-bottom: vw(55);
	padding-left: vw(448);
	position: relative;
	&::after{
		position: absolute;
		width: vw(92);
		height: vw(85);
		background-size: cover;
		background-position: center;
		content: '';
		display: block;
		background-image: url('../img/icons/check-quote.svg');
		top: vw(80);
		left: vw(321);
	}
}
.check-line__title{
	color: #FFF;
	font-family: "Trajan Pro 3";
	font-size: vw(45);
	font-weight: 400;
	line-height: 120%;
	letter-spacing: vw(-1.35);
	margin-bottom: vw(10);
	strong{
		font-weight: 400;
		color: #FAE0C5;
	}
}
.check-line__text{
	color: #FFF;

	font-family: Roboto;
	font-size: vw(18);
	font-style: normal;
	font-weight: 400;
	line-height: 125%;
}
.check .content{
	position: relative;
}
.court{
	padding-top: vw(110);
}
.court-participation{
	margin-top: vw(30);
	margin-bottom: vw(25);
	border-radius: vw(10);
	background: #628D9B;
	box-shadow: 0 vw(4) vw(25) 0 rgba(255, 255, 255, 0.45) inset;
}
.court-participation__name{
	color: #FFF;
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
}
.court-participation__price{
	color: #FFF;
	font-family: "Trajan Pro 3";
	font-size: vw(35);
	font-weight: 400;
	line-height: 100%; /* 42px */
	letter-spacing: vw(-1.05);
}
.court-participation{
	padding-left: vw(25);
	max-width: vw(300);
	padding-top: vw(18);
	padding-bottom: vw(18);
}
.court-participation__name{
	margin-bottom: vw(8);
}
.court_subtext{
	color: #3E4953;
	margin-top: vw(20);
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	strong{
		font-weight: 700;
	}
}
.court-list{
	color: #3E4953;
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	li{
		padding-left: vw(26);
		position: relative;
		&::after{
			position: absolute;
			width: vw(11);
			height: vw(9);
			content: '';
			display: block;
			background-size: cover;
			background-image: url('../img/icons/check-blue.svg');
			left: vw(0);
			top: vw(6.6);
		}
	}
}
.court-participation{
	position: relative;
	&::after{
		position: absolute;
		width: vw(11);
		height: vw(15);
		background-size: cover;
		background-position: center;
		content: '';
		display: block;
		background-image: url('../img/icons/arrow-bottom.svg');
		bottom: vw(25);
		right: vw(25);
	}
}
.court{

	.content{
		position: relative;
		&::after{
			position: absolute;
			width: vw(291);
			height: vw(82);
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/court-arrow.svg');
			content: '';
			display: block;
			left: vw(217);
			bottom: vw(10.71);
		}
	}
	.container{
		padding-bottom: vw(80);
		border-bottom: vw(1) solid rgba(0, 0, 0, 0.02);
	}
}
.court-photo{
	width: vw(600);
	height: vw(380);
	display: flex;
	align-items: center;
	justify-content: center;
}
.court-photo__play{
	display: flex;
	align-items: center;
	justify-content: center;
	width: vw(90);
	height: vw(90);
	border-radius: 50%;
	background: #FFFFFF;
}
.troubles-slide{
	display: flex!important;
	align-items: center;

}
.troubles-photo{
	width: vw(500);
	height: vw(528);
	flex-shrink: 0;
	border-radius: vw(20);
	margin-right:vw(60);
}
.troubles-btn{
	color: #628D9B;
	font-family: "Gotham Pro";
	font-size: vw(13);
	font-weight: 700;
	line-height: 125%;
	letter-spacing: vw(0.65);
	text-transform: uppercase;

	border-radius: vw(10);
	background: #FFF;
	padding: vw(16) vw(20);
	padding-bottom:vw(14);
	margin-right: vw(10);
	display: inline-block;
}
.troubles-btns{
	margin-top: vw(20);
	margin-bottom:vw(50);
}
.troubles-text{
	color: #3E4953;
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	max-width: vw(540);
	p{
		margin-bottom: vw(25);
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.troubles-slide{
	align-items: flex-start;
}
.troubles-btn.active{
	color: #FFF;
	background: #628D9B;
	box-shadow: 0 vw(4) vw(15) 0 rgba(255, 255, 255, 0.35) inset;
}
.troubles-arrows{
	display: flex;
	align-items: center;
}
.troubles-arrow{
	width: vw(70);
	height: vw(70);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: .4s all ease;
	svg path{
		transition: .4s all ease;
	}
	background: #628D9B;
	box-shadow: 0 vw(4) vw(15) 0 rgba(255, 255, 255, 0.35) inset;
}

.troubles-arrow.slick-disabled{
	background: #fff;
	svg path{
		fill: #628D9B;
	}
}

.troubles-next{
	transform: scale(-1, 1);
	margin-left: vw(20);
}
.troubles-arrows{
	position: absolute;
	right: vw(440);
	bottom: 0;
}
.troubles-slider-wrapper{
	position: relative;
}
.portfolio__subtitle{
	display: flex;
	align-items: center;
	img{
		margin-right:vw(14);
	}

	color: #3E4953;
	font-size: vw(20);
	font-weight: 400;
	line-height: 125%;
}
.portfolio-slider-wrapper{
	margin-top:vw(50);
}
.portfolio-block{
	border-radius: vw(15);
	background: #FFF;
	max-width: vw(279);
	padding-left: vw(35);
	padding-top: vw(30);
	height: vw(291);
	width: 100%;
	margin-bottom: vw(10);
	position: relative;
}
.portfolio-slider{
	max-width: 100%;
}
.portfolio-slide{
	display: flex!important;
	justify-content: space-between;
	flex-wrap: wrap;
	max-width: 100%;
}
.portfolio-slider-wrapper{
	margin-bottom: vw(110);
}
.portfolio-block__pretitle{
	color: rgba(62, 65, 80, 0.75);
	font-size:vw(14);
	font-weight: 400;
	line-height: 125%;
	margin-bottom: vw(10);
}
.portfolio-block__price{
	color: #3E4150;
	margin-bottom: vw(35);
	font-size: vw(25);
	font-weight: 700;
	line-height: 125%;
}
.portfolio-block__text{
	color: rgba(62, 65, 80, 0.45);
	font-size: vw(15);
	font-weight: 400;
	line-height: 125%;
	strong{
		color: #3E4150;
		font-weight: 500;
	}
	p{
		margin-bottom: vw(20);
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.portfolio-block__zoom{
	width: vw(45);
	height: vw(45);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	border-radius: vw(10);
	background: rgba(98, 141, 155, 0.20);
	bottom: vw(20);
	right: vw(20);
}
.portfolio-logos{
	color: rgba(155, 127, 98, 0.45);
	font-family: "Gotham Pro";
	font-size: vw(11);
	font-weight: 700;
	line-height: 125%;
	letter-spacing: vw(0.55);
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height:vw(50);
	width: vw(374);
	border-radius: vw(10);
	background: rgba(155, 127, 98, 0.10);
	backdrop-filter: blur(vw(22.5));
	position: relative;
	bottom: vw(4);
	span{
		margin-right: vw(10);
		margin-left: vw(10);
	}
}

.slick-dots li button{
	display: none;
}
.slick-dots li{
	width: vw(10);
	height: vw(10);
	border-radius: vw(10);
	background: rgba(155, 127, 98, 0.10);
	transition: .4s all ease;
	margin-right: vw(10);
	margin-left: vw(10);
}
.slick-dots{
	width: auto;
	margin-left: vw(-10);
	bottom: vw(-36);
}
.slick-dots li.slick-active{
	background: #9B7F62;
}
.portfolio-slider-wrapper{
	position: relative;
	.troubles-arrows{
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: vw(1340);
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
.portfolio-next{
	transform: scale(-1, 1);
}
.header-logo{
	max-width: vw(201.6);
	margin-right: vw(118);
}
.header{
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	padding-top: vw(40);
	padding-left:vw(50);
	padding-right:vw(50);
}
.header-link{
	color: rgba(62, 73, 83, 0.75);
	font-family: Roboto;
	font-size:vw(14);
	font-weight: 300;
	line-height: 135%; /* 18.9px */
	text-decoration-line: underline;
	max-width: vw(157);
	text-decoration-skip-ink: none;
	display: inline-block;
	margin-right: vw(43);
	&:last-child{
		margin-right: 0;
	}
}
.header-number{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.header-number__phone{
	color: #3E4953;
	font-family: "Trajan Pro 3";
	font-size: vw(24);
	font-weight: 400;
	line-height: 125%;
}
.header-number{
	margin-right:vw(50);
	&:last-child{
		margin-right: 0;
	}
}
.header-number__name{
	color: rgba(62, 73, 83, 0.75);
	font-size:vw(14);
	font-weight: 200;
	line-height: 125%;
}
.header-soc__item{
	display: flex;
	align-items: center;
	justify-content: center;
	width: vw(55);
	height: vw(55);
	margin-right: vw(10);
	border-radius: vw(9.167);
	box-shadow: 0 vw(4) vw(10) 0 rgba(255, 255, 255, 0.25) inset;
	&:last-child{
		margin-right: 0;
	}
}
.soc-wc{
	background: #65BC63;
}
.soc-tg{
	background: #68B6C1;
}
.header-soc{
	margin-left: vw(-30);
}
.footer{
	padding-bottom: vw(41);
	.container{
		max-width: vw(1340);
	}
	.header-logo{
		max-width: vw(273);
		position: relative;
		bottom: vw(17);
		margin-right: vw(77);
	}
	.header-row{
		align-items: flex-start;
	}
}
.footer-bottom{
	padding-top: vw(35);
	margin-top: vw(35);
	border-top: vw(1) solid rgba(0, 0, 0, 0.05);
}
.footer__legal{
	color: #D0D0D0;
	font-family: Roboto;
	font-size: vw(16);
	font-weight: 400;
	line-height: vw(22);
	text-align: right;
}
.footer__copy{
	color: #D0D0D0;
	font-size: vw(16);
	font-weight: 400;
	line-height: vw(22);
	a{
		color: #9B7F62;
		text-decoration-line: underline;
	}
}
.footer-address__name{
	color: #3E4953;
	margin-bottom: vw(10);
	font-family: "Trajan Pro 3";
	font-size: vw(20);
	font-weight: 400;
	line-height: vw(22);
}
.footer-address__adress{
	color: #9B7F62;
	font-size: vw(16);
	font-weight: 400;
	line-height: vw(22); /* 137.5% */
}
.footer-address{
	position: relative;
	top: vw(2);
}
.modal-window{
	position: fixed;
	right: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000000;
	background: rgba(0,0,0, 0.2);
	display: flex;
	justify-content: center;
	overflow-y: scroll;
	padding-top: vw(45);
	padding-bottom: vw(45);
	transition: .4s all ease;
	opacity: 0;
	visibility: hidden;
	.inner{
		margin-top: auto;
		margin-bottom: auto;
		height: max-content;
		position: relative;
	}
}
.modal-window.active{
	opacity: 1;
	visibility: visible;
}
.modal-window__close{
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: vw(45);
	height: vw(45);
	right: vw(35);
	top: vw(35);
	border-radius: vw(8);
	border: vw(0.45) solid #628D9B;
	background: #F6F7F8;
	box-shadow: 0 vw(4) vw(10) 0 rgba(255, 255, 255, 0.25) inset;
}
.modal-exit{
	.inner{
		border-radius: vw(20);
		background: #628D9B;
		box-shadow: 0 vw(4) vw(65) 0 rgba(255, 255, 255, 0.25) inset;
		padding:vw(60);
		max-width: vw(1160);
		width: 100%;
	}
}
.modal-exit__title{
	color: #FFF;
	font-family: "Trajan Pro 3";
	font-size: vw(35);
	font-weight: 400;
	line-height: 120%; /* 42px */
	letter-spacing: vw(-1.05);
	margin-bottom: vw(21);
}
.exit-photo{
	width: vw(460);
	height: vw(377);
	border-radius: vw(15);
	background-size: 101%;
	margin-right: vw(68);
}
.exit-row{
	justify-content: flex-start;
}
.modal-exit__name{
	color: #FFF;

	font-family: Roboto;
	font-size: vw(18);
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: vw(20);
}
.modal-exit-list{
	color: rgba(255, 255, 255, 0.75);
	font-size: vw(16);
	font-weight: 400;
	max-width: vw(512);
	li{
		margin-bottom: vw(15);
		position: relative;
		padding-left: vw(26);
		&::after{
			width: vw(11);
			height: vw(9);
			background-size: cover;
			background-position: center;
			content: '';
			display: block;
			background-position: center;
			background-image: url('../img/icons/check-white.svg');
			position: absolute;
			top: vw(4);
			left: 0;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.modal-exit__btn{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: vw(15);
	background: #9B7F62;
	box-shadow: 0 vw(4) vw(35) 0 rgba(255, 255, 255, 0.25) inset;
	width: vw(360);
	height: vw(85);
	margin-top: vw(20);

	color: #FFF;

	text-align: center;
	font-size: vw(18);
	font-weight: 700;
	line-height: vw(20); 
	img{
		margin-left: vw(10);
	}
}
.exit-photo__play{
	width: vw(90);
	height: vw(90);
	border-radius: 50%;
	background: #FFFFFF;
}
.modal-start{
	.inner{
		max-width: vw(1160);
		padding: vw(20);
		width: 100%;
		border-radius: vw(20);
		background: #FFF;
	}
}
.modal-start{

}
.start{
	border-radius: vw(15);
	padding:vw(60);
}
.start__title{
	font-family: "Trajan Pro 3";
	font-size: vw(40);
	font-weight: 400;
	line-height: 120%; /* 48px */
	letter-spacing: vw(-1.2);
	margin-bottom: vw(25);
}
.start-text{
	color: #FFF;
	font-size: vw(15);
	font-weight: 400;
	line-height: 125%;
	max-width: vw(480);
	margin-bottom: vw(40);
	p{
		margin-bottom: vw(23);
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.start-block{
	border-radius: vw(10);
	background: #628D9B;
	box-shadow: 0 vw(4) vw(55) 0 rgba(255, 255, 255, 0.25) inset;
	max-width: vw(480);
	width: 100%;
	padding: vw(30) vw(40);
	position: relative;
	&::after{
		position: absolute;
		width: vw(59);
		height: vw(55);
		background-size: cover;
		background-position: center;
		background-image: url('../img/icons/start-quote.svg');
		content: '';
		display: block;
		top: vw(-20);
		right: vw(20);
	}
}
.start-block__text{
	color: #FFF;
	margin-bottom: vw(20);
	font-size:vw(14);
	font-weight: 400;
	line-height: 125%;
	strong{
		font-weight: 500;
	}
}
.start-number__phone{
	color: #FFF;
	font-family: "Trajan Pro 3";
	font-size: vw(20);
	font-weight: 400;
	line-height: 125%;
}
.start-number__name{
	color: rgba(255, 255, 255, 0.75);
	font-size: vw(13);
	font-weight: 200;
	line-height: 125%; 
}
.start-number{
	margin-right: vw(40);
	position: relative;
	&:last-child{
		margin-right: 0;
	}
	&:first-child{
		&::after{
			position: absolute;
			width: vw(10);
			height: vw(15);
			background-size: cover;
			background-position: center;
			background-image: url('../img/icons/start-sim.svg');
			top: vw(5);
			right: vw(-25);
			content: '';
			display: block;
		}
	}
}
.start{
	position: relative;
	.hello__fio{
		position: absolute;
		top: vw(285);
		right: vw(66);
		font-size: vw(12);
	}
}
.webinar{
	max-width: 100%;
	width: 100%;
	padding-top: vw(80);
	padding-left: vw(80);
	padding-bottom:vw(60);
	.modal-exit__title{
		color: #3E4953;
		font-family: "Trajan Pro 3";
		font-size: vw(40);
		font-weight: 400;
		line-height: 120%;
		letter-spacing: vw(-1.2);
	}

	.modal-exit__name{
		color: #3E4953;
		font-size: vw(18);
		font-weight: 600;
		line-height: normal;
	}
	.exit-photo{
		
	}
	.exit-row{
		
	}
	.modal-exit-list{
		color: #3E4953;
	}
	.modal-exit__btn{
		background: #628D9B;
	}
	.modal-exit-list{
		li{
			&::after{
				background-image: url('../img/icons/check-blue.svg');
			}
		}
	}
}
.webinar__pretitle{
	color: #3E4953;
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	margin-bottom: vw(25);
}
.modal-exit__title{
	margin-bottom:vw(50);
}
.modal-keys{
	.inner{
		border-radius: vw(20);
		background: #FFF;
		max-width: vw(1160);
		width: 100%;
	}
	.webinar{
		padding-left:vw(60);
	}
}
.keys{
	padding-left:vw(60);
	padding-top:vw(60);
	padding-right:vw(60);
	.section-title{
		font-size: vw(40);
		line-height: 120%;
		letter-spacing: vw(-1.2);
		margin-bottom: vw(25);
	}

}
.keys-text{
	color: #3E4953;
	margin-top: vw(25);
	font-size: vw(18);
	font-weight: 400;
	line-height: 125%;
	strong{
		font-weight: 600;
	}
	p{
		margin-bottom: vw(25);
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.keys-slide__photo{
	height: vw(150);
	width: 100%;
	border-radius: vw(10);
	margin-bottom: vw(15);
}
.keys-block{
	max-width:vw(249);
	width: 100%;
	margin-bottom: vw(25);
}
.keys-slide__title{
	color: #3E4953;
	font-family: Roboto;
	font-size: vw(15);
	font-weight: 300;
	line-height: 125%; 
}
.keys-slider{
	//
}
.keys-slide{
	margin-bottom: vw(-25);
}
.keys-slider-wrapper{
	margin-top: vw(31);
	position: relative;
	.troubles-arrows{
		width: vw(160);
		justify-content: space-between;
		right: 0px;
		top: vw(-100);
		transform: none;
		bottom: unset;
	}
	.troubles-arrow.slick-disabled{
		background: #F0F2F4;
	}
	.slick-dots{
		width: 100%;
		bottom: vw(-47);
	}
	.slick-dots li{
		width: vw(7);
		height: vw(7);
		margin-right: vm(7);
		margin-left: vm(7);
	}
	.slick-dots li.slick-active{
		background: #628D9B;
	}
}
.keys-next{
	transform: scale(-1, 1);
}
.modal-keys{
	.modal-window__close{
		border-radius: vw(8);
		background: #F6F7F8;
		box-shadow: 0px vw(4) vw(10) 0px rgba(255, 255, 255, 0.25) inset;
		border: none;
	}
}
.keys-slide__play{
	width: vw(70);
	height: vw(70);
	border-radius: 50%;
	background: #FFFFFF;
}
.keys-slide{
	display: flex!important;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;

}
.modal-start{
	.modal-window__close{
		border: none;
	}
}

.care-subtitle__bottom img{
	max-width: vw(15);
}
.care-block__photo{
	
}
.header-soc__item{
	img{
		max-width: vw(22);
	}
}
.form-label__img{
	max-width: vw(16);
}
.deeper-block__play{
	img{
		max-width: vw(13);
	}
}
.cooperation__play{
	img{
		max-width: vw(18);
	}
}
.troubles-arrow{
	svg{
		max-width: vw(9);
		width: 100%;
	}
}
.latent-happening{
	img{
		max-width: vw(11);
	}
}
.latent__zoom{
	img{
		max-width: vw(15);
	}
}
.every-slide__link{
	img{
		max-width: vw(16.5);
	}
}
.portfolio__subtitle img{
	max-width: vw(32);
}
.portfolio-block__zoom{
	img{
		max-width: vw(15);
	}
}
.reviews-slider__arrow img{
	max-width: vw(10);
}
.first-screen-term img{
	max-width: vw(11);
}
.first-screen-term{
	white-space: nowrap;
}
.reviews-slide__play{
	img{
		max-width: vw(11);
	}
}
.reviews-slider__arrow{
	white-space: nowrap;
}
.hello__signature{
	max-width: vw(160);
}
.hello__quote{
	max-width: vw(92);
}

.first-screen-count__img{
	&:nth-child(1){
		max-width: vw(65);
	}
	&:nth-child(3){
		max-width: vw(67);
	}
	&:nth-child(5){
		max-width: vw(150);
	}
}

svg path{
	width: 100%;
}
.court-photo__play{
	img{
		max-width: vw(14);
	}
}
.overflow{
	overflow: hidden;
}

.keys-slide__play{
	img{
		max-width: vw(11);
	}
}
.modal-exit__btn{
	img{
		max-width: vw(19);
	}
}
.blick{

}
.blick{
    background-image: linear-gradient(-45deg, transparent 0%, transparent 49%, #fff 50%, transparent 70%) !important;
    background-repeat: no-repeat !important;
    -webkit-animation: blick-slide infinite 3s;
    animation: blick-slide infinite 3s;
    .spn-txt{
    	position: relative;
    	z-index: 2;
    }
}
@keyframes blick-slide{
	0% {
	    background-position-x: -20vw;
	}
	100% {
	    background-position-x: calc(100% + 20vw);
	}
}
.form-btn{
	-webkit-animation: blick-slide-btn infinite 4s;
	animation: blick-slide-btn infinite 4s;
}

@keyframes blick-slide-btn{
	0% {
	    background-position-x: -30vw;
	}
	100% {
	    background-position-x: calc(100% + 30vw);
	}
}
.first-line .slide{
	white-space: nowrap;
}
.form-input{
	border: vw(1) solid #F6F7F8;
	transition: .4s all ease;
}
.form-input.error{
	border-color: red;
}

.every-slide__text{
	display: -webkit-box;
	    -webkit-line-clamp: 2;
	    -webkit-box-orient: vertical;
}
.keys-slide__title{
	display: -webkit-box;
	    -webkit-line-clamp: 2;
	    -webkit-box-orient: vertical;
	    overflow: hidden;
}

.thanks{
	margin-bottom: vw(100);
	.content{
		padding-top: vw(173);
		padding-bottom: vw(90);
		min-height: vw(700);
	}
}
.deeper--thanks{
	.section-title{
		font-size: vw(40);
		line-height: 120%;
		letter-spacing: vw(-1.2);
	}
}
.thanks__title{
	color: #FFF;
	font-family: "Trajan Pro 3";
	font-size: vw(45);
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: vw(-1.35);
	margin-bottom: vw(137);
	span{
		color: #F8E7D6;
	}
}
.thanks-text{
	color: #FFF;
	max-width: vw(460);
	font-family: Roboto;
	font-size: vw(18);
	font-style: normal;
	font-weight: 300;
	line-height: 125%;
	strong{
		font-weight: 500;
	}
	p{
		margin-bottom: vw(27);
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.header--thanks{
	.header-number__phone{
		color: #FFF;
	}
	.header-number__name{
		color: rgba(255, 255, 255, 0.75);
	}
	.header-link{
		color: #F8E7D6;
	}
}
.thanks-text{
	position: relative;
	&::after{
		position: absolute;
		width: vw(92);
		height: vw(85);
		background-size: cover;
		background-position: center;
		content: '';
		display: block;
		top: vw(-30);
		right: vw(-40);
		background-image: url('../img/icons/thanks-quote.svg');
	}
}
.exit-photo__play{
	img{
		max-width: vw(16.5);
	}
}
.webinar--section{
	padding-right: 0;
	padding-left: 0;
	padding-top: vw(100);
	padding-bottom: vw(120);
	.exit-photo{
		width: vw(600);
		height: vw(404);
	}
	.modal-exit-list{
		font-size: vw(18);
	}
	.webinar__pretitle{
		font-size: vw(20);
		margin-bottom: vw(15);
	}
}
.deeper--thanks{
	padding-bottom: 0;
}
.thanks .content{
	position: relative;
	.hello__fio{
		position: absolute;
		right: 10vw;
		top: 24vw;
	}
}

.selectize-dropdown-content{
	overflow-y: scroll;
}

.selectize-dropdown-content::-webkit-scrollbar {
  width: vw(10); /* Ширина всего элемента навигации */
}

.selectize-dropdown-content::-webkit-scrollbar-track {
  background: #fff; /* Цвет дорожки */
}

.selectize-dropdown-content::-webkit-scrollbar-thumb {
  background-color: #9B7F62; /* Цвет бегунка */
  border-radius: 0px; /* Округление бегунка */
  border: none;
}

.reviews-slide__play, .deeper-block__play, .cooperation__play, .court-photo__play, .latent__zoom, .every-slide__link, .portfolio-block__zoom, .keys-slide__play, .exit-photo__play{
	transition: .4s all ease;
	&:hover{
		transform: scale(1.1);
	}
}
.troubles-arrow{
	transition: .4s all ease;
	&:hover{
		opacity: .85;
	}
}
.form-btn{
	transition: .4s all ease;
	&:hover{
		box-shadow: unset;
	}
}
.first-line__btn{
	transition: .4s all ease;
	&:hover{
		opacity: .9;
	}
}

.header-soc__item{
	transition: .4s all ease;
	&:hover{
		opacity: .75;
	}
}
.footer__copy a{
	transition: .4s all ease;
	&:hover{
		text-decoration: unset;
		color: #9B7F62;
	}
}
.header-number__phone{
	transition: .4s all ease;
	&:hover{
		color: #9B7F62;
	}
}
.header-link{
	transition: .4s all ease;
	&:hover{
		text-decoration: unset;
	}
}

.deeper-block__play{
	&:hover{
		transform: translate(-50%, -50%) scale(1.1);
	}
}

.modal-exit__btn{
	transition: .4s all ease;
	&:hover{
		box-shadow: unset;
		color: #FFF;
	}
}

.modal-video{
	.inner{
		width: 1100px;
		height: 600px;
		iframe{
			width: 100%;
			height: 100%;
		}
	}
}
.reviews-slide__play, .every-slide__link, .deeper-block__play, .cooperation__play, .court-photo__play, .keys-slide__play, .exit-photo__play{
	cursor: pointer;
}