@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap");
@font-face {
  font-family: 'Trajan Pro 3';
  src: url("../fonts/TrajanPro3-Regular.woff2") format("woff2"), url("../fonts/TrajanPro3-Regular.woff") format("woff"), url("../fonts/TrajanPro3-Regular.svg#TrajanPro3-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gotham Pro';
  src: url("../fonts/GothamPro-Bold.woff2") format("woff2"), url("../fonts/GothamPro-Bold.woff") format("woff"), url("../fonts/GothamPro-Bold.svg#GothamPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

.fancybox__container {
  z-index: 9999999999999999; }

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 80.55556vw;
  width: 100%; }

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.flex-a {
  display: flex;
  align-items: center; }

.flex-t {
  display: flex;
  align-items: flex-start; }

.flex-b {
  display: flex;
  align-items: flex-end; }

.flex-c {
  display: flex;
  align-items: center;
  justify-content: center; }

.photo-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.cicle {
  border-radius: 50%; }

.title {
  font-family: 'Trajan Pro 3'; }
  .title span {
    color: #9B7F62; }

.troubles-arrows {
  pointer-events: none; }

.troubles-arrow {
  pointer-events: all; }

.first-screen .content {
  padding-top: 10.06944vw;
  padding-bottom: 3.68056vw;
  border-radius: 1.38889vw; }

.section-padding {
  padding: 0.69444vw; }
  .section-padding .content {
    border-radius: 1.38889vw; }

.first-screen__title {
  margin-bottom: 1.38889vw;
  font-size: 2.77778vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 48px */
  letter-spacing: -0.08333vw; }

.first-screen-term {
  border-radius: 0.41667vw;
  background: #FFF;
  display: flex;
  align-items: center;
  color: #3E4953;
  padding: 0.69444vw;
  font-family: "Wix Madefor Display";
  font-size: 0.76389vw;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  /* 13.75px */
  letter-spacing: 0.03819vw;
  text-transform: uppercase;
  margin-right: 0.69444vw;
  display: inline-flex; }
  .first-screen-term img {
    margin-right: 0.69444vw; }

.first-screen-count {
  margin-top: 3.47222vw; }
  .first-screen-count .wrapper {
    display: flex;
    align-items: center;
    border-radius: 0.69444vw;
    background: #FFF;
    backdrop-filter: blur(1.5625vw);
    justify-content: center;
    width: 25.90278vw;
    height: 3.47222vw; }
  .first-screen-count span {
    color: rgba(155, 127, 98, 0.25);
    margin-right: 0.69444vw;
    margin-left: 0.69444vw;
    font-size: 0.76389vw;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.03819vw;
    text-transform: uppercase; }

.first-screen-count__title {
  color: #3E4953;
  margin-right: 1.38889vw;
  font-size: 1.38889vw;
  font-weight: 400;
  line-height: 125%; }

.reviews-slider-wrapper {
  display: flex;
  align-items: center;
  width: 83.33333vw;
  max-width: 83.33333vw;
  margin-right: -1.38889vw;
  margin-left: -1.38889vw;
  border-radius: 1.04167vw;
  background: #FFF;
  padding-top: 1.52778vw;
  padding-bottom: 1.38889vw;
  margin-top: 1.38889vw;
  padding-left: 1.38889vw;
  position: relative; }

.reviews-slide {
  width: 100%;
  margin-right: 0.34722vw;
  margin-left: 0.34722vw; }
  .reviews-slide .photo-bg {
    width: 100%;
    height: 6.94444vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: grey;
    border-radius: 0.69444vw;
    margin-bottom: 1.04167vw; }
  .reviews-slide .reviews-slide__text {
    color: #3E4953;
    font-family: Roboto;
    font-size: 1.04167vw;
    font-weight: 300;
    line-height: 125%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }

.reviews-slider {
  width: 100%;
  max-width: 66.66667vw;
  margin-right: -0.34722vw;
  margin-left: -0.34722vw; }

.reviews-slide__play {
  border-radius: 3.47222vw;
  background: #FFF;
  width: 4.16667vw;
  height: 4.16667vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.reviews-slider__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9B7F62;
  box-shadow: 0 0.29069vw 2.54361vw 0 rgba(255, 255, 255, 0.25) inset;
  width: 15.625vw;
  height: 15.625vw;
  border-radius: 50%;
  top: -1.04167vw;
  right: -1.38889vw;
  position: absolute;
  color: #FFFAF5;
  text-align: center;
  font-family: Roboto;
  font-size: 1.45347vw;
  font-weight: 600;
  line-height: 1.81688vw;
  /* 125% */
  letter-spacing: -0.04361vw;
  transition: .4s all ease; }
  .reviews-slider__arrow span {
    font-weight: 500; }
  .reviews-slider__arrow img {
    margin-right: 0.27778vw;
    margin-left: 0.27778vw; }
  .reviews-slider__arrow:hover {
    box-shadow: none;
    color: #FFFAF5; }

.first-line {
  background: #FFF;
  backdrop-filter: blur(1.21528vw);
  padding-top: 0.69444vw;
  padding-bottom: 0;
  height: 2.98611vw;
  overflow: hidden; }
  .first-line .slide {
    color: #3E4953;
    font-family: Roboto;
    font-size: 1.25vw;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.0375vw; }
    .first-line .slide span {
      color: #9B7F62; }

.first-line-wrapper {
  position: relative;
  margin-top: 3.54167vw; }

.first-line__btn {
  border-radius: 0.69444vw;
  background: #3E4953;
  width: 14.09722vw;
  height: 4.16667vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7.77778vw;
  color: #F8FBFF;
  text-align: center;
  font-family: Roboto;
  font-size: 1.25vw;
  font-weight: 600;
  line-height: 1.73611vw;
  /* 138.889% */
  letter-spacing: -0.0375vw; }
  .first-line__btn:hover {
    color: #F8FBFF; }

.hello .container {
  padding-left: 38.88889vw; }

.hello .content {
  border-radius: 1.38889vw;
  padding-top: 8.33333vw;
  padding-bottom: 6.25vw; }

.hello__title {
  color: #F6F7F8;
  font-size: 3.47222vw;
  font-weight: 600;
  line-height: 3.47222vw;
  letter-spacing: -0.10417vw;
  margin-bottom: 3.47222vw; }

.hello-text {
  color: #F6F7F8;
  font-size: 1.38889vw;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 1.73611vw; }
  .hello-text p {
    margin-bottom: 1.73611vw; }
    .hello-text p:last-child {
      margin-bottom: 0; }

.hello-wrapper {
  margin-left: 6.94444vw; }

.hello__fio {
  color: rgba(246, 247, 248, 0.65);
  font-size: 1.11111vw;
  font-weight: 400;
  line-height: 125%; }
  .hello__fio strong {
    color: #F6F7F8;
    font-weight: 700; }

.hello-wrapper {
  position: relative; }

.hello__quote {
  position: absolute;
  top: 0;
  left: -9.30556vw; }

.hello__signature {
  position: absolute;
  bottom: -1.38889vw;
  left: 12.98611vw; }

.first-screen {
  padding-bottom: 0; }

.section-title {
  color: #444;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: rgba(155, 127, 98, 0.55);
  font-family: "Trajan Pro 3";
  font-size: 3.125vw;
  font-weight: 400;
  line-height: 120%;
  /* 54px */
  letter-spacing: -0.09375vw; }

.portfolio {
  padding-top: 7.63889vw; }
  .portfolio .section-title {
    margin-bottom: 1.38889vw; }

.sum {
  padding-left: 1.38889vw;
  padding-right: 1.38889vw; }
  .sum .content {
    border-radius: 1.73611vw;
    padding-top: 7.29167vw;
    padding-bottom: 8.05556vw;
    padding-left: 47.36111vw; }
  .sum .section-title {
    margin-bottom: 2.08333vw; }

.section-title span {
  color: #9B7F62; }

.sum__text {
  color: #3E4953;
  max-width: 34.72222vw;
  font-family: Roboto;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%; }
  .sum__text p {
    margin-bottom: 1.73611vw; }
    .sum__text p:last-child {
      margin-bottom: 0; }

.big-block {
  padding-right: 1.38889vw;
  padding-left: 1.38889vw; }
  .big-block .content {
    border-radius: 1.73611vw; }

.section-title--center {
  text-align: center; }

.big-block .content {
  padding-top: 6.25vw;
  padding-bottom: 6.25vw; }

.care {
  margin-top: 1.38889vw; }
  .care .content {
    background: #3C464F; }
  .care .section-title {
    font-size: 3.81944vw;
    line-height: 120%;
    /* 66px */
    letter-spacing: -0.11458vw;
    margin-bottom: 1.73611vw; }
  .care .arrow-left {
    transform: scale(-1, 1); }

.white {
  color: #FFF; }
  .white .section-title {
    color: #FFF; }

.care-subtitle {
  margin-right: auto;
  margin-left: auto;
  max-width: 39.375vw;
  text-align: center;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; }

.care-subtitle__bottom {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  justify-content: center;
  margin-top: 1.73611vw;
  font-weight: 500; }
  .care-subtitle__bottom img {
    margin-right: 1.38889vw; }
  .care-subtitle__bottom .arrow-left {
    margin-right: 0;
    margin-left: 1.04167vw; }

.care-block {
  width: 100%;
  border-radius: 1.73611vw;
  background: #628D9B;
  box-shadow: 0 0.27778vw 2.77778vw 0 rgba(255, 255, 255, 0.25) inset;
  max-width: 25.97222vw;
  padding-bottom: 1.31944vw;
  padding-top: 3.47222vw;
  padding-left: 1.38889vw;
  padding-right: 1.38889vw; }
  .care-block .wrapper {
    padding-left: 1.38889vw; }

.care-block__title {
  color: #FFF;
  font-family: Roboto;
  font-size: 1.38889vw;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 1.38889vw; }

.care-block__text {
  color: rgba(255, 255, 255, 0.75);
  font-family: Roboto;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%;
  min-height: 11.11111vw;
  margin-bottom: 1.38889vw; }
  .care-block__text p {
    margin-bottom: 1.73611vw; }
    .care-block__text p:last-child {
      margin-bottom: 0; }

.care-block__photo {
  width: 100%;
  border-radius: 1.04167vw;
  background-color: grey;
  height: 13.88889vw;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 1.04167vw;
  padding-bottom: 1.04167vw; }

.care-row {
  margin-top: 4.51389vw; }

.care-block__loop {
  border-radius: 0.69444vw;
  background: rgba(98, 141, 155, 0.2);
  width: 3.125vw;
  height: 3.125vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.care-block--gray {
  background: #9B7F62;
  box-shadow: 0 0.27778vw 2.77778vw 0 rgba(255, 255, 255, 0.25) inset; }
  .care-block--gray .care-block__loop {
    background: rgba(155, 127, 98, 0.2); }

.questions {
  padding-top: 7.63889vw;
  padding-bottom: 7.63889vw; }

.questions-row {
  margin-top: 2.77778vw;
  margin-bottom: -0.69444vw;
  align-items: flex-start; }

.questions-block {
  border-radius: 0.69444vw;
  background: #FFF;
  padding-left: 2.77778vw;
  padding-right: 2.77778vw;
  width: 100%;
  max-width: 39.93056vw;
  cursor: pointer;
  margin-bottom: 0.69444vw; }
  .questions-block .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2.43056vw;
    padding-bottom: 2.43056vw;
    transition: .4s all ease; }
  .questions-block .hidden {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); }
  .questions-block .content {
    padding-bottom: 2.77778vw;
    padding-top: 1.38889vw;
    border-top: 0.06944vw solid rgba(0, 0, 0, 0.05);
    color: rgba(62, 73, 83, 0.75);
    font-size: 1.11111vw;
    font-weight: 400;
    line-height: 125%; }
    .questions-block .content p {
      min-height: 5.55556vw; }
    .questions-block .content a {
      color: #9B7F62;
      text-decoration-line: underline;
      text-decoration-skip-ink: none; }

.questions-block__title {
  color: #3E4953;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.0375vw; }

.questions-block__arrow {
  transition: .4s all ease; }

.questions-block.opened .top {
  padding-bottom: 1.38889vw; }

.questions-block.opened .hidden {
  max-height: 138.88889vw;
  transition: max-height 1s ease-in-out; }

.questions-block.opened .questions-block__arrow {
  transform: scale(1, -1); }

.deeper-block {
  max-width: 25.97222vw;
  width: 100%;
  padding-left: 2.08333vw;
  padding-top: 2.08333vw;
  height: 17.36111vw;
  position: relative;
  border-radius: 1.73611vw; }

.deeper {
  padding-bottom: 7.63889vw; }
  .deeper .section-title {
    margin-bottom: 3.47222vw; }

.deeper-block__play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 4.86111vw;
  height: 4.86111vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff; }

.deeper-block__title {
  color: #FFF;
  font-family: "Gotham Pro";
  font-size: 0.97222vw;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.04861vw;
  text-transform: uppercase;
  margin-bottom: 1.07639vw; }
  .deeper-block__title strong {
    color: #E3CDB6; }

.lose {
  margin-bottom: 3.47222vw; }
  .lose .content {
    border-radius: 1.38889vw;
    box-shadow: 0 0.27778vw 4.86111vw 0 rgba(255, 255, 255, 0.2) inset;
    padding-top: 5.55556vw;
    padding-bottom: 3.47222vw; }

.section-title--big {
  font-size: 3.81944vw;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.11458vw; }

.lose__subtitle {
  margin-top: 1.38889vw;
  margin-bottom: 3.05556vw;
  margin-bottom: 3.05556vw;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.0375vw; }
  .lose__subtitle strong {
    font-weight: 500; }

.section-form {
  border-radius: 1.38889vw;
  background: #FFF;
  padding: 1.04167vw;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.04167vw;
  background: #2A3138;
  box-shadow: 0 0.27778vw 1.04167vw 0 rgba(255, 255, 255, 0.15) inset;
  width: 25vw;
  height: 5.90278vw;
  color: #FFF;
  border: none;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 1.38889vw; }

.form-input {
  border-radius: 1.04167vw;
  background: #F6F7F8;
  width: 18.40278vw;
  height: 5.90278vw;
  padding-left: 3.88889vw;
  border: none;
  font-family: Roboto;
  color: #3e4953;
  font-size: 1.18056vw;
  font-weight: 400;
  line-height: 1.52778vw; }
  .form-input::placeholder {
    font-family: Roboto;
    color: rgba(62, 73, 83, 0.55); }

.form-label {
  position: relative; }

.form-label__img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2.08333vw; }

.selectize-input {
  border-radius: 1.04167vw;
  background: #F6F7F8;
  width: 33.33333vw;
  height: 5.90278vw;
  border: none !important;
  box-shadow: none !important;
  border-radius: 1.04167vw;
  background: #F6F7F8 !important;
  padding-left: 2.8125vw;
  color: rgba(62, 73, 83, 0.55);
  transition: .4s all ease;
  font-family: Roboto;
  font-size: 1.18056vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.52778vw;
  /* 129.412% */
  letter-spacing: -0.03542vw;
  display: flex !important;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0; }

.section-form__polcy {
  margin-top: 0.83333vw;
  color: rgba(255, 255, 255, 0.55);
  font-size: 1.04167vw;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.03125vw; }
  .section-form__polcy a {
    color: #FEEBD6;
    text-decoration: underline;
    text-decoration-skip-ink: none; }

.selectize-control.single .selectize-input:after {
  width: 0.83333vw;
  height: 0.48611vw;
  background-image: url("../img/icons/select-arrow.svg");
  padding: 0vw;
  border: none;
  background-size: cover;
  right: 2.08333vw;
  transition: .4s all ease; }

.selectize-control.single .selectize-input.dropdown-active:after {
  margin-top: -0.20833vw;
  transform: scale(1, -1); }

.selectize-dropdown .create, .selectize-dropdown .no-results, .selectize-dropdown .optgroup-header, .selectize-dropdown .option {
  padding-top: 0.90278vw;
  padding-bottom: 0.90278vw;
  padding-left: 2.8125vw;
  background: #F6F7F8;
  color: rgba(62, 73, 83, 0.55);
  transition: .4s all ease;
  font-family: Roboto;
  font-size: 1.18056vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.52778vw;
  /* 129.412% */
  letter-spacing: -0.03542vw; }

.selectize-dropdown {
  border: none; }

.lose .container {
  position: relative; }

.lose__fio {
  position: absolute;
  color: rgba(246, 247, 248, 0.65);
  font-size: 1.11111vw;
  font-weight: 400;
  line-height: 125%;
  right: 3.40278vw;
  bottom: 14.58333vw; }
  .lose__fio strong {
    color: #F6F7F8;
    font-weight: 700; }

.price {
  margin-bottom: 10.06944vw;
  margin-top: 10.13889vw; }
  .price .content {
    padding-top: 5.55556vw;
    padding-bottom: 3.47222vw; }
  .price .container {
    position: relative; }
  .price .form-btn {
    background: #9B7F62;
    box-shadow: 0 0.27778vw 1.04167vw 0 rgba(255, 255, 255, 0.35) inset; }
  .price .section-form__polcy {
    color: rgba(255, 255, 255, 0.55); }
    .price .section-form__polcy a {
      color: #1A96DD; }

.price-name {
  display: flex;
  align-items: center;
  margin-top: 1.38889vw;
  margin-bottom: 4.375vw;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.0375vw; }
  .price-name .cicle {
    width: 3.47222vw;
    height: 3.47222vw;
    border-radius: 50%;
    margin-right: 1.38889vw; }

.price-fio__text {
  color: rgba(255, 255, 255, 0.65);
  font-size: 1.11111vw;
  font-weight: 400;
  line-height: 125%;
  position: relative; }
  .price-fio__text::after {
    width: 0.69444vw;
    height: 0.69444vw;
    content: '';
    display: block;
    background-size: cover;
    background-image: url("../img/price/line.svg");
    position: absolute;
    left: 0vw;
    top: -1.73611vw; }
  .price-fio__text strong {
    color: #FFF;
    font-weight: 500; }

.price-fio-abc {
  position: absolute;
  right: 9.58333vw;
  bottom: 17.29167vw; }

.block-small {
  padding-right: 3.47222vw;
  padding-left: 3.47222vw; }
  .block-small .content {
    border-radius: 1.38889vw; }

.latent-happening {
  display: flex;
  align-items: center; }

.latent {
  padding-bottom: 0.69444vw;
  margin-bottom: 0.69444vw; }
  .latent .content {
    padding-top: 4.86111vw;
    padding-bottom: 4.86111vw; }
  .latent .section-title {
    color: #F5F5F5; }
    .latent .section-title span {
      color: #E3CDB5; }

.latent__text {
  max-width: 32.77778vw;
  font-size: 1.25vw;
  margin-top: 1.38889vw;
  font-weight: 300;
  line-height: 125%; }
  .latent__text p {
    margin-bottom: 1.73611vw; }
    .latent__text p:last-child {
      margin-bottom: 0; }

.latent-happening {
  display: flex;
  align-items: center;
  border-radius: 0.41667vw;
  background: rgba(255, 255, 255, 0.05);
  width: 12.98611vw;
  height: 2.22222vw;
  justify-content: center;
  margin-bottom: 0.69444vw;
  color: #FFF;
  font-family: "Gotham Pro";
  font-size: 0.76389vw;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.03819vw;
  text-transform: uppercase; }
  .latent-happening img {
    margin-right: 0.69444vw; }
  .latent-happening span {
    position: relative;
    top: 0.06944vw; }

.latent .container {
  position: relative; }

.latent__zoom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.125vw;
  height: 3.125vw;
  background: #FFF;
  bottom: 0;
  right: 8.05556vw; }

.sheepskin .content {
  padding-bottom: 3.47222vw;
  padding-top: 5.55556vw; }

.sheepskin__text {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 1.38889vw;
  margin-bottom: 3.125vw;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%;
  /* 22.5px */
  letter-spacing: -0.0375vw; }

.latent {
  margin-bottom: 0; }

.sheepskin .container {
  position: relative; }

.sheepskin .price-fio__text::after {
  background-image: url("../img/icons/line-white.svg"); }

.sheepskin .price-fio-abc {
  right: 6.25vw;
  bottom: 15.90278vw; }

.sheepskin {
  margin-bottom: 3.47222vw; }
  .sheepskin .content {
    position: relative; }
    .sheepskin .content::after {
      position: absolute;
      width: 1.66667vw;
      height: 1.94444vw;
      background-size: cover;
      background-position: center;
      background-image: url("../img/icons/polygon-2.svg");
      content: '';
      display: block;
      z-index: 100;
      top: 4.51389vw;
      right: -0.83333vw; }

.latent .content {
  position: relative; }
  .latent .content::after {
    position: absolute;
    width: 1.66667vw;
    height: 1.94444vw;
    background-size: cover;
    background-position: center;
    background-image: url("../img/icons/polygon-1.svg");
    content: '';
    display: block;
    z-index: 100;
    top: 4.51389vw;
    left: -0.83333vw; }

.seems {
  padding-bottom: 6.25vw; }

.seems-row {
  margin-top: 2.77778vw; }

.seems-line {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 1.04167vw;
  width: 100%; }
  .seems-line:last-child {
    margin-bottom: 0; }

.seems-name {
  border-radius: 0.69444vw;
  background: #628D9B;
  box-shadow: 0 0.27778vw 1.73611vw 0 rgba(255, 255, 255, 0.25) inset;
  width: 100%;
  max-width: 27.77778vw;
  padding-left: 2.08333vw;
  padding-top: 1.73611vw;
  padding-bottom: 1.73611vw;
  color: #FFF;
  position: relative;
  font-size: 1.38889vw;
  font-weight: 500;
  line-height: 125%; }
  .seems-name::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.04167vw;
    height: 1.04167vw;
    background-size: cover;
    background-position: center;
    background-image: url("../img/icons/seems-arrow.svg");
    content: '';
    display: block;
    right: -4.16667vw; }

.seems-content {
  border-radius: 0.69444vw;
  background: #FFF;
  width: 100%;
  padding-left: 2.77778vw;
  display: flex;
  align-items: center;
  max-width: 45.48611vw;
  color: #444;
  font-size: 1.25vw;
  font-weight: 300;
  line-height: 125%; }

.every-slider {
  margin-top: 4.16667vw;
  margin-bottom: 4.51389vw;
  margin-left: -0.69444vw;
  margin-right: -48.61111vw; }

.every-slide {
  width: 25.97222vw;
  margin-right: 0.69444vw;
  margin-left: 0.69444vw; }
  .every-slide .photo-bg {
    height: 13.88889vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: grey;
    border-radius: 1.04167vw; }

.every-slide__text {
  color: #3E4953;
  margin-top: 1.04167vw;
  font-size: 1.25vw;
  font-weight: 300;
  line-height: 125%; }

.every-slide__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 6.25vw;
  height: 6.25vw;
  background: #FFFFFF; }

.messag-cicle {
  width: 14.58333vw;
  height: 14.58333vw;
  flex-shrink: 0; }

.messag-content {
  max-width: 100%;
  max-width: 62.5vw;
  padding-top: 2.36111vw;
  padding-left: 2.43056vw;
  padding-right: 2.08333vw;
  border-radius: 1.38889vw;
  background: #9B7F62;
  min-height: 13.19444vw; }

.every-messag {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.every {
  margin-bottom: 7.63889vw; }

.messag-content__title {
  color: #FFF;
  margin-bottom: 1.04167vw;
  font-size: 1.52778vw;
  font-weight: 600;
  line-height: 125%; }

.messag-content__text {
  color: #FFF;
  font-size: 1.25vw;
  font-weight: 300;
  line-height: 125%; }

.messag-content__direktor {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25vw;
  color: rgba(255, 255, 255, 0.55);
  font-size: 1.11111vw;
  font-style: italic;
  font-weight: 400;
  line-height: 125%; }
  .messag-content__direktor strong {
    font-weight: 400;
    color: #FFF; }

.messag-content {
  position: relative;
  width: 100%; }
  .messag-content::after {
    position: absolute;
    content: '';
    display: block;
    width: 6.38889vw;
    height: 5.90278vw;
    background-size: cover;
    background-position: center;
    background-image: url("../img/every/every-quote.svg");
    top: 1.73611vw;
    right: 1.78472vw; }
  .messag-content::before {
    position: absolute;
    content: '';
    display: block;
    width: 2.01389vw;
    height: 1.66667vw;
    background-size: cover;
    background-position: center;
    background-image: url("../img/every/every-polygon-1.svg");
    bottom: 3.54167vw;
    right: -1.80556vw; }

.every-messag {
  margin-bottom: 2.08333vw; }
  .every-messag:last-child {
    margin-bottom: 0; }

.every-messag--blue .messag-content {
  background: #628D9B; }
  .every-messag--blue .messag-content::before {
    background-image: url("../img/every/every-polygon-2.svg");
    bottom: 3.54167vw;
    left: -1.18056vw;
    right: unset; }

.every-counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-image: url("../img/icons/cicle-bg.svg");
  width: 20.83333vw;
  height: 20.83333vw;
  position: relative;
  color: #FFF;
  font-family: "Trajan Pro 3";
  font-size: 1.63785vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 28.302px */
  letter-spacing: -0.04917vw; }
  .every-counter strong {
    color: #FFF;
    font-family: "Trajan Pro 3";
    font-size: 6.25vw;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.1875vw;
    margin-bottom: -1.45833vw;
    display: inline-block; }
  .every-counter p {
    max-width: 12.56944vw; }

.every .container {
  position: relative; }

.every-counter-abc {
  position: absolute;
  right: 0.38194vw;
  top: -12.5vw; }

.cooperation {
  margin-top: 7.63889vw;
  position: relative;
  z-index: 5; }
  .cooperation .content {
    padding-top: 6.25vw;
    padding-bottom: 0;
    border-radius: 1.73611vw;
    background: #9B7F62;
    box-shadow: 0 0.27778vw 5.55556vw 0 rgba(255, 255, 255, 0.25) inset; }

.cooperation-subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.73611vw;
  color: #FFF; }
  .cooperation-subtitle strong {
    font-family: Roboto;
    font-size: 1.38889vw;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-right: 1.38889vw;
    margin-left: 1.38889vw; }
    .cooperation-subtitle strong span {
      color: rgba(255, 255, 255, 0.55); }
  .cooperation-subtitle .cooperation-right {
    transform: scale(-1, 1); }

.cooperation-photo {
  width: 80.55556vw;
  height: 34.72222vw;
  margin-top: 2.77778vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1.38889vw;
  bottom: -2.63889vw; }

.cooperation .content {
  height: 52.08333vw;
  position: relative; }

.check {
  margin-top: 1.38889vw; }
  .check .content {
    padding-top: 7.5vw;
    padding-bottom: 5.34722vw;
    border-radius: 1.38889vw; }

.cooperation__play {
  width: 6.94444vw;
  height: 6.94444vw;
  background: #F6F7F8; }

.check .section-title {
  margin-bottom: 2.08333vw; }
  .check .section-title span {
    color: #FFE4C8; }

.check .price-fio-abc {
  right: 8.19444vw;
  bottom: 49.02778vw; }

.check .price-fio__text::after {
  background-image: url("../img/icons/line-white.svg"); }

.check-block {
  max-width: 26.38889vw;
  border-radius: 1.04167vw;
  background: #F6F7F8; }
  .check-block .top {
    position: relative;
    bottom: 0.13889vw;
    display: flex;
    align-items: center;
    padding-left: 2.43056vw;
    justify-content: space-between;
    padding-right: 2.43056vw;
    padding-top: 1.80556vw;
    padding-bottom: 1.80556vw;
    border-radius: 1.04167vw;
    background: #9B7F62;
    box-shadow: 0 0.27778vw 1.73611vw 0 rgba(255, 255, 255, 0.15) inset; }
  .check-block .bottom {
    color: #3E4953;
    padding-left: 2.08333vw;
    padding-top: 1.94444vw;
    padding-bottom: 2.36111vw;
    padding-right: 2.98611vw; }

.check-block-list {
  margin-top: 0.69444vw; }

.check-block__title {
  color: #FFF;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 125%; }

.check-block__procent {
  color: #FFF;
  font-family: "Trajan Pro 3";
  font-size: 3.125vw;
  font-weight: 400;
  line-height: 100%;
  /* 54px */
  letter-spacing: -0.09375vw;
  position: relative;
  top: 0.27778vw; }

.check-block__text {
  color: #3E4953;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%; }
  .check-block__text strong {
    font-weight: 500;
    color: #3E4953; }

.check-block-list {
  color: #3E4953;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%; }
  .check-block-list li {
    padding-left: 1.45833vw;
    position: relative;
    margin-bottom: 0; }
    .check-block-list li::after {
      position: absolute;
      top: 0.51389vw;
      width: 0.76389vw;
      height: 0.625vw;
      background-size: cover;
      content: '';
      display: block;
      background-image: url("../img/icons/check-color-2.svg");
      left: 0; }
    .check-block-list li:last-child {
      margin-bottom: 0; }

.check-block {
  margin-bottom: 2.08333vw; }

.check-line {
  border-radius: 1.73611vw;
  background: #9B7F62;
  box-shadow: 0 0.27778vw 3.81944vw 0 rgba(255, 255, 255, 0.25) inset;
  padding-top: 3.81944vw;
  padding-bottom: 3.81944vw;
  padding-left: 31.11111vw;
  position: relative; }
  .check-line::after {
    position: absolute;
    width: 6.38889vw;
    height: 5.90278vw;
    background-size: cover;
    background-position: center;
    content: '';
    display: block;
    background-image: url("../img/icons/check-quote.svg");
    top: 5.55556vw;
    left: 22.29167vw; }

.check-line__title {
  color: #FFF;
  font-family: "Trajan Pro 3";
  font-size: 3.125vw;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.09375vw;
  margin-bottom: 0.69444vw; }
  .check-line__title strong {
    font-weight: 400;
    color: #FAE0C5; }

.check-line__text {
  color: #FFF;
  font-family: Roboto;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; }

.check .content {
  position: relative; }

.court {
  padding-top: 7.63889vw; }

.court-participation {
  margin-top: 2.08333vw;
  margin-bottom: 1.73611vw;
  border-radius: 0.69444vw;
  background: #628D9B;
  box-shadow: 0 0.27778vw 1.73611vw 0 rgba(255, 255, 255, 0.45) inset; }

.court-participation__name {
  color: #FFF;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%; }

.court-participation__price {
  color: #FFF;
  font-family: "Trajan Pro 3";
  font-size: 2.43056vw;
  font-weight: 400;
  line-height: 100%;
  /* 42px */
  letter-spacing: -0.07292vw; }

.court-participation {
  padding-left: 1.73611vw;
  max-width: 20.83333vw;
  padding-top: 1.25vw;
  padding-bottom: 1.25vw; }

.court-participation__name {
  margin-bottom: 0.55556vw; }

.court_subtext {
  color: #3E4953;
  margin-top: 1.38889vw;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%; }
  .court_subtext strong {
    font-weight: 700; }

.court-list {
  color: #3E4953;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%; }
  .court-list li {
    padding-left: 1.80556vw;
    position: relative; }
    .court-list li::after {
      position: absolute;
      width: 0.76389vw;
      height: 0.625vw;
      content: '';
      display: block;
      background-size: cover;
      background-image: url("../img/icons/check-blue.svg");
      left: 0vw;
      top: 0.45833vw; }

.court-participation {
  position: relative; }
  .court-participation::after {
    position: absolute;
    width: 0.76389vw;
    height: 1.04167vw;
    background-size: cover;
    background-position: center;
    content: '';
    display: block;
    background-image: url("../img/icons/arrow-bottom.svg");
    bottom: 1.73611vw;
    right: 1.73611vw; }

.court .content {
  position: relative; }
  .court .content::after {
    position: absolute;
    width: 20.20833vw;
    height: 5.69444vw;
    background-size: cover;
    background-position: center;
    background-image: url("../img/icons/court-arrow.svg");
    content: '';
    display: block;
    left: 15.06944vw;
    bottom: 0.74375vw; }

.court .container {
  padding-bottom: 5.55556vw;
  border-bottom: 0.06944vw solid rgba(0, 0, 0, 0.02); }

.court-photo {
  width: 41.66667vw;
  height: 26.38889vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.court-photo__play {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25vw;
  height: 6.25vw;
  border-radius: 50%;
  background: #FFFFFF; }

.troubles-slide {
  display: flex !important;
  align-items: center; }

.troubles-photo {
  width: 34.72222vw;
  height: 36.66667vw;
  flex-shrink: 0;
  border-radius: 1.38889vw;
  margin-right: 4.16667vw; }

.troubles-btn {
  color: #628D9B;
  font-family: "Gotham Pro";
  font-size: 0.90278vw;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.04514vw;
  text-transform: uppercase;
  border-radius: 0.69444vw;
  background: #FFF;
  padding: 1.11111vw 1.38889vw;
  padding-bottom: 0.97222vw;
  margin-right: 0.69444vw;
  display: inline-block; }

.troubles-btns {
  margin-top: 1.38889vw;
  margin-bottom: 3.47222vw; }

.troubles-text {
  color: #3E4953;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%;
  max-width: 37.5vw; }
  .troubles-text p {
    margin-bottom: 1.73611vw; }
    .troubles-text p:last-child {
      margin-bottom: 0; }

.troubles-slide {
  align-items: flex-start; }

.troubles-btn.active {
  color: #FFF;
  background: #628D9B;
  box-shadow: 0 0.27778vw 1.04167vw 0 rgba(255, 255, 255, 0.35) inset; }

.troubles-arrows {
  display: flex;
  align-items: center; }

.troubles-arrow {
  width: 4.86111vw;
  height: 4.86111vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: .4s all ease;
  background: #628D9B;
  box-shadow: 0 0.27778vw 1.04167vw 0 rgba(255, 255, 255, 0.35) inset; }
  .troubles-arrow svg path {
    transition: .4s all ease; }

.troubles-arrow.slick-disabled {
  background: #fff; }
  .troubles-arrow.slick-disabled svg path {
    fill: #628D9B; }

.troubles-next {
  transform: scale(-1, 1);
  margin-left: 1.38889vw; }

.troubles-arrows {
  position: absolute;
  right: 30.55556vw;
  bottom: 0; }

.troubles-slider-wrapper {
  position: relative; }

.portfolio__subtitle {
  display: flex;
  align-items: center;
  color: #3E4953;
  font-size: 1.38889vw;
  font-weight: 400;
  line-height: 125%; }
  .portfolio__subtitle img {
    margin-right: 0.97222vw; }

.portfolio-slider-wrapper {
  margin-top: 3.47222vw; }

.portfolio-block {
  border-radius: 1.04167vw;
  background: #FFF;
  max-width: 19.375vw;
  padding-left: 2.43056vw;
  padding-top: 2.08333vw;
  height: 20.20833vw;
  width: 100%;
  margin-bottom: 0.69444vw;
  position: relative; }

.portfolio-slider {
  max-width: 100%; }

.portfolio-slide {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%; }

.portfolio-slider-wrapper {
  margin-bottom: 7.63889vw; }

.portfolio-block__pretitle {
  color: rgba(62, 65, 80, 0.75);
  font-size: 0.97222vw;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 0.69444vw; }

.portfolio-block__price {
  color: #3E4150;
  margin-bottom: 2.43056vw;
  font-size: 1.73611vw;
  font-weight: 700;
  line-height: 125%; }

.portfolio-block__text {
  color: rgba(62, 65, 80, 0.45);
  font-size: 1.04167vw;
  font-weight: 400;
  line-height: 125%; }
  .portfolio-block__text strong {
    color: #3E4150;
    font-weight: 500; }
  .portfolio-block__text p {
    margin-bottom: 1.38889vw; }
    .portfolio-block__text p:last-child {
      margin-bottom: 0; }

.portfolio-block__zoom {
  width: 3.125vw;
  height: 3.125vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 0.69444vw;
  background: rgba(98, 141, 155, 0.2);
  bottom: 1.38889vw;
  right: 1.38889vw; }

.portfolio-logos {
  color: rgba(155, 127, 98, 0.45);
  font-family: "Gotham Pro";
  font-size: 0.76389vw;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.03819vw;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3.47222vw;
  width: 25.97222vw;
  border-radius: 0.69444vw;
  background: rgba(155, 127, 98, 0.1);
  backdrop-filter: blur(1.5625vw);
  position: relative;
  bottom: 0.27778vw; }
  .portfolio-logos span {
    margin-right: 0.69444vw;
    margin-left: 0.69444vw; }

.slick-dots li button {
  display: none; }

.slick-dots li {
  width: 0.69444vw;
  height: 0.69444vw;
  border-radius: 0.69444vw;
  background: rgba(155, 127, 98, 0.1);
  transition: .4s all ease;
  margin-right: 0.69444vw;
  margin-left: 0.69444vw; }

.slick-dots {
  width: auto;
  margin-left: -0.69444vw;
  bottom: -2.5vw; }

.slick-dots li.slick-active {
  background: #9B7F62; }

.portfolio-slider-wrapper {
  position: relative; }
  .portfolio-slider-wrapper .troubles-arrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 93.05556vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.portfolio-next {
  transform: scale(-1, 1); }

.header-logo {
  max-width: 14vw;
  margin-right: 8.19444vw; }

.header {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  padding-top: 2.77778vw;
  padding-left: 3.47222vw;
  padding-right: 3.47222vw; }

.header-link {
  color: rgba(62, 73, 83, 0.75);
  font-family: Roboto;
  font-size: 0.97222vw;
  font-weight: 300;
  line-height: 135%;
  /* 18.9px */
  text-decoration-line: underline;
  max-width: 10.90278vw;
  text-decoration-skip-ink: none;
  display: inline-block;
  margin-right: 2.98611vw; }
  .header-link:last-child {
    margin-right: 0; }

.header-number {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.header-number__phone {
  color: #3E4953;
  font-family: "Trajan Pro 3";
  font-size: 1.66667vw;
  font-weight: 400;
  line-height: 125%; }

.header-number {
  margin-right: 3.47222vw; }
  .header-number:last-child {
    margin-right: 0; }

.header-number__name {
  color: rgba(62, 73, 83, 0.75);
  font-size: 0.97222vw;
  font-weight: 200;
  line-height: 125%; }

.header-soc__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.81944vw;
  height: 3.81944vw;
  margin-right: 0.69444vw;
  border-radius: 0.6366vw;
  box-shadow: 0 0.27778vw 0.69444vw 0 rgba(255, 255, 255, 0.25) inset; }
  .header-soc__item:last-child {
    margin-right: 0; }

.soc-wc {
  background: #65BC63; }

.soc-tg {
  background: #68B6C1; }

.header-soc {
  margin-left: -2.08333vw; }

.footer {
  padding-bottom: 2.84722vw; }
  .footer .container {
    max-width: 93.05556vw; }
  .footer .header-logo {
    max-width: 18.95833vw;
    position: relative;
    bottom: 1.18056vw;
    margin-right: 5.34722vw; }
  .footer .header-row {
    align-items: flex-start; }

.footer-bottom {
  padding-top: 2.43056vw;
  margin-top: 2.43056vw;
  border-top: 0.06944vw solid rgba(0, 0, 0, 0.05); }

.footer__legal {
  color: #D0D0D0;
  font-family: Roboto;
  font-size: 1.11111vw;
  font-weight: 400;
  line-height: 1.52778vw;
  text-align: right; }

.footer__copy {
  color: #D0D0D0;
  font-size: 1.11111vw;
  font-weight: 400;
  line-height: 1.52778vw; }
  .footer__copy a {
    color: #9B7F62;
    text-decoration-line: underline; }

.footer-address__name {
  color: #3E4953;
  margin-bottom: 0.69444vw;
  font-family: "Trajan Pro 3";
  font-size: 1.38889vw;
  font-weight: 400;
  line-height: 1.52778vw; }

.footer-address__adress {
  color: #9B7F62;
  font-size: 1.11111vw;
  font-weight: 400;
  line-height: 1.52778vw;
  /* 137.5% */ }

.footer-address {
  position: relative;
  top: 0.13889vw; }

.modal-window {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  padding-top: 3.125vw;
  padding-bottom: 3.125vw;
  transition: .4s all ease;
  opacity: 0;
  visibility: hidden; }
  .modal-window .inner {
    margin-top: auto;
    margin-bottom: auto;
    height: max-content;
    position: relative; }

.modal-window.active {
  opacity: 1;
  visibility: visible; }

.modal-window__close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.125vw;
  height: 3.125vw;
  right: 2.43056vw;
  top: 2.43056vw;
  border-radius: 0.55556vw;
  border: 0.03125vw solid #628D9B;
  background: #F6F7F8;
  box-shadow: 0 0.27778vw 0.69444vw 0 rgba(255, 255, 255, 0.25) inset; }

.modal-exit .inner {
  border-radius: 1.38889vw;
  background: #628D9B;
  box-shadow: 0 0.27778vw 4.51389vw 0 rgba(255, 255, 255, 0.25) inset;
  padding: 4.16667vw;
  max-width: 80.55556vw;
  width: 100%; }

.modal-exit__title {
  color: #FFF;
  font-family: "Trajan Pro 3";
  font-size: 2.43056vw;
  font-weight: 400;
  line-height: 120%;
  /* 42px */
  letter-spacing: -0.07292vw;
  margin-bottom: 1.45833vw; }

.exit-photo {
  width: 31.94444vw;
  height: 26.18056vw;
  border-radius: 1.04167vw;
  background-size: 101%;
  margin-right: 4.72222vw; }

.exit-row {
  justify-content: flex-start; }

.modal-exit__name {
  color: #FFF;
  font-family: Roboto;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.38889vw; }

.modal-exit-list {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1.11111vw;
  font-weight: 400;
  max-width: 35.55556vw; }
  .modal-exit-list li {
    margin-bottom: 1.04167vw;
    position: relative;
    padding-left: 1.80556vw; }
    .modal-exit-list li::after {
      width: 0.76389vw;
      height: 0.625vw;
      background-size: cover;
      background-position: center;
      content: '';
      display: block;
      background-position: center;
      background-image: url("../img/icons/check-white.svg");
      position: absolute;
      top: 0.27778vw;
      left: 0; }
    .modal-exit-list li:last-child {
      margin-bottom: 0; }

.modal-exit__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.04167vw;
  background: #9B7F62;
  box-shadow: 0 0.27778vw 2.43056vw 0 rgba(255, 255, 255, 0.25) inset;
  width: 25vw;
  height: 5.90278vw;
  margin-top: 1.38889vw;
  color: #FFF;
  text-align: center;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 1.38889vw; }
  .modal-exit__btn img {
    margin-left: 0.69444vw; }

.exit-photo__play {
  width: 6.25vw;
  height: 6.25vw;
  border-radius: 50%;
  background: #FFFFFF; }

.modal-start .inner {
  max-width: 80.55556vw;
  padding: 1.38889vw;
  width: 100%;
  border-radius: 1.38889vw;
  background: #FFF; }

.start {
  border-radius: 1.04167vw;
  padding: 4.16667vw; }

.start__title {
  font-family: "Trajan Pro 3";
  font-size: 2.77778vw;
  font-weight: 400;
  line-height: 120%;
  /* 48px */
  letter-spacing: -0.08333vw;
  margin-bottom: 1.73611vw; }

.start-text {
  color: #FFF;
  font-size: 1.04167vw;
  font-weight: 400;
  line-height: 125%;
  max-width: 33.33333vw;
  margin-bottom: 2.77778vw; }
  .start-text p {
    margin-bottom: 1.59722vw; }
    .start-text p:last-child {
      margin-bottom: 0; }

.start-block {
  border-radius: 0.69444vw;
  background: #628D9B;
  box-shadow: 0 0.27778vw 3.81944vw 0 rgba(255, 255, 255, 0.25) inset;
  max-width: 33.33333vw;
  width: 100%;
  padding: 2.08333vw 2.77778vw;
  position: relative; }
  .start-block::after {
    position: absolute;
    width: 4.09722vw;
    height: 3.81944vw;
    background-size: cover;
    background-position: center;
    background-image: url("../img/icons/start-quote.svg");
    content: '';
    display: block;
    top: -1.38889vw;
    right: 1.38889vw; }

.start-block__text {
  color: #FFF;
  margin-bottom: 1.38889vw;
  font-size: 0.97222vw;
  font-weight: 400;
  line-height: 125%; }
  .start-block__text strong {
    font-weight: 500; }

.start-number__phone {
  color: #FFF;
  font-family: "Trajan Pro 3";
  font-size: 1.38889vw;
  font-weight: 400;
  line-height: 125%; }

.start-number__name {
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.90278vw;
  font-weight: 200;
  line-height: 125%; }

.start-number {
  margin-right: 2.77778vw;
  position: relative; }
  .start-number:last-child {
    margin-right: 0; }
  .start-number:first-child::after {
    position: absolute;
    width: 0.69444vw;
    height: 1.04167vw;
    background-size: cover;
    background-position: center;
    background-image: url("../img/icons/start-sim.svg");
    top: 0.34722vw;
    right: -1.73611vw;
    content: '';
    display: block; }

.start {
  position: relative; }
  .start .hello__fio {
    position: absolute;
    top: 19.79167vw;
    right: 4.58333vw;
    font-size: 0.83333vw; }

.webinar {
  max-width: 100%;
  width: 100%;
  padding-top: 5.55556vw;
  padding-left: 5.55556vw;
  padding-bottom: 4.16667vw; }
  .webinar .modal-exit__title {
    color: #3E4953;
    font-family: "Trajan Pro 3";
    font-size: 2.77778vw;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.08333vw; }
  .webinar .modal-exit__name {
    color: #3E4953;
    font-size: 1.25vw;
    font-weight: 600;
    line-height: normal; }
  .webinar .modal-exit-list {
    color: #3E4953; }
  .webinar .modal-exit__btn {
    background: #628D9B; }
  .webinar .modal-exit-list li::after {
    background-image: url("../img/icons/check-blue.svg"); }

.webinar__pretitle {
  color: #3E4953;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 1.73611vw; }

.modal-exit__title {
  margin-bottom: 3.47222vw; }

.modal-keys .inner {
  border-radius: 1.38889vw;
  background: #FFF;
  max-width: 80.55556vw;
  width: 100%; }

.modal-keys .webinar {
  padding-left: 4.16667vw; }

.keys {
  padding-left: 4.16667vw;
  padding-top: 4.16667vw;
  padding-right: 4.16667vw; }
  .keys .section-title {
    font-size: 2.77778vw;
    line-height: 120%;
    letter-spacing: -0.08333vw;
    margin-bottom: 1.73611vw; }

.keys-text {
  color: #3E4953;
  margin-top: 1.73611vw;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 125%; }
  .keys-text strong {
    font-weight: 600; }
  .keys-text p {
    margin-bottom: 1.73611vw; }
    .keys-text p:last-child {
      margin-bottom: 0; }

.keys-slide__photo {
  height: 10.41667vw;
  width: 100%;
  border-radius: 0.69444vw;
  margin-bottom: 1.04167vw; }

.keys-block {
  max-width: 17.29167vw;
  width: 100%;
  margin-bottom: 1.73611vw; }

.keys-slide__title {
  color: #3E4953;
  font-family: Roboto;
  font-size: 1.04167vw;
  font-weight: 300;
  line-height: 125%; }

.keys-slide {
  margin-bottom: -1.73611vw; }

.keys-slider-wrapper {
  margin-top: 2.15278vw;
  position: relative; }
  .keys-slider-wrapper .troubles-arrows {
    width: 11.11111vw;
    justify-content: space-between;
    right: 0px;
    top: -6.94444vw;
    transform: none;
    bottom: unset; }
  .keys-slider-wrapper .troubles-arrow.slick-disabled {
    background: #F0F2F4; }
  .keys-slider-wrapper .slick-dots {
    width: 100%;
    bottom: -3.26389vw; }
  .keys-slider-wrapper .slick-dots li {
    width: 0.48611vw;
    height: 0.48611vw;
    margin-right: vm(7);
    margin-left: vm(7); }
  .keys-slider-wrapper .slick-dots li.slick-active {
    background: #628D9B; }

.keys-next {
  transform: scale(-1, 1); }

.modal-keys .modal-window__close {
  border-radius: 0.55556vw;
  background: #F6F7F8;
  box-shadow: 0px 0.27778vw 0.69444vw 0px rgba(255, 255, 255, 0.25) inset;
  border: none; }

.keys-slide__play {
  width: 4.86111vw;
  height: 4.86111vw;
  border-radius: 50%;
  background: #FFFFFF; }

.keys-slide {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%; }

.modal-start .modal-window__close {
  border: none; }

.care-subtitle__bottom img {
  max-width: 1.04167vw; }

.header-soc__item img {
  max-width: 1.52778vw; }

.form-label__img {
  max-width: 1.11111vw; }

.deeper-block__play img {
  max-width: 0.90278vw; }

.cooperation__play img {
  max-width: 1.25vw; }

.troubles-arrow svg {
  max-width: 0.625vw;
  width: 100%; }

.latent-happening img {
  max-width: 0.76389vw; }

.latent__zoom img {
  max-width: 1.04167vw; }

.every-slide__link img {
  max-width: 1.14583vw; }

.portfolio__subtitle img {
  max-width: 2.22222vw; }

.portfolio-block__zoom img {
  max-width: 1.04167vw; }

.reviews-slider__arrow img {
  max-width: 0.69444vw; }

.first-screen-term img {
  max-width: 0.76389vw; }

.first-screen-term {
  white-space: nowrap; }

.reviews-slide__play img {
  max-width: 0.76389vw; }

.reviews-slider__arrow {
  white-space: nowrap; }

.hello__signature {
  max-width: 11.11111vw; }

.hello__quote {
  max-width: 6.38889vw; }

.first-screen-count__img:nth-child(1) {
  max-width: 4.51389vw; }

.first-screen-count__img:nth-child(3) {
  max-width: 4.65278vw; }

.first-screen-count__img:nth-child(5) {
  max-width: 10.41667vw; }

svg path {
  width: 100%; }

.court-photo__play img {
  max-width: 0.97222vw; }

.overflow {
  overflow: hidden; }

.keys-slide__play img {
  max-width: 0.76389vw; }

.modal-exit__btn img {
  max-width: 1.31944vw; }

.blick {
  background-image: linear-gradient(-45deg, transparent 0%, transparent 49%, #fff 50%, transparent 70%) !important;
  background-repeat: no-repeat !important;
  -webkit-animation: blick-slide infinite 3s;
  animation: blick-slide infinite 3s; }
  .blick .spn-txt {
    position: relative;
    z-index: 2; }

@keyframes blick-slide {
  0% {
    background-position-x: -20vw; }
  100% {
    background-position-x: calc(100% + 20vw); } }

.form-btn {
  -webkit-animation: blick-slide-btn infinite 4s;
  animation: blick-slide-btn infinite 4s; }

@keyframes blick-slide-btn {
  0% {
    background-position-x: -30vw; }
  100% {
    background-position-x: calc(100% + 30vw); } }

.first-line .slide {
  white-space: nowrap; }

.form-input {
  border: 0.06944vw solid #F6F7F8;
  transition: .4s all ease; }

.form-input.error {
  border-color: red; }

.every-slide__text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.keys-slide__title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.thanks {
  margin-bottom: 6.94444vw; }
  .thanks .content {
    padding-top: 12.01389vw;
    padding-bottom: 6.25vw;
    min-height: 48.61111vw; }

.deeper--thanks .section-title {
  font-size: 2.77778vw;
  line-height: 120%;
  letter-spacing: -0.08333vw; }

.thanks__title {
  color: #FFF;
  font-family: "Trajan Pro 3";
  font-size: 3.125vw;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.09375vw;
  margin-bottom: 9.51389vw; }
  .thanks__title span {
    color: #F8E7D6; }

.thanks-text {
  color: #FFF;
  max-width: 31.94444vw;
  font-family: Roboto;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; }
  .thanks-text strong {
    font-weight: 500; }
  .thanks-text p {
    margin-bottom: 1.875vw; }
    .thanks-text p:last-child {
      margin-bottom: 0; }

.header--thanks .header-number__phone {
  color: #FFF; }

.header--thanks .header-number__name {
  color: rgba(255, 255, 255, 0.75); }

.header--thanks .header-link {
  color: #F8E7D6; }

.thanks-text {
  position: relative; }
  .thanks-text::after {
    position: absolute;
    width: 6.38889vw;
    height: 5.90278vw;
    background-size: cover;
    background-position: center;
    content: '';
    display: block;
    top: -2.08333vw;
    right: -2.77778vw;
    background-image: url("../img/icons/thanks-quote.svg"); }

.exit-photo__play img {
  max-width: 1.14583vw; }

.webinar--section {
  padding-right: 0;
  padding-left: 0;
  padding-top: 6.94444vw;
  padding-bottom: 8.33333vw; }
  .webinar--section .exit-photo {
    width: 41.66667vw;
    height: 28.05556vw; }
  .webinar--section .modal-exit-list {
    font-size: 1.25vw; }
  .webinar--section .webinar__pretitle {
    font-size: 1.38889vw;
    margin-bottom: 1.04167vw; }

.deeper--thanks {
  padding-bottom: 0; }

.thanks .content {
  position: relative; }
  .thanks .content .hello__fio {
    position: absolute;
    right: 10vw;
    top: 24vw; }

.selectize-dropdown-content {
  overflow-y: scroll; }

.selectize-dropdown-content::-webkit-scrollbar {
  width: 0.69444vw;
  /* Ширина всего элемента навигации */ }

.selectize-dropdown-content::-webkit-scrollbar-track {
  background: #fff;
  /* Цвет дорожки */ }

.selectize-dropdown-content::-webkit-scrollbar-thumb {
  background-color: #9B7F62;
  /* Цвет бегунка */
  border-radius: 0px;
  /* Округление бегунка */
  border: none; }

.reviews-slide__play, .deeper-block__play, .cooperation__play, .court-photo__play, .latent__zoom, .every-slide__link, .portfolio-block__zoom, .keys-slide__play, .exit-photo__play {
  transition: .4s all ease; }
  .reviews-slide__play:hover, .deeper-block__play:hover, .cooperation__play:hover, .court-photo__play:hover, .latent__zoom:hover, .every-slide__link:hover, .portfolio-block__zoom:hover, .keys-slide__play:hover, .exit-photo__play:hover {
    transform: scale(1.1); }

.troubles-arrow {
  transition: .4s all ease; }
  .troubles-arrow:hover {
    opacity: .85; }

.form-btn {
  transition: .4s all ease; }
  .form-btn:hover {
    box-shadow: unset; }

.first-line__btn {
  transition: .4s all ease; }
  .first-line__btn:hover {
    opacity: .9; }

.header-soc__item {
  transition: .4s all ease; }
  .header-soc__item:hover {
    opacity: .75; }

.footer__copy a {
  transition: .4s all ease; }
  .footer__copy a:hover {
    text-decoration: unset;
    color: #9B7F62; }

.header-number__phone {
  transition: .4s all ease; }
  .header-number__phone:hover {
    color: #9B7F62; }

.header-link {
  transition: .4s all ease; }
  .header-link:hover {
    text-decoration: unset; }

.deeper-block__play:hover {
  transform: translate(-50%, -50%) scale(1.1); }

.modal-exit__btn {
  transition: .4s all ease; }
  .modal-exit__btn:hover {
    box-shadow: unset;
    color: #FFF; }

.modal-video .inner {
  width: 1100px;
  height: 600px; }
  .modal-video .inner iframe {
    width: 100%;
    height: 100%; }

.reviews-slide__play, .every-slide__link, .deeper-block__play, .cooperation__play, .court-photo__play, .keys-slide__play, .exit-photo__play {
  cursor: pointer; }

@media screen and (min-width: 680px) {
  .mob-true {
    display: none !important; }
  .care-subtitle__bottom strong {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap; }
  .care-subtitle__bottom img {
    flex-shrink: 0; } }

@media screen and (max-width: 680px) {
  .pc-true {
    display: none !important; }
  .container {
    margin-right: auto;
    margin-left: auto;
    max-width: 74.66667vw;
    width: 100%; }
  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .flex-a {
    display: flex;
    align-items: center; }
  .flex-t {
    display: flex;
    align-items: flex-start; }
  .flex-b {
    display: flex;
    align-items: flex-end; }
  .flex-c {
    display: flex;
    align-items: center;
    justify-content: center; }
  .photo-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .cicle {
    border-radius: 50%; }
  .title {
    font-family: 'Trajan Pro 3'; }
    .title span {
      color: #9B7F62; }
  .first-screen .content {
    padding-top: 24vw;
    padding-bottom: 8vw;
    border-radius: 5.33333vw; }
  .section-padding {
    padding: 0vw; }
    .section-padding .content {
      border-radius: 5.33333vw; }
  .first-screen .content {
    border-radius: 0 0 5.33333vw 5.33333vw; }
  .first-screen__title {
    margin-bottom: 4vw;
    font-size: 8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 48px */
    letter-spacing: -0.32vw; }
  .first-screen-terms {
    flex-direction: column;
    align-items: flex-start; }
  .first-screen-term {
    border-radius: 1.6vw;
    background: #FFF;
    display: flex;
    align-items: center;
    color: #3E4953;
    padding: 1.86667vw 2.66667vw;
    font-family: "Wix Madefor Display";
    font-size: 2.66667vw;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
    /* 13.75px */
    letter-spacing: 0.14667vw;
    text-transform: uppercase;
    margin-bottom: 1.33333vw;
    margin-right: 0vw;
    display: inline-flex; }
    .first-screen-term img {
      margin-right: 1.33333vw;
      max-width: 2.66667vw; }
    .first-screen-term:last-child {
      margin-bottom: 0; }
  .first-screen-count {
    margin-top: 6.66667vw;
    flex-direction: column;
    align-items: flex-start; }
    .first-screen-count .wrapper {
      display: flex;
      align-items: center;
      border-radius: 1.9976vw;
      background: #FFF;
      backdrop-filter: blur(4.48vw);
      justify-content: center;
      width: 74.93333vw;
      height: 9.97333vw; }
    .first-screen-count span {
      color: rgba(155, 127, 98, 0.25);
      margin-right: 1.944vw;
      margin-left: 1.944vw;
      font-size: 2.19733vw;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: 0.112vw;
      text-transform: uppercase; }
  .first-screen-count__title {
    color: #3E4953;
    margin-right: 0vw;
    font-size: 4.53333vw;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 1.40533vw; }
  .reviews-slider-wrapper {
    display: flex;
    align-items: center;
    width: 87.2vw;
    max-width: 87.2vw;
    margin-right: -5.33333vw;
    margin-left: 0vw;
    border-radius: 3.17947vw;
    background: #FFF;
    padding-top: 4.26667vw;
    padding-bottom: 4.71733vw;
    margin-top: 93.6vw;
    padding-left: 4.26667vw;
    position: relative; }
  .reviews-slide {
    width: 49.33333vw;
    margin-right: 1.06667vw;
    margin-left: 1.06667vw; }
    .reviews-slide .photo-bg {
      width: 100%;
      height: 21.33333vw;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: grey;
      border-radius: 2.13333vw;
      margin-bottom: 3.2vw; }
    .reviews-slide .reviews-slide__text {
      color: #3E4953;
      font-family: Roboto;
      font-size: 3.2vw;
      font-weight: 300;
      line-height: 125%; }
  .reviews-slider {
    width: 100%;
    max-width: 256vw;
    margin-right: -1.06667vw;
    margin-left: -1.06667vw; }
  .reviews-slide__play {
    border-radius: 13.33333vw;
    background: #FFF;
    width: 10.66667vw;
    height: 10.66667vw;
    display: flex;
    align-items: center;
    justify-content: center; }
  .first-line {
    border-radius: 2.13333vw;
    bottom: 0px;
    background: #FFF;
    backdrop-filter: blur(4.66667vw);
    padding-top: 2.66667vw;
    padding-bottom: 0;
    height: 10.4vw;
    overflow: hidden; }
    .first-line .slide {
      color: #3E4953;
      font-family: Roboto;
      font-size: 4vw;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: -0.12vw; }
      .first-line .slide span {
        color: #9B7F62; }
  .first-screen {
    position: relative; }
  .first-line-wrapper {
    position: absolute;
    margin-top: 0;
    left: 20px;
    bottom: 284px; }
  .first-line__btn {
    border-radius: 2.66667vw;
    background: #3E4953;
    width: 74.66667vw;
    height: 16vw;
    background: #9B7F62;
    box-shadow: 0px 1.06667vw 6.66667vw 0px rgba(255, 255, 255, 0.35) inset;
    color: #F8FBFF;
    font-size: 4.26667vw;
    font-weight: 500;
    line-height: 5.86667vw;
    /* 138.889% */
    letter-spacing: -0.128vw;
    right: unset;
    top: unset;
    bottom: -18.66667vw;
    position: absolute;
    left: 7.2vw;
    transform: unset; }
    .first-line__btn:hover {
      color: #F8FBFF; }
  .first-screen .content {
    background-image: url("../img/bg/first-screen-mob.jpg") !important; }
  .hello .content {
    background-image: url("../img/bg/hello-mob.jpg") !important; }
  .hello {
    padding: 2.66667vw; }
    .hello .container {
      padding-left: 0vw; }
    .hello .content {
      border-radius: 5.33333vw;
      padding-top: 13.33333vw;
      padding-bottom: 38.66667vw; }
  .hello__title {
    color: #F6F7F8;
    font-size: 9.33333vw;
    font-weight: 600;
    line-height: 9.33333vw;
    letter-spacing: -0.4vw;
    margin-bottom: 6.66667vw; }
  .hello-text {
    color: #F6F7F8;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 36.53333vw; }
    .hello-text p {
      margin-bottom: 5.33333vw; }
      .hello-text p:last-child {
        margin-bottom: 0; }
  .hello-wrapper {
    margin-left: 0vw; }
  .hello__fio {
    color: rgba(246, 247, 248, 0.65);
    font-size: 3.46667vw;
    font-weight: 400;
    line-height: 125%; }
    .hello__fio strong {
      color: #F6F7F8;
      font-weight: 700; }
  .hello-wrapper {
    position: relative; }
  .hello-text strong {
    font-weight: 700; }
  .hello__quote {
    display: none; }
  .hello__signature {
    display: none; }
  .first-screen {
    padding-bottom: 0; }
  .section-title {
    color: #444;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: rgba(155, 127, 98, 0.55);
    font-family: "Trajan Pro 3";
    font-size: 7.2vw;
    font-weight: 400;
    line-height: 120%;
    /* 54px */
    letter-spacing: -0.36vw; }
  .portfolio {
    padding-top: 10.66667vw; }
    .portfolio .section-title {
      margin-bottom: 2.66667vw; }
  body .sheepskin {
    margin-bottom: 5.33333vw; }
  .sum {
    padding-left: 0vw;
    padding-right: 0vw; }
    .sum .content {
      border-radius: 6.66667vw;
      padding-top: 13.33333vw;
      padding-bottom: 89.86667vw;
      padding-left: 12.8vw;
      padding-right: 12.53333vw;
      min-height: 188vw;
      background-image: url("../img/bg/sum-mob.jpg") !important; }
    .sum .section-title {
      margin-bottom: 5.33333vw; }
  .section-title span {
    color: #9B7F62; }
  .sum__text {
    color: #3E4953;
    max-width: 106.66667vw;
    font-family: Roboto;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%; }
    .sum__text p {
      margin-bottom: 6.66667vw; }
      .sum__text p:last-child {
        margin-bottom: 0; }
    .sum__text strong {
      font-weight: 500; }
  .big-block {
    padding-right: 2.66667vw;
    padding-left: 2.66667vw; }
    .big-block .content {
      border-radius: 5.33333vw; }
  .section-title--center {
    text-align: center; }
  .big-block .content {
    padding-top: 24vw;
    padding-bottom: 24vw; }
  .care {
    margin-top: 2.66667vw; }
    .care .content {
      background: #3C464F;
      padding-top: 13.33333vw;
      padding-bottom: 12.26667vw; }
    .care .section-title {
      font-size: 7.2vw;
      line-height: 120%;
      /* 66px */
      letter-spacing: -0.216vw;
      margin-bottom: 5.33333vw;
      text-align: left; }
    .care .arrow-left {
      transform: scale(-1, 1); }
  .white {
    color: #FFF; }
    .white .section-title {
      color: #FFF; }
  .care-subtitle {
    margin-right: auto;
    margin-left: auto;
    max-width: 151.2vw;
    text-align: left;
    font-size: 3.73333vw;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; }
    .care-subtitle img {
      display: none; }
  .care-subtitle__bottom {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    justify-content: flex-start;
    margin-top: 5.33333vw;
    font-weight: 500; }
    .care-subtitle__bottom img {
      display: none; }
    .care-subtitle__bottom .arrow-left {
      margin-right: 0;
      margin-left: 4vw; }
    .care-subtitle__bottom strong {
      font-weight: 500; }
  .care-block {
    width: 100%;
    border-radius: 2.66667vw;
    background: #628D9B;
    box-shadow: 0 1.06667vw 10.66667vw 0 rgba(255, 255, 255, 0.25) inset;
    max-width: 74.66667vw;
    min-width: 74.66667vw;
    margin-right: 4vw;
    padding: 8vw 5.33333vw; }
    .care-block .wrapper {
      padding-left: 2.66667vw; }
  .care-block__title {
    color: #FFF;
    font-family: Roboto;
    font-size: 4.8vw;
    font-weight: 700;
    line-height: 125%;
    margin-bottom: 4vw; }
  .care-block__text {
    color: rgba(255, 255, 255, 0.75);
    font-family: Roboto;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    min-height: 33.6vw;
    margin-bottom: 5.33333vw; }
    .care-block__text p {
      margin-bottom: 5.33333vw; }
      .care-block__text p:last-child {
        margin-bottom: 0; }
  .care-block__photo {
    width: 100%;
    border-radius: 2.66667vw;
    background-color: grey;
    height: 40vw;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 2.66667vw;
    padding-bottom: 2.66667vw; }
  .care-row {
    margin-top: 8vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-right: -10vw;
    padding-right: 5.33333vw; }
    .care-row .section-title span {
      color: #E3CDB5; }
  .care-block__loop {
    border-radius: 2.66667vw;
    background: #FFFFFF;
    width: 12vw;
    height: 12vw;
    display: flex;
    align-items: center;
    justify-content: center; }
  .care-block--gray {
    background: #9B7F62;
    box-shadow: 0 1.06667vw 10.66667vw 0 rgba(255, 255, 255, 0.25) inset; }
    .care-block--gray .care-block__loop {
      background: rgba(155, 127, 98, 0.2); }
  .questions {
    padding-top: 13.33333vw;
    padding-bottom: 13.33333vw; }
  .questions-row {
    margin-top: 8vw;
    margin-bottom: -2.66667vw;
    align-items: flex-start; }
  .questions-block {
    border-radius: 2.66667vw;
    background: #FFF;
    padding-left: 6.66667vw;
    padding-right: 6.66667vw;
    width: 88vw;
    max-width: unset;
    cursor: pointer;
    margin-bottom: 2.66667vw;
    margin-left: -6vw;
    margin-right: -6vw; }
    .questions-block .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 5.86667vw;
      padding-bottom: 5.86667vw;
      transition: .4s all ease;
      min-height: 21.33333vw; }
    .questions-block .hidden {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); }
    .questions-block .content {
      padding-bottom: 6.66667vw;
      padding-top: 5.33333vw;
      border-top: 0.26667vw solid rgba(0, 0, 0, 0.05);
      color: rgba(62, 73, 83, 0.75);
      font-size: 3.73333vw;
      font-weight: 400;
      line-height: 125%; }
      .questions-block .content p {
        min-height: 21.33333vw; }
      .questions-block .content a {
        color: #9B7F62;
        text-decoration-line: underline;
        text-decoration-skip-ink: none; }
  .questions-block .content br {
    display: none; }
  .questions-block .content br.mob-true {
    display: block; }
  .questions-block .content p {
    min-height: unset; }
  .questions-block__arrow {
    max-width: 3.2vw; }
  .questions-block__title {
    color: #3E4953;
    font-size: 4vw;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.12vw; }
  .questions-block__arrow {
    transition: .4s all ease; }
  .questions-block.opened .top {
    padding-bottom: 5.33333vw; }
  .questions-block.opened .hidden {
    max-height: 533.33333vw;
    transition: max-height 1s ease-in-out; }
  .questions-block.opened .questions-block__arrow {
    transform: scale(1, -1); }
  .deeper-block {
    max-width: 99.73333vw;
    width: 100%;
    padding-left: 7.05867vw;
    padding-top: 7.05867vw;
    height: 58.66667vw;
    position: relative;
    border-radius: 5.86667vw;
    margin-bottom: 2.776vw; }
    .deeper-block:last-child {
      margin-bottom: 0; }
  .deeper {
    padding-bottom: 13.33333vw; }
    .deeper .section-title {
      margin-bottom: 6.66667vw; }
  .deeper-row {
    margin-right: -6.66667vw;
    margin-left: -6.66667vw; }
  .deeper-block__play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16.47067vw;
    height: 16.47067vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff; }
  .deeper-block__title {
    color: #FFF;
    font-family: "Gotham Pro";
    font-size: 3.29413vw;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.16vw;
    text-transform: uppercase;
    margin-bottom: 3.62667vw; }
    .deeper-block__title strong {
      color: #E3CDB6; }
  .lose {
    margin-bottom: 0vw; }
    .lose .content {
      border-radius: 5.33333vw;
      box-shadow: 0 1.06667vw 18.66667vw 0 rgba(255, 255, 255, 0.2) inset;
      padding-top: 13.33333vw;
      padding-bottom: 7.46667vw;
      background-image: url("../img/bg/lose-bg-mob.jpg") !important; }
    .lose .widget {
      margin-top: 4vw;
      background: #9B7F62; }
  .section-title--big {
    font-size: 9.33333vw;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.28vw; }
  .lose__subtitle {
    margin-top: 3.2vw;
    margin-bottom: 26.66667vw;
    color: rgba(255, 255, 255, 0.8);
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.144vw;
    max-width: 45.33333vw; }
    .lose__subtitle strong {
      font-weight: 500; }
    .lose__subtitle .lose__fio {
      color: rgba(255, 255, 255, 0.4);
      font-family: Roboto;
      font-size: 3.2vw;
      font-weight: 400;
      line-height: 125%;
      position: static;
      margin-top: 2.66667vw; }
      .lose__subtitle .lose__fio strong {
        color: rgba(255, 255, 255, 0.6); }
  .section-form {
    border-radius: 2.66667vw;
    background: #FFF;
    padding: 2.66667vw;
    display: flex;
    align-items: center;
    justify-content: unset;
    flex-direction: column; }
  .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4vw;
    background: #2A3138;
    box-shadow: 0 1.06667vw 4vw 0 rgba(255, 255, 255, 0.15) inset;
    width: 100%;
    height: 18.66667vw;
    color: #FFF;
    border: none;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-size: 4.26667vw;
    font-weight: 700;
    line-height: 5.33333vw; }
  .form-input {
    border-radius: 2.66667vw;
    background: #F6F7F8;
    width: 100%;
    height: 18.66667vw;
    padding-left: 14.4vw;
    border: none;
    font-family: Roboto;
    color: #3e4953;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 5.86667vw; }
    .form-input::placeholder {
      font-family: Roboto;
      color: rgba(62, 73, 83, 0.55); }
  .form-label {
    position: relative; }
  .form-label__img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6.66667vw; }
  .form-label {
    width: 100%;
    margin-bottom: 1.6vw; }
  .selectize-control {
    width: 100%;
    margin-bottom: 1.6vw; }
  .selectize-input {
    border-radius: 4vw;
    background: #F6F7F8;
    width: 100%;
    height: 18.66667vw;
    border: none !important;
    box-shadow: none !important;
    border-radius: 2.66667vw;
    background: #F6F7F8 !important;
    padding-left: 6.8vw;
    color: rgba(62, 73, 83, 0.55);
    transition: .4s all ease;
    font-family: Roboto;
    font-size: 3.73333vw;
    font-style: normal;
    font-weight: 400;
    line-height: 5.86667vw;
    /* 129.412% */
    letter-spacing: -0.136vw;
    display: flex !important;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0; }
  .section-form__polcy {
    margin-top: 2.66667vw;
    color: rgba(255, 255, 255, 0.45);
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.112vw;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 62.66667vw; }
    .section-form__polcy a {
      color: #FEEBD6;
      text-decoration: underline;
      text-decoration-skip-ink: none; }
  .selectize-control.single .selectize-input:after {
    width: 3.2vw;
    height: 1.86667vw;
    background-image: url("../img/icons/select-arrow.svg");
    padding: 0vw;
    border: none;
    background-size: cover;
    right: 8vw;
    transition: .4s all ease; }
  .selectize-control.single .selectize-input.dropdown-active:after {
    margin-top: -0.8vw;
    transform: scale(1, -1); }
  .selectize-dropdown .create, .selectize-dropdown .no-results, .selectize-dropdown .optgroup-header, .selectize-dropdown .option {
    padding-top: 2.13333vw;
    padding-bottom: 2.13333vw;
    padding-left: 6.8vw;
    background: #F6F7F8;
    color: rgba(62, 73, 83, 0.55);
    transition: .4s all ease;
    font-family: Roboto;
    font-size: 3.73333vw;
    font-style: normal;
    font-weight: 400;
    line-height: 5.86667vw;
    /* 129.412% */
    letter-spacing: -0.136vw; }
  .selectize-dropdown {
    border: none; }
  .lose .container {
    position: relative; }
  .lose__fio {
    position: absolute;
    color: rgba(246, 247, 248, 0.65);
    font-size: 4.26667vw;
    font-weight: 400;
    line-height: 125%;
    right: 13.06667vw;
    bottom: 56vw; }
    .lose__fio strong {
      color: #F6F7F8;
      font-weight: 700; }
  .price {
    margin-bottom: 14.13333vw;
    margin-top: 32vw; }
    .price .content {
      padding-top: 13.33333vw;
      padding-bottom: 7.46667vw; }
    .price .container {
      position: relative; }
    .price .form-btn {
      background: #9B7F62;
      box-shadow: 0 1.06667vw 4vw 0 rgba(255, 255, 255, 0.35) inset; }
    .price .section-form__polcy {
      color: rgba(255, 255, 255, 0.55); }
      .price .section-form__polcy a {
        color: #1A96DD; }
  .price .content {
    background-image: url("../img/bg/price-bg.jpg") !important; }
  .price-name {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 9.33333vw;
    margin-bottom: 26.66667vw;
    color: rgba(255, 255, 255, 0.9);
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.112vw;
    padding-left: 29.33333vw; }
    .price-name .cicle {
      display: none; }
  .price-fio__text {
    color: rgba(255, 255, 255, 0.65);
    font-size: 4.26667vw;
    font-weight: 400;
    line-height: 125%;
    position: relative; }
    .price-fio__text::after {
      width: 2.66667vw;
      height: 2.66667vw;
      content: '';
      display: block;
      background-size: cover;
      background-image: url("../img/price/line.svg");
      position: absolute;
      left: 0vw;
      top: -6.66667vw; }
    .price-fio__text strong {
      color: #FFF;
      font-weight: 500; }
  .price .price-fio__text::after {
    display: none; }
  .price .price-fio__text {
    color: rgba(255, 255, 255, 0.4);
    margin-top: 10px;
    font-family: Roboto;
    font-size: 3.2vw;
    font-weight: 400;
    line-height: 125%; }
    .price .price-fio__text strong {
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6); }
  .block-small {
    padding-right: 13.33333vw;
    padding-left: 13.33333vw; }
    .block-small .content {
      border-radius: 5.33333vw; }
  .latent-happening {
    display: flex;
    align-items: center; }
  .latent {
    padding-bottom: 2.66667vw;
    margin-bottom: 2.66667vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw; }
    .latent .content {
      padding-top: 13.33333vw;
      padding-bottom: 66.4vw; }
    .latent .section-title {
      color: #F5F5F5; }
      .latent .section-title span {
        color: #E3CDB5; }
  .latent__text {
    max-width: 74.66667vw;
    font-size: 3.73333vw;
    margin-top: 4vw;
    font-weight: 300;
    line-height: 125%; }
    .latent__text p {
      margin-bottom: 5.33333vw; }
      .latent__text p:last-child {
        margin-bottom: 0; }
  .latent-happening {
    display: flex;
    align-items: unset;
    border-radius: 0%;
    background: unset;
    background-image: url("../img/icons/latent-cicle.svg");
    width: 32vw;
    height: 32vw;
    padding-left: 5.86667vw;
    padding-top: 9.06667vw;
    justify-content: flex-start;
    margin-bottom: 0vw;
    color: #FFF;
    font-family: "Trajan Pro 3";
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.112vw;
    text-transform: uppercase;
    position: absolute;
    top: -22.66667vw;
    right: -3.33333vw; }
    .latent-happening img {
      display: none; }
    .latent-happening span {
      position: relative;
      top: 0vw; }
  .latent .content {
    background-image: url("../img/bg/latent-mob.jpg") !important; }
  .latent .container {
    position: relative; }
  .latent__zoom {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 12vw;
    height: 12vw;
    background: #FFF;
    bottom: -44.66667vw;
    right: 1vw; }
  .sheepskin .content::after {
    display: none !important; }
  .sheepskin {
    padding-right: 2.66667vw;
    padding-left: 2.66667vw; }
    .sheepskin .content {
      padding-bottom: 10.4vw;
      padding-top: 13.33333vw;
      background-image: url("../img/bg/sheepskin-mob.jpg") !important; }
    .sheepskin .section-form {
      width: 80vw;
      margin-right: -2.66667vw;
      margin-left: -2.66667vw; }
    .sheepskin .widget {
      width: 80vw;
      margin-right: -2.66667vw;
      margin-left: -2.66667vw;
      margin-top: 4vw;
      border-radius: 2.66667vw;
      background: #9B7F62;
      box-shadow: 0px 1.06667vw 9.33333vw 0px rgba(255, 255, 255, 0.25) inset; }
    .sheepskin .section-title {
      color: #FFF;
      font-family: "Trajan Pro 3";
      font-size: 7.2vw;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      /* 29.7px */
      letter-spacing: -0.216vw; }
    .sheepskin .price-fio__text {
      color: rgba(255, 255, 255, 0.4);
      font-size: 3.2vw;
      font-weight: 400;
      line-height: 125%;
      margin-top: 2.66667vw; }
      .sheepskin .price-fio__text strong {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6); }
    .sheepskin .price-fio__text::after {
      display: none; }
  .latent .content::after {
    display: none !important; }
  .sheepskin__text {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 8vw;
    margin-bottom: 26.66667vw;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    /* 22.5px */
    letter-spacing: -0.112vw;
    max-width: 45.33333vw; }
  .latent {
    margin-bottom: 0; }
  .sheepskin .container {
    position: relative; }
  .sheepskin .price-fio__text::after {
    background-image: url("../img/icons/line-white.svg"); }
  .sheepskin .price-fio-abc {
    right: 24vw;
    bottom: 61.06667vw; }
  .sheepskin {
    margin-bottom: 13.33333vw; }
    .sheepskin .content {
      position: relative; }
      .sheepskin .content::after {
        position: absolute;
        width: 6.4vw;
        height: 7.46667vw;
        background-size: cover;
        background-position: center;
        background-image: url("../img/icons/polygon-2.svg");
        content: '';
        display: block;
        z-index: 100;
        top: 17.33333vw;
        right: -3.2vw; }
  .latent .content {
    position: relative; }
    .latent .content::after {
      position: absolute;
      width: 6.4vw;
      height: 7.46667vw;
      background-size: cover;
      background-position: center;
      background-image: url("../img/icons/polygon-1.svg");
      content: '';
      display: block;
      z-index: 100;
      top: 17.33333vw;
      left: -3.2vw; }
  .seems {
    padding-bottom: 13.33333vw; }
  .seems-row {
    margin-top: 5.33333vw; }
  .seems-line {
    margin-bottom: 0;
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    border-radius: 2.66667vw;
    background: #628D9B;
    box-shadow: 0px 1.06667vw 6.66667vw 0px rgba(255, 255, 255, 0.25) inset;
    margin-bottom: 4vw; }
    .seems-line:last-child {
      margin-bottom: 0; }
    .seems-line:first-child .seems-name::after {
      top: 6.66667vw; }
  .seems-name {
    background: transparent;
    box-shadow: unset;
    width: 100%;
    max-width: 100%;
    padding-left: 5.33333vw;
    padding-top: 5.33333vw;
    padding-bottom: 4vw;
    color: #FFF;
    position: relative;
    font-size: 4.26667vw;
    font-weight: 500;
    line-height: 125%; }
    .seems-name::after {
      position: absolute;
      top: 9.33333vw;
      transform: rotate(90deg);
      width: 2.66667vw;
      height: 2.66667vw;
      background-size: cover;
      background-position: center;
      background-image: url("../img/icons/seems-arrow.svg");
      content: '';
      display: block;
      right: 4.66667vw;
      opacity: .2; }
  .seems-content {
    border-radius: 2.66667vw;
    background: #FFF;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 100%;
    color: #444;
    font-size: 4vw;
    font-weight: 300;
    line-height: 125%;
    padding: 6.66667vw; }
  .mobile-scroll {
    display: flex;
    align-items: flex-start;
    color: rgba(68, 68, 68, 0.35);
    font-family: Roboto;
    font-size: 3.46667vw;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; }
    .mobile-scroll img {
      max-width: 4vw;
      margin-right: 4vw; }
  .every .mobile-scroll {
    margin-top: 4vw; }
  .every-slider {
    margin-top: 8vw;
    margin-bottom: 12.26667vw;
    margin-left: 0vw;
    margin-right: -12.66667vw; }
  .every-slide {
    width: 74.66667vw;
    margin-right: 2.66667vw;
    margin-left: 2.66667vw; }
    .every-slide .photo-bg {
      height: 40vw;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: grey;
      border-radius: 2.99467vw; }
  .every-slide__text {
    color: #3E4953;
    margin-top: 2.66667vw;
    font-size: 3.73333vw;
    font-weight: 300;
    line-height: 125%; }
  .every-slide__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 16vw;
    height: 16vw;
    background: #FFFFFF; }
  .messag-cicle {
    width: 34.66667vw;
    height: 34.66667vw;
    flex-shrink: 0;
    margin-top: 2.66667vw;
    align-self: flex-end;
    margin-right: 6.66667vw; }
  .every-messag:last-child {
    margin-top: -35.66667vw; }
    .every-messag:last-child .messag-cicle {
      align-self: flex-start;
      margin-bottom: 2.66667vw;
      margin-left: 6.66667vw;
      margin-right: 0; }
  .messag-content {
    max-width: 100%;
    max-width: 240vw;
    padding: 6.66667vw;
    border-radius: 4vw;
    background: #9B7F62; }
  .every-messag {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .every {
    margin-bottom: 13.33333vw; }
  .messag-content__title {
    color: #FFF;
    margin-bottom: 4vw;
    font-size: 4.8vw;
    font-weight: 600;
    line-height: 125%; }
  .messag-content__text {
    color: #FFF;
    font-size: 3.73333vw;
    font-weight: 300;
    line-height: 125%; }
  .messag-content__direktor {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2.66667vw;
    color: rgba(255, 255, 255, 0.55);
    font-size: 3.46667vw;
    font-style: italic;
    font-weight: 400;
    line-height: 125%; }
    .messag-content__direktor strong {
      font-weight: 400;
      color: #FFF; }
  .every-messages {
    width: 88vw;
    margin-right: -6.66667vw;
    margin-left: -6.66667vw; }
  .messag-content {
    position: relative;
    width: 100%; }
    .messag-content::after {
      position: absolute;
      content: '';
      display: block;
      width: 10.93333vw;
      height: 10.13333vw;
      background-size: cover;
      background-position: center;
      background-image: url("../img/every/every-quote.svg");
      top: unset;
      bottom: 2.93333vw;
      right: unset;
      left: 22.93333vw;
      transform: scale(-1, 1); }
    .messag-content::before {
      position: absolute;
      content: '';
      display: block;
      width: 5.86667vw;
      height: 4.26667vw;
      background-size: cover;
      background-position: center;
      background-image: url("../img/icons/poly-1-mob.svg");
      bottom: -3.2vw;
      right: 3.73333vw;
      z-index: -1; }
  .every-messag {
    margin-bottom: 8vw; }
    .every-messag:last-child {
      margin-bottom: 0; }
      .every-messag:last-child .messag-content::after {
        transform: none;
        top: 5.33333vw;
        right: 5.33333vw;
        bottom: unset;
        left: unset; }
      .every-messag:last-child .messag-content::before {
        width: 5.86667vw;
        height: 4.26667vw;
        top: -3.73333vw;
        left: 6.66667vw;
        bottom: unset;
        right: unset;
        background-image: url("../img/icons/poly-2-mob.svg"); }
  .every-counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image: url("../img/icons/cicle-bg.svg");
    width: 50.66667vw;
    height: 50.66667vw;
    position: relative;
    color: #FFF;
    font-family: "Trajan Pro 3";
    font-size: 3.9832vw;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 28.302px */
    letter-spacing: -0.11947vw; }
    .every-counter strong {
      color: #FFF;
      font-family: "Trajan Pro 3";
      font-size: 15.2vw;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.456vw;
      margin-bottom: -2.93333vw;
      display: inline-block; }
    .every-counter p {
      max-width: 30.66667vw; }
  .every .container {
    position: relative; }
  .every-counter-abc {
    position: absolute;
    right: -20.53333vw;
    top: -24vw; }
  .cooperation {
    margin-top: 32vw;
    position: relative;
    z-index: 5; }
    .cooperation .content {
      padding-top: 13.33333vw;
      padding-bottom: 0;
      border-radius: 5.33333vw;
      background: #9B7F62;
      box-shadow: 0 1.06667vw 21.33333vw 0 rgba(255, 255, 255, 0.25) inset; }
    .cooperation .section-title {
      text-align: left; }
  .cooperation-subtitle {
    justify-content: flex-start;
    margin-top: 4vw;
    color: #FFF;
    align-items: flex-start; }
    .cooperation-subtitle strong {
      font-family: Roboto;
      font-size: 3.73333vw;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      margin-right: 0vw;
      margin-left: 2.66667vw;
      max-width: 46.66667vw; }
      .cooperation-subtitle strong span {
        color: rgba(255, 255, 255, 0.55); }
    .cooperation-subtitle .cooperation-right {
      display: none; }
    .cooperation-subtitle img {
      max-width: 2.93333vw;
      position: relative;
      top: 1.06667vw; }
  .cooperation-photo {
    width: 88vw;
    height: 49.33333vw;
    margin-top: 6.66667vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2.13333vw;
    bottom: 13.06667vw;
    background-image: url("../img/bg/cooperation-photo-mob.jpg") !important; }
  .cooperation .content {
    height: 138.66667vw;
    position: relative; }
  .check {
    margin-top: 2.66667vw; }
    .check .content {
      padding-top: 13.33333vw;
      padding-bottom: 5.33333vw;
      border-radius: 5.33333vw; }
  .cooperation__play {
    width: 16vw;
    height: 16vw;
    background: #F6F7F8; }
    .cooperation__play img {
      max-width: 2.93333vw; }
  .check .section-title {
    margin-bottom: 54.13333vw; }
    .check .section-title span {
      color: #FFE4C8; }
  .check .price-fio-abc {
    right: 31.46667vw;
    bottom: 188.26667vw; }
  .check .price-fio__text::after {
    background-image: url("../img/icons/line-white.svg"); }
  .check-block {
    max-width: 101.33333vw;
    border-radius: 4vw;
    background: #F6F7F8; }
    .check-block .top {
      position: relative;
      bottom: 0.53333vw;
      display: flex;
      align-items: center;
      padding-left: 6.66667vw;
      justify-content: space-between;
      padding-right: 0vw;
      padding-top: 5.33333vw;
      padding-bottom: 5.6vw;
      border-radius: 2.66667vw;
      background: #9B7F62;
      box-shadow: 0 1.06667vw 6.66667vw 0 rgba(255, 255, 255, 0.15) inset; }
    .check-block .bottom {
      color: #3E4953;
      padding-left: 6.66667vw;
      padding-top: 4vw;
      padding-bottom: 6.66667vw;
      padding-right: 7.46667vw; }
  .check-block-list {
    margin-top: 2.66667vw; }
  .check-block__title {
    color: #FFF;
    font-size: 4vw;
    font-weight: 500;
    line-height: 125%; }
    .check-block__title br {
      display: none; }
  .check-block__procent {
    width: 19.46667vw;
    height: 19.46667vw;
    background-size: cover;
    padding-top: 7.2vw;
    padding-left: 4.53333vw;
    background-image: url("../img/icons/check-circle-mob.svg");
    color: #9C8063;
    right: 2.93333vw;
    top: -6.93333vw;
    font-family: "Trajan Pro 3";
    font-size: 5.33333vw;
    font-weight: 400;
    line-height: 120%;
    /* 24px */
    letter-spacing: -0.16vw;
    position: absolute; }
  .check-block__text {
    color: #3E4953;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%; }
    .check-block__text strong {
      font-weight: 500;
      color: #3E4953; }
  .check-block-list {
    color: #3E4953;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 129%; }
    .check-block-list li {
      padding-left: 5.6vw;
      position: relative;
      margin-bottom: 0; }
      .check-block-list li::after {
        position: absolute;
        top: 1.52vw;
        width: 2.93333vw;
        height: 2.4vw;
        background-size: cover;
        content: '';
        display: block;
        background-image: url("../img/icons/check-color-2.svg");
        left: 0; }
      .check-block-list li:last-child {
        margin-bottom: 0; }
  .check-block {
    margin-bottom: 8vw; }
  .check .content {
    background-image: url("../img/bg/check-mob.jpg") !important; }
  .check .price-fio-abc {
    right: unset;
    left: 10.13333vw;
    top: 52.8vw;
    bottom: unset; }
  .check .price-fio__text {
    font-size: 3.2vw;
    font-weight: 400;
    line-height: 125%;
    color: rgba(255, 255, 255, 0.4); }
    .check .price-fio__text strong {
      color: rgba(255, 255, 255, 0.6); }
  .check .price-fio__text::after {
    display: none; }
  .check-line {
    border-radius: 2.66667vw;
    background: #9B7F62;
    box-shadow: 0 1.06667vw 14.66667vw 0 rgba(255, 255, 255, 0.25) inset;
    padding-top: 8vw;
    padding-bottom: 8vw;
    padding-left: 8vw;
    padding-right: 7.73333vw;
    position: relative;
    width: 84vw;
    position: relative;
    right: 4.56vw; }
    .check-line::after {
      position: absolute;
      width: 15.73333vw;
      height: 14.66667vw;
      background-size: cover;
      background-position: center;
      content: '';
      display: block;
      background-image: url("../img/icons/check-quote.svg");
      top: unset;
      left: unset;
      right: 8vw;
      bottom: 9.06667vw;
      transform: scale(-1, 1);
      opacity: .2; }
  .check-line__title {
    color: #FFF;
    font-family: "Trajan Pro 3";
    font-size: 7.2vw;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.216vw;
    margin-bottom: 2.4vw; }
    .check-line__title strong {
      font-weight: 400;
      color: #FAE0C5; }
  .check-line__text {
    color: #FFF;
    font-family: Roboto;
    font-size: 3.73333vw;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; }
  .check .content {
    position: relative; }
  .court {
    padding-top: 13.33333vw; }
  .court-participation {
    margin-top: 6.66667vw;
    margin-bottom: 4vw;
    border-radius: 2.66667vw;
    background: #628D9B;
    box-shadow: 0 1.06667vw 6.66667vw 0 rgba(255, 255, 255, 0.45) inset; }
  .court-participation__name {
    color: #FFF;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    position: relative;
    top: 0.8vw; }
  .court-participation__price {
    color: #FFF;
    font-family: "Trajan Pro 3";
    font-size: 8vw;
    font-weight: 400;
    line-height: 100%;
    /* 42px */
    letter-spacing: -0.28vw;
    position: relative;
    top: 1.06667vw; }
  .court-participation {
    padding-left: 6.66667vw;
    max-width: 80vw;
    padding-top: 4.8vw;
    padding-bottom: 4.8vw; }
  .court-participation__name {
    margin-bottom: 1.6vw; }
  .court_subtext {
    color: #3E4953;
    margin-top: 5.33333vw;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%; }
    .court_subtext strong {
      font-weight: 700; }
  .court-list {
    color: #3E4953;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%; }
    .court-list li {
      padding-left: 5.6vw;
      position: relative;
      margin-bottom: 1.33333vw; }
      .court-list li::after {
        position: absolute;
        width: 2.93333vw;
        height: 2.4vw;
        content: '';
        display: block;
        background-size: cover;
        background-image: url("../img/icons/check-blue.svg");
        left: 0vw;
        top: 1.22667vw; }
      .court-list li:last-child {
        margin-bottom: 0; }
  .court-participation {
    position: relative; }
    .court-participation::after {
      position: absolute;
      width: 2.93333vw;
      height: 4vw;
      background-size: cover;
      background-position: center;
      content: '';
      display: block;
      background-image: url("../img/icons/arrow-bottom.svg");
      top: 9.86667vw;
      right: 6.93333vw; }
  .court .content {
    position: relative; }
    .court .content::after {
      display: none; }
  .court .container {
    padding-bottom: 21.33333vw;
    border-bottom: 0.26667vw solid rgba(0, 0, 0, 0.02); }
  .court-photo {
    width: 88vw;
    height: 55.73333vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9.33333vw;
    margin-right: -6vw;
    margin-left: -6vw;
    border-radius: 2.2vw; }
  .court-photo__play {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16vw;
    height: 16vw;
    border-radius: 50%;
    background: #FFFFFF; }
  .troubles-slide {
    display: flex !important;
    align-items: center;
    flex-direction: column; }
  .troubles-photo {
    width: 133.33333vw;
    height: 140.8vw;
    flex-shrink: 0;
    border-radius: 5.33333vw;
    margin-right: 16vw; }
  .troubles-photo {
    width: 88vw;
    height: 53.33333vw;
    margin-right: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 49.33333vw;
    border-radius: 2.66667vw; }
  .troubles .troubles-arrows {
    right: 0;
    width: 33.33333vw;
    bottom: -18.66667vw;
    justify-content: space-between; }
  .troubles .troubles-arrow {
    margin-right: 0;
    margin-left: 0; }
  .troubles .container {
    padding-right: 0;
    padding-left: 0;
    max-width: 88vw; }
  .troubles .content {
    max-width: 74.66667vw;
    margin-right: auto;
    margin-left: auto; }
  .troubles .slick-dots {
    display: flex !important;
    bottom: -18.66667vw;
    left: 6.33333vw; }
  .troubles-btn {
    color: #628D9B;
    font-family: "Gotham Pro";
    font-size: 2.13333vw;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.10667vw;
    text-transform: uppercase;
    border-radius: 1.6vw;
    background: #FFF;
    padding: 0px;
    padding-bottom: 0vw;
    margin-right: 0vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32vw;
    height: 9.33333vw;
    margin-bottom: 1.6vw; }
    .troubles-btn:nth-child(2n) {
      width: 41.06667vw; }
  .troubles-btns {
    margin-top: 6.66667vw;
    margin-bottom: 64vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch; }
  .troubles-text {
    color: #3E4953;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    max-width: 144vw; }
    .troubles-text p {
      margin-bottom: 5.33333vw; }
      .troubles-text p:last-child {
        margin-bottom: 0; }
  .troubles-slide {
    align-items: flex-start;
    position: relative; }
  .troubles-btn.active {
    color: #FFF;
    background: #628D9B;
    box-shadow: 0 1.06667vw 4vw 0 rgba(255, 255, 255, 0.35) inset; }
  .troubles-arrows {
    display: flex;
    align-items: center; }
  .troubles-arrow {
    width: 14.66667vw;
    height: 14.66667vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .4s all ease;
    background: #628D9B;
    box-shadow: 0 1.06667vw 6.66667vw 0 rgba(255, 255, 255, 0.35) inset; }
    .troubles-arrow svg path {
      transition: .4s all ease; }
  .troubles-arrow.slick-disabled {
    background: #fff; }
    .troubles-arrow.slick-disabled svg path {
      fill: #628D9B; }
  .troubles-next {
    transform: scale(-1, 1);
    margin-left: 5.33333vw; }
  .troubles-arrows {
    position: absolute;
    right: 117.33333vw;
    bottom: 0; }
  .troubles-slider-wrapper {
    position: relative; }
  .portfolio__subtitle {
    display: flex;
    align-items: center;
    color: #3E4953;
    font-size: 4vw;
    font-weight: 400;
    line-height: 125%; }
    .portfolio__subtitle img {
      margin-right: 4.26667vw; }
  .portfolio-slider-wrapper {
    margin-top: 8vw; }
  .portfolio-block {
    border-radius: 4vw;
    background: #FFF;
    max-width: 74.4vw;
    padding-left: 9.33333vw;
    padding-top: 8vw;
    height: 77.6vw;
    width: 100%;
    margin-bottom: 2.66667vw;
    position: relative;
    margin-right: 2.66667vw; }
  .portfolio-slider {
    max-width: 100%; }
  .portfolio-slide {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%; }
  .portfolio-slider-wrapper {
    margin-bottom: 29.33333vw; }
  .portfolio-block__pretitle {
    color: rgba(62, 65, 80, 0.75);
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 2.66667vw; }
  .portfolio-block__price {
    color: #3E4150;
    margin-bottom: 9.33333vw;
    font-size: 6.66667vw;
    font-weight: 700;
    line-height: 125%; }
  .portfolio-block__text {
    color: rgba(62, 65, 80, 0.45);
    font-size: 4vw;
    font-weight: 400;
    line-height: 125%; }
    .portfolio-block__text strong {
      color: #3E4150;
      font-weight: 500; }
    .portfolio-block__text p {
      margin-bottom: 5.33333vw; }
      .portfolio-block__text p:last-child {
        margin-bottom: 0; }
  .portfolio-block__zoom {
    width: 12vw;
    height: 12vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 2.66667vw;
    background: rgba(98, 141, 155, 0.2);
    bottom: 5.33333vw;
    right: 5.33333vw; }
  .portfolio-logos {
    color: rgba(155, 127, 98, 0.45);
    font-family: "Gotham Pro";
    font-size: 1.76vw;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 0.14667vw;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 8vw;
    width: 60vw;
    border-radius: 1.6vw;
    background: rgba(155, 127, 98, 0.1);
    backdrop-filter: blur(3.6vw);
    position: relative;
    bottom: 0.53333vw; }
    .portfolio-logos span {
      margin-right: 1.6vw;
      margin-left: 1.6vw; }
  .portfolio .first-screen-count__img:nth-child(1) {
    max-width: 10.4vw; }
  .portfolio .first-screen-count__img:nth-child(3) {
    max-width: 10.66667vw; }
  .portfolio .first-screen-count__img:nth-child(5) {
    max-width: 24vw; }
  .slick-dots li button {
    display: none; }
  .slick-dots li {
    width: 1.86667vw;
    height: 1.86667vw;
    border-radius: 2.66667vw;
    background: rgba(155, 127, 98, 0.1);
    transition: .4s all ease;
    margin-right: 1.86667vw;
    margin-left: 1.86667vw; }
  .slick-dots {
    width: auto;
    margin-left: -1.86667vw;
    bottom: -18.66667vw;
    display: none; }
  .slick-dots {
    display: none !important; }
  .slick-dots li.slick-active {
    background: #9B7F62; }
  .portfolio-slider-wrapper {
    position: relative; }
    .portfolio-slider-wrapper .troubles-arrows {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 33.33333vw;
      transform: none;
      top: unset;
      bottom: -18.66667vw; }
  .portfolio-next {
    transform: scale(-1, 1); }
  .portfolio-slider-wrapper {
    margin-bottom: 0;
    margin-right: -12.8vw; }
    .portfolio-slider-wrapper .portfolio-slider {
      margin-bottom: 0; }
    .portfolio-slider-wrapper .portfolio-block {
      margin-bottom: 0; }
  .header-logo {
    max-width: 44.8vw;
    margin-right: 0vw; }
  .header__phone-mob {
    border-radius: 2.13333vw;
    background: #FFF;
    width: 10.66667vw;
    height: 10.66667vw;
    margin-right: 1.33333vw; }
    .header__phone-mob img {
      max-width: 3.2vw; }
  .header__burger {
    width: 10.66667vw;
    height: 10.66667vw;
    border-radius: 2.13333vw;
    background: #9B7F62;
    box-shadow: 0px 1.06667vw 5.33333vw 0px rgba(255, 255, 255, 0.45) inset; }
    .header__burger img {
      max-width: 2.93333vw; }
  .header {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    padding-top: 5.46667vw;
    padding-left: 6.8vw;
    padding-right: 6.8vw; }
  .soc-wc {
    background: #65BC63; }
  .soc-tg {
    background: #68B6C1; }
  .header-soc {
    margin-left: -8vw; }
  .footer {
    padding-bottom: 10.93333vw;
    padding-top: 13.33333vw; }
    .footer .container {
      max-width: 74.66667vw; }
    .footer .header-logo {
      max-width: 61.6vw;
      position: relative;
      bottom: 0vw;
      margin-right: 0vw;
      margin-bottom: 8vw; }
    .footer .header-row {
      align-items: flex-start; }
    .footer .left {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 14.66667vw;
      margin-bottom: 13.33333vw;
      width: 100%;
      position: relative; }
      .footer .left::after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 100vw;
        height: 0.26667vw;
        background: rgba(0, 0, 0, 0.05);
        bottom: 0; }
    .footer .right {
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding-bottom: 13.33333vw;
      margin-bottom: 8vw; }
      .footer .right::after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 100vw;
        height: 0.26667vw;
        background: rgba(0, 0, 0, 0.05);
        bottom: 0; }
  .footer-bottom {
    padding-top: 0vw;
    margin-top: 0vw;
    border: none;
    flex-direction: column;
    align-items: flex-start; }
  .footer__legal {
    color: #D0D0D0;
    font-family: Roboto;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 5.86667vw;
    text-align: left;
    margin-top: 4vw; }
  .footer__copy {
    color: #D0D0D0;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 5.86667vw; }
    .footer__copy a {
      color: #9B7F62;
      text-decoration-line: underline; }
  .footer-address__name {
    color: #3E4953;
    margin-bottom: 0vw;
    font-family: "Trajan Pro 3";
    font-size: 4.8vw;
    font-weight: 400;
    line-height: 5.86667vw; }
  .footer-address__adress {
    color: #9B7F62;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 5.86667vw;
    /* 137.5% */ }
  .footer-address {
    position: relative;
    top: 0.53333vw; }
  .modal-window {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    padding: 2.66667vw;
    transition: .4s all ease;
    opacity: 0;
    visibility: hidden; }
    .modal-window .inner {
      margin-top: auto;
      margin-bottom: auto;
      height: max-content;
      position: relative; }
  .modal-window.active {
    opacity: 1;
    visibility: visible; }
  .modal-window__close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.33333vw;
    height: 9.33333vw;
    right: 4vw;
    top: 4vw;
    border-radius: 1.6vw;
    border: 0.12vw solid #628D9B;
    background: #F6F7F8;
    box-shadow: 0 1.06667vw 2.66667vw 0 rgba(255, 255, 255, 0.25) inset; }
    .modal-window__close svg {
      max-width: 2.66667vw; }
  .modal-exit .inner {
    border-radius: 5.33333vw;
    background: #628D9B;
    box-shadow: 0 1.06667vw 17.33333vw 0 rgba(255, 255, 255, 0.25) inset;
    padding: 16vw;
    max-width: 309.33333vw;
    width: 100%; }
  .modal-exit__title {
    color: #FFF;
    font-family: "Trajan Pro 3";
    font-size: 5.33333vw;
    font-weight: 400;
    line-height: 120%;
    /* 42px */
    letter-spacing: -0.16vw;
    margin-bottom: 4vw; }
  .exit-photo {
    width: 88vw;
    height: 53.33333vw;
    border-radius: 4vw;
    background-size: 101%;
    margin-right: 18.13333vw;
    position: relative;
    right: 10.73333vw;
    margin-right: 0vw;
    margin-left: 0vw; }
  .exit-photo__play img {
    max-width: 2.93333vw; }
  .exit-row {
    justify-content: flex-start; }
  .modal-exit__name {
    color: #FFF;
    font-family: Roboto;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4vw; }
  .modal-exit-list {
    color: rgba(255, 255, 255, 0.75);
    font-size: 3.73333vw;
    font-weight: 400;
    max-width: 100%; }
    .modal-exit-list li {
      margin-bottom: 2.66667vw;
      position: relative;
      padding-left: 6.93333vw; }
      .modal-exit-list li::after {
        width: 2.93333vw;
        height: 2.4vw;
        background-size: cover;
        background-position: center;
        content: '';
        display: block;
        background-position: center;
        background-image: url("../img/icons/check-white.svg");
        position: absolute;
        top: 1.06667vw;
        left: 0; }
      .modal-exit-list li:last-child {
        margin-bottom: 0; }
  .modal-exit__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2.66667vw;
    background: #9B7F62;
    box-shadow: 0 1.06667vw 9.33333vw 0 rgba(255, 255, 255, 0.25) inset;
    width: 100%;
    height: 16vw;
    margin-top: 4vw;
    color: #FFF;
    text-align: center;
    font-size: 3.73333vw;
    font-weight: 700;
    line-height: 5.33333vw; }
    .modal-exit__btn img {
      margin-left: 2.66667vw; }
  .exit-photo__play {
    width: 16vw;
    height: 16vw;
    border-radius: 50%;
    background: #FFFFFF; }
  .modal-start .inner {
    max-width: 100%;
    padding: 0vw;
    width: 100%;
    border-radius: 5.33333vw;
    background: #FFF; }
  .start {
    border-radius: 2.66667vw;
    padding: 3.46667vw;
    padding-top: 12vw; }
  .start__title {
    font-family: "Trajan Pro 3";
    font-size: 7.2vw;
    font-weight: 400;
    line-height: 120%;
    /* 48px */
    letter-spacing: -0.216vw;
    margin-bottom: 4vw;
    margin-left: auto;
    margin-right: auto;
    max-width: 74.66667vw; }
  .start .hello__fio {
    display: none; }
  .start-text {
    color: #FFF;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    max-width: 74.66667vw;
    margin-bottom: 4vw;
    margin-left: auto;
    margin-right: auto; }
    .start-text p {
      margin-bottom: 5.33333vw; }
      .start-text p:last-child {
        margin-bottom: 0; }
  .start-block {
    border-radius: 2.66667vw;
    background: #628D9B;
    box-shadow: 0 1.06667vw 14.66667vw 0 rgba(255, 255, 255, 0.25) inset;
    max-width: 128vw;
    width: 100%;
    padding: 6.66667vw;
    position: relative; }
    .start-block::after {
      position: absolute;
      width: 15.73333vw;
      height: 14.66667vw;
      background-size: cover;
      background-position: center;
      background-image: url("../img/icons/start-quote.svg");
      content: '';
      display: block;
      top: -5.33333vw;
      right: 5.33333vw; }
  .start-block__text {
    color: #FFF;
    margin-bottom: 5.33333vw;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%; }
    .start-block__text strong {
      font-weight: 500; }
  .start-number__phone {
    color: #FFF;
    font-family: "Trajan Pro 3";
    font-size: 5.33333vw;
    font-weight: 400;
    line-height: 125%; }
  .start {
    border-radius: 2.66667vw;
    background: #9B7F62 !important;
    box-shadow: 0px 0.27778vw 4.51389vw 0px rgba(255, 255, 255, 0.35) inset; }
  .start-block {
    background-size: cover;
    background-image: url("../img/bg/start-block-mob.jpg"); }
    .start-block .wrapper {
      flex-direction: column;
      align-items: flex-start; }
  .start-number__name {
    color: rgba(255, 255, 255, 0.75);
    font-size: 3.46667vw;
    font-weight: 200;
    line-height: 125%; }
  .start-block::after {
    display: none; }
  .start-number {
    margin-right: 0vw;
    position: relative;
    margin-bottom: 4vw; }
    .start-number:last-child {
      margin-right: 0; }
    .start-number:first-child::after {
      position: absolute;
      width: 2.66667vw;
      height: 4vw;
      background-size: cover;
      background-position: center;
      background-image: url("../img/icons/start-sim.svg");
      top: 1.33333vw;
      right: -6.66667vw;
      content: '';
      display: block; }
  .start {
    position: relative; }
    .start .hello__fio {
      position: absolute;
      top: 76vw;
      right: 17.6vw;
      font-size: 3.2vw; }
  .webinar {
    max-width: 74.66667vw;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: 100%;
    padding-top: 8vw;
    padding-left: 0vw;
    padding-bottom: 8.8vw;
    padding-right: 0; }
    .webinar .modal-exit__title {
      color: #3E4953;
      font-family: "Trajan Pro 3";
      font-size: 5.33333vw;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.32vw;
      margin-bottom: 64vw; }
    .webinar .modal-exit__name {
      color: #3E4953;
      font-size: 4vw;
      font-weight: 600;
      line-height: normal; }
    .webinar .exit-row {
      flex-direction: column;
      align-items: center; }
    .webinar .modal-exit-list {
      color: #3E4953; }
    .webinar .modal-exit__btn {
      background: #628D9B; }
    .webinar .modal-exit-list li::after {
      background-image: url("../img/icons/check-blue.svg"); }
  .webinar__pretitle {
    color: #3E4953;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 3.2vw;
    max-width: 53vw; }
  .modal-exit__title {
    margin-bottom: 13.33333vw; }
  .modal-keys .inner {
    border-radius: 5.33333vw;
    background: #FFF;
    max-width: 309.33333vw;
    width: 100%; }
  .modal-keys .webinar {
    padding-left: 0vw; }
  .keys {
    padding-left: 10.13333vw;
    padding-top: 12vw;
    padding-right: 0vw; }
    .keys .section-title {
      font-size: 7.2vw;
      line-height: 120%;
      letter-spacing: -0.24267vw;
      margin-bottom: 5.33333vw; }
  .keys-text {
    color: #3E4953;
    margin-top: 5.33333vw;
    font-size: 3.73333vw;
    font-weight: 400;
    line-height: 125%;
    max-width: 74.66667vw; }
    .keys-text strong {
      font-weight: 600; }
    .keys-text p {
      margin-bottom: 5.33333vw; }
      .keys-text p:last-child {
        margin-bottom: 0; }
  .keys-slide__photo {
    height: 40vw;
    width: 100%;
    border-radius: 2.66667vw;
    margin-bottom: 2.66667vw; }
  .keys-block {
    width: 66.4vw;
    max-width: unset;
    margin-bottom: 0vw;
    margin-right: 4vw; }
  .keys-slider-wrapper .troubles-arrows {
    max-width: 33.33333vw;
    width: 100%;
    top: unset;
    bottom: -20vw;
    right: 9.86667vw; }
  .keys-slide__title {
    color: #3E4953;
    font-family: Roboto;
    font-size: 3.73333vw;
    font-weight: 300;
    line-height: 125%; }
  .modal-keys .webinar {
    padding-top: 33.33333vw; }
    .modal-keys .webinar .exit-photo {
      top: 82vw; }
  .modal-window__close {
    z-index: 10000; }
  .keys {
    position: relative;
    z-index: 10; }
  .keys-slider-wrapper .slick-dots {
    display: flex !important; }
  .keys-slider-wrapper .slick-dots {
    width: unset; }
  .keys-slider-wrapper .slick-dots li {
    width: 1.86667vw;
    height: 1.86667vw;
    margin-right: 1.86667vw;
    margin-left: 1.86667vw; }
  .keys-slider {
    margin-top: 8vw;
    margin-bottom: 0 !important; }
  .keys-slide__play {
    width: 18.66667vw;
    height: 18.66667vw;
    border-radius: 50%;
    background: #FFFFFF; }
  .keys-slide {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%; }
  .modal-start .modal-window__close {
    border: none; }
  .care-subtitle__bottom img {
    max-width: 4vw; }
  .widget {
    border-radius: 10px;
    background: #363C41;
    box-shadow: 0px 4px 35px 0px rgba(255, 255, 255, 0.1) inset;
    padding: 4vw;
    padding-top: 5.33333vw;
    margin-top: 5.33333vw; }
  .widget__title {
    text-align: center;
    margin-bottom: 2.66667vw;
    color: #FFF;
    font-family: "Trajan Pro 3";
    font-size: 4.8vw;
    font-weight: 400;
    line-height: 120%;
    /* 21.6px */
    letter-spacing: -0.144vw; }
    .widget__title span {
      color: rgba(255, 255, 255, 0.25); }
  .widget-btn {
    width: 100%;
    height: 16vw;
    border-radius: 2.66667vw;
    margin-bottom: 1.33333vw;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 3.73333vw;
    font-style: normal;
    font-weight: 700;
    line-height: 4vw; }
    .widget-btn img {
      margin-left: 2.66667vw; }
  .widget-btn--tg {
    background: #628D9B;
    box-shadow: 0px 1.06667vw 4vw 0px rgba(255, 255, 255, 0.35) inset; }
    .widget-btn--tg img {
      max-width: 4.8vw; }
  .widget-btn--wc {
    background: #65BC63;
    box-shadow: 0px 1.06667vw 4vw 0px rgba(255, 255, 255, 0.35) inset; }
    .widget-btn--wc img {
      max-width: 4vw; }
  .header-soc__item img {
    max-width: 5.86667vw; }
  .form-label__img {
    max-width: 3.73333vw; }
  .deeper-block__play img {
    max-width: 2.93333vw; }
  .cooperation__play img {
    max-width: 2.93333vw; }
  .troubles-arrow svg {
    max-width: 1.86667vw;
    width: 100%; }
  .latent-happening img {
    max-width: 2.93333vw; }
  .latent__zoom img {
    max-width: 4vw; }
  .every-slide__link img {
    max-width: 2.93333vw; }
  .portfolio__subtitle img {
    max-width: 7.2vw; }
  .portfolio-block__zoom img {
    max-width: 4vw; }
  .reviews-slider__arrow img {
    max-width: 2.66667vw; }
  .first-screen-term img {
    max-width: 2.93333vw; }
  .first-screen-term {
    white-space: nowrap; }
  .reviews-slide__play img {
    max-width: 1.86667vw; }
  .reviews-slider__arrow {
    white-space: nowrap; }
  .hello__signature {
    max-width: 42.66667vw; }
  .hello__quote {
    max-width: 24.53333vw; }
  .first-screen-count__img:nth-child(1) {
    max-width: 13.33333vw; }
  .first-screen-count__img:nth-child(3) {
    max-width: 13.33333vw; }
  .first-screen-count__img:nth-child(5) {
    max-width: 29.86667vw; }
  svg path {
    width: 100%; }
  .court-photo__play img {
    max-width: 2.66667vw; }
  .overflow {
    overflow: hidden; }
  .keys-slide__play img {
    max-width: 2.93333vw; }
  .modal-exit__btn img {
    max-width: 4.18667vw; }
  .court-mob-wrapper {
    justify-content: space-between;
    margin-top: 5.33333vw; }
  .court-mob__arrow {
    width: 26.66667vw;
    height: 14.4vw;
    border-radius: 26.66667vw;
    background: #FFF; }
    .court-mob__arrow img {
      max-width: 3.73333vw; }
  .exit-photo {
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
    top: 56.8vw;
    right: unset;
    position: absolute; }
  .court_subtext {
    margin-top: 0; }
  .court .container {
    padding-bottom: 13.33333vw; }
  .header-number__phone {
    font-size: 24px;
    font-weight: 400;
    line-height: 125%; }
  .header-number__name {
    color: rgba(62, 73, 83, 0.75);
    font-size: 3.73333vw;
    font-weight: 200;
    line-height: 125%; }
  .header-number {
    align-items: flex-start;
    margin-right: 0;
    margin-bottom: 8vw; }
    .header-number:last-child {
      margin-bottom: 0; }
  .header-soc {
    margin-left: 0; }
    .header-soc .header-soc__item.soc-wc {
      order: -1;
      margin-right: 2.66667vw; }
  .header-soc__item {
    width: 14.66667vw;
    height: 14.66667vw;
    margin-left: 0;
    margin-right: 0;
    border-radius: 2.44453vw;
    box-shadow: 0px 1.06667vw 2.66667vw 0px rgba(255, 255, 255, 0.25) inset; }
    .header-soc__item:last-child {
      margin-right: 0; }
  .soc-tg {
    background: #62949B; }
  .footer {
    padding-bottom: 16vw; }
  .latent-happening {
    background-size: cover; }
  .start-number:first-child::after {
    display: none; }
  .modal-menu .left {
    width: 100%; }
  .modal-menu {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F6F8FA;
    z-index: 1000000;
    padding-top: 5.33333vw;
    transform: translateY(-100%);
    transition: .4s all ease; }
    .modal-menu .top-row {
      padding-right: 6.66667vw;
      padding-left: 6.66667vw;
      margin-bottom: 26.53333vw; }
    .modal-menu .left {
      flex-direction: column;
      align-items: flex-start; }
    .modal-menu .right {
      flex-direction: column;
      align-items: flex-start; }
    .modal-menu .footer-address {
      padding-bottom: 10.66667vw;
      margin-bottom: 13.33333vw; }
    .modal-menu .header-links {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: 100%;
      padding-bottom: 8vw;
      margin-bottom: 10.66667vw;
      display: flex; }
    .modal-menu .header__burger img {
      max-width: 3.46667vw; }
    .modal-menu .header-link {
      color: rgba(62, 73, 83, 0.75);
      display: block;
      width: 100%;
      max-width: 100%;
      font-family: Roboto;
      font-size: 3.73333vw;
      font-weight: 300;
      line-height: 135%;
      margin-bottom: 2.66667vw;
      text-decoration: none;
      position: relative; }
      .modal-menu .header-link:last-child {
        margin-bottom: 0; }
      .modal-menu .header-link::after {
        position: absolute;
        top: 1.6vw;
        right: 0.53333vw;
        content: '';
        display: block;
        width: 0.8vw;
        height: 1.6vw;
        background-size: cover;
        background-position: center;
        background-image: url("../img/icons/link-mob-arrow.svg"); }
    .modal-menu .header-links {
      position: relative; }
      .modal-menu .header-links::after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 100vw;
        height: 0.26667vw;
        background: rgba(0, 0, 0, 0.05);
        bottom: 0; }
    .modal-menu .footer-address {
      position: relative; }
      .modal-menu .footer-address::after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 100vw;
        height: 0.26667vw;
        background: rgba(0, 0, 0, 0.05);
        bottom: 0; }
  .modal-menu.active {
    transform: translateY(0); }
  .modal-menu {
    overflow-y: auto;
    padding-bottom: 6.66667vw; }
  .header__phone-mob {
    transition: .4s all ease; }
  .header {
    width: 97.33333vw;
    margin-top: 1.33333vw;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 4vw;
    padding-bottom: 4vw;
    border-radius: 2.66667vw;
    transition: .4s all ease; }
  .header.fixed {
    position: fixed;
    background: #FFF;
    box-shadow: 0px 66.93333vw 18.66667vw 0px rgba(71, 70, 78, 0), 0px 42.93333vw 17.06667vw 0px rgba(71, 70, 78, 0.01), 0px 24vw 14.4vw 0px rgba(71, 70, 78, 0.03), 0px 10.66667vw 10.66667vw 0px rgba(71, 70, 78, 0.04), 0px 2.66667vw 5.86667vw 0px rgba(71, 70, 78, 0.05); }
    .header.fixed .header__phone-mob {
      background: #F3F4F5; }
  .form-input {
    border: 0.26667vw solid #F6F7F8;
    transition: .4s all ease; }
  .blick {
    background-image: linear-gradient(-45deg, transparent 0%, transparent 49%, #fff 50%, transparent 70%) !important;
    background-repeat: no-repeat !important;
    -webkit-animation: blick-slide infinite 3s;
    animation: blick-slide infinite 3s; }
    .blick .spn-txt {
      position: relative;
      z-index: 2; }
  @keyframes blick-slide {
    0% {
      background-position-x: -70vw; }
    100% {
      background-position-x: calc(100% + 70vw); } }
  .form-btn {
    -webkit-animation: blick-slide-btn infinite 4s;
    animation: blick-slide-btn infinite 4s; }
  @keyframes blick-slide-btn {
    0% {
      background-position-x: -80vw; }
    100% {
      background-position-x: calc(100% + 80vw); } }
  .thanks {
    margin-bottom: 13.33333vw; }
  .thanks .content {
    padding-top: 24vw;
    padding-bottom: 0;
    height: 192vw;
    border-radius: 0 0 5.33333vw 5.33333vw;
    background-image: url("../img/bg/thanks-mob.jpg") !important; }
  .thanks__title {
    font-size: 8vw;
    line-height: 120%;
    /* 36px */
    letter-spacing: -0.24vw;
    margin-bottom: 8vw; }
  .thanks-text::after {
    width: 15.73333vw;
    height: 14.66667vw;
    right: 0;
    top: -3.46667vw; }
  .thanks-text {
    font-size: 3.73333vw;
    line-height: 125%;
    max-width: 74.66667vw; }
    .thanks-text p {
      margin-bottom: 5.33333vw; }
      .thanks-text p:last-child {
        margin-bottom: 0; }
  .deeper--thanks .section-title {
    font-size: 5.86667vw;
    letter-spacing: -0.176vw;
    margin-bottom: 8vw; }
  .deeper--thanks {
    padding-bottom: 0; }
  .webinar--section {
    padding-bottom: 0; }
  .webinar--section .webinar__pretitle {
    font-size: 3.73333vw;
    margin-bottom: 4vw; }
  .webinar--section .modal-exit-list {
    font-size: 3.73333vw; }
  .webinar--section .exit-photo {
    width: 88vw;
    height: 53.33333vw;
    top: 46vw; }
  .header--thanks {
    position: absolute !important;
    background: transparent !important; }
  [data-aos^=fade][data-aos^=fade] {
    transition-delay: 0.1s !important; }
  .first-line {
    position: relative;
    right: 5.20833vw;
    border-radius: 0; }
  .selectize-dropdown-content::-webkit-scrollbar {
    width: 1.33333vw;
    /* Ширина всего элемента навигации */ } }

html, body {
  font-size: 16px;
  overflow-x: hidden; }

body {
  overflow: hidden;
  position: relative;
  background: #F6F7F8;
  font-family: "Roboto", sans-serif;
  color: #3E4953; }

input {
  outline: none; }

ul, li {
  list-style-type: none;
  margin-bottom: 0; }

p {
  margin-bottom: 0; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: inherit; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none !important; }

img {
  max-width: 100%;
  width: 100%; }
