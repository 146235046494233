@import 'fonts.scss';
@import 'style.scss';
@import '_media.scss';


html,body{
	font-size: 16px;
	overflow-x: hidden;
}
html{
	
}
body{
	overflow: hidden;
	position: relative;
	background: #F6F7F8;
	font-family: "Roboto", sans-serif;
	color: #3E4953;
}
input{
	outline: none;
}

ul,li{
	list-style-type: none;
	margin-bottom: 0;
}
p{
	margin-bottom: 0;
}
a{
	color: inherit;
	text-decoration: none;
	&:hover{
		text-decoration: none;
		color: inherit;
	}
}
*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	outline: none!important;
}
img{
	max-width: 100%;
	width: 100%;
}